import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  MBox,
  MButton,
  MCircularProgress,
  MDrawer,
  MIconButton,
  MLink,
  MMenu,
  MMenuItem,
  MToolbar,
  MTypography,
} from "src/app/material-ui";
import { MDialogActions } from "src/app/material-ui/dialog-actions/DialogActions";
import { MDialogContent } from "src/app/material-ui/dialog-content/DialogContent";
import { MDialogTitle } from "src/app/material-ui/dialog-title/DialogTitle";
import { MDialog } from "src/app/material-ui/dialog/Dialog";
import {
  MDispatched,
  MInvoiceApproved,
  MInvoicePaidBlue,
  MInvoicePending,
  MInvoiceReview,
} from "src/app/material-ui/icons/index";
import { timeZone } from "../../hooks";
import SendQuote from "../../screens/vendor/send-quote/SendQuote";
import { showAlert } from "../../store";
import ViewDraft from "../ViewDraft";
import { IJob, ILanguage, IProps, IService } from "./interface";
import useStyles from "./JobListingStyles";
// import {htmlToText} from "html-to-text";
import CheckPermissions from "src/app/hooks/permissions/CheckPermissions";

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const { htmlToText } = require("html-to-text");

interface ISingleProps extends IProps {
  projects: IJob;
  setSendPOOpenModal?: any;
  props?: any;
  getAllJobs?: any;
  isDrawer?: any;
  setIsDrawer?: any;
  setSelectedItem?: any;
  handleSetFileId?: any;
  setRefreshProjects: any;
  setIsJobDetailDrawer: (val: any) => void,
}

const APPROVE_CUSTOMER_TASK = gql`
  mutation approveCustomerJob($taskId: String, $orgId: Int) {
    approveCustomerJob(taskId: $taskId, orgId: $orgId)
  }
`;

const SingleJob = ({ setInvoiceId, setViewDetailOpenModal, setQuoteRequestedId, getAllJobs, projects, vendorCompanyName, setOpen, setIsJobDetailDrawer, setSelectedJob, history, status, hideFilter, setRefreshProjects, refreshProjects }: ISingleProps) => {
  //,isDrawer,setIsDrawer
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [openNew, setOpenNew] = useState(false);
  // const [jobQuoteDetails, setJobQuoteDetails]: any = useState();
  const [quoteResponseId, setQuoteResponseId]: any = useState();
  const [jobView, setOpenJobView] = useState(false);
  const [jobEdit, setOpenJobEdit] = useState(false);
  // const [selectedQuoteResponse, setSelectedQuoteResponse]: any = useState([]);
  // const [isDrawer, setIsDrawer] = useState(false);
  // const [isDrawer1, setIsDrawer1] = useState(false);
  const [sendDrawer, setSendDrawer] = useState<boolean>(false);
  const [viewDraftOpen, setViewDraftOpen] = useState(false);
  const statusArray = ['', 'Sent', 'Paid', 'Sent(Revised)', 'Rejected', 'Review'];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menu = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const [approveOpen, setApproveOpen] = useState<boolean>(false);
  const appUserRole = useSelector((store: any) => store.userRole.userRole);
  const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
  const currentUser = useSelector((store: any) => store.auth.currentUser);
  const SelectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
  const organizations = useSelector((store: any) => store.orgs.organizations);

  const [approveCustomerTask, { loading: approveTaskLoading }] = useMutation(APPROVE_CUSTOMER_TASK, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      if (typeof setRefreshProjects != "undefined") {
        setRefreshProjects(!refreshProjects);
      }
      // setIsDrawer(false);
      setApproveOpen(false);
      setAnchorEl(null);
      dispatch(showAlert({
        message: 'Task Approved Successfully',
        messageType: 'success',
        showAlertMessage: true,
      }));
    }
  });

  useEffect(() => {
    setOpenJobEdit(CheckPermissions('ROLE_OPEN_JOBS_EDIT', selectedOrgId, organizations));
    setOpenJobView(CheckPermissions('ROLE_OPEN_JOBS_VIEW', selectedOrgId, organizations));
  }, [SelectedOrgId, organizations]);

  const servicesText = (services: IService[]) => {
    const servicesNameArr: Array<string> = [];
    services.forEach((services: IService, index: number) => {
      if (index > 1) {
        servicesNameArr.push(services.name)
      }
    })
    return servicesNameArr.join(', ');
  }

  const LightTooltip = styled(
    React.forwardRef(function LightTooltip({ className, ...props }: TooltipProps, ref) {
      return (
        <Tooltip
          {...props}
          classes={{ ...props?.classes, popper: className || '' }}  // Ensure className is passed safely
          ref={ref}
        />
      );
    })
  )(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#6b778c",
      fontSize: 12,
      filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
      border: "1px solid #f4f5f7",
      borderRadius: 0,
      padding: "20px 15px",
      maxWidth: 230,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
  }));

  // const LightTooltip1 = styled(({ className, ...props }: TooltipProps) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.white,
  //     color: "#6b778c",
  //     fontSize: 12,
  //     filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
  //     border: "1px solid #f4f5f7",
  //     borderRadius: 0,
  //     padding: "20px 15px",
  //     maxWidth: 230,
  //   },
  //   arrow: {
  //     color: theme.palette.common.white,
  //   },
  // }));

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // const openNewSet = (val: any) => {
  //     setOpenNew(val);
  // }
  // const decodedDescription = htmlToText(selectedQuoteResponse.description, {
  //   // wordwrap: 2
  // });

  // const convertMin = (min: number) => {
  //   const result = []
  //   const units: any = {
  //     Year: 12 * 60 * 365,
  //     Month: 12 * 60 * 30,
  //     Week: 12 * 60 * 7,
  //     Day: 12 * 60,
  //     Hour: 60,
  //     Min: 1
  //   }
  //   for (const name in units) {
  //     const p = Math.floor(min / units[name]);
  //     if (p == 1) result.push(p + " " + name + " ");
  //     if (p >= 2) result.push(p + " " + name + "s ");
  //     min %= units[name]
  //   }
  //   return result[0];
  // }

  const moreTargetText = (targetLan: ILanguage[]) => {
    const langNameArr: Array<string> = [];
    targetLan.forEach((lang: ILanguage, index: number) => {
      if (index > 2) {
        langNameArr.push(lang.code)
      }
    })
    return langNameArr.join(', ');
  }

  const handleApproveClose = () => {
    setApproveOpen(false);
  };

  const approveDialogCloseYes = () => {
    approveCustomerTask({
      variables: {
        taskId: "" + projects.id,
        orgId: selectedOrgId
      }
    })
  };

  return (
    <>
      <ViewDraft viewDraftOpen={viewDraftOpen} setViewDraftOpen={setViewDraftOpen}
        getAllJobs={getAllJobs} setSendDrawer={setSendDrawer} sendDrawer={sendDrawer}
        quoteResponseId={quoteResponseId}
      />
      <MDialog
        open={approveOpen}
        onClose={handleApproveClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <MDialogTitle id="alert-dialog-title"><FormattedMessage id="are.sure" /></MDialogTitle>
        <MDialogContent dividers>
          <MBox mr={9}><FormattedMessage id="sure.want.to.approve" /></MBox>
        </MDialogContent>
        <MDialogActions>
          <MBox mr={1}>
            <MLink onClick={(e: any) => {
              e?.preventDefault();
              handleApproveClose();
            }} color="inherit">
              <FormattedMessage id="no" />
            </MLink>
          </MBox>
          <MButton
            disabled={approveTaskLoading}
            onClick={approveDialogCloseYes} variant="contained" color="primary"
            autoFocus>
            {approveTaskLoading && <MCircularProgress color="primary" size={24} />}
            <FormattedMessage id="yes" />
          </MButton>
        </MDialogActions>
      </MDialog>
      <MBox className={classes.projectList} pt={1.75} pl={3} pb={3} mb={2}>

        <MDrawer
          open={sendDrawer}
          variant="temporary"
          anchor='right'
          className={classes.fileUploadDrawer}
        >
          <AppBar className='drawerAppBar'>
            <MToolbar>
              <MBox display="inline-block" mr={1}>
                <MIconButton onClick={() => setSendDrawer(false)}>
                  <ArrowBackIcon />
                </MIconButton>
              </MBox>
              <MBox display="flex" justifyContent="flex-end">
                <MBox width="1000px" fontSize={23} fontFamily="Poppins">
                  <FormattedMessage id="send.bid.proposal" />
                </MBox>
              </MBox>
            </MToolbar>
          </AppBar>
          <MBox pb={5}>
            <SendQuote getAllJobs={getAllJobs} setSendDrawer={setSendDrawer} sendDrawer={sendDrawer}
              quoteResponseId={quoteResponseId} />
          </MBox>
        </MDrawer>
        <MBox display="flex" justifyContent="space-between" alignItems="center">
          {appUserRole === "ROLE_VENDOR" && (jobEdit || jobView) ?
            <MBox className={classes.listHeading} fontFamily="Poppins" fontSize={17}
              color='#3a3a3a'>
              <MLink
                //  href="javascript:void(0);"
                onClick={(e: any) => {
                  e?.preventDefault();
                  if (status === "assigned") {
                    history.push('/vendor/projects/my-jobs/' + projects.encryptedId)
                  } else {
                    setSelectedJob(projects);
                  }
                  if (typeof setIsJobDetailDrawer !== "undefined" && setSelectedJob) {
                    setQuoteRequestedId(projects.quoteRequestId);
                    setSelectedJob(projects);
                    setIsJobDetailDrawer(true)
                  }
                }
                }
                color="inherit"
              >
                {projects.jobTitle}
              </MLink>
            </MBox> : ""
          }
          {appUserRole !== "ROLE_VENDOR" && (jobEdit || jobView) ?
            <MBox className={classes.listHeading} fontFamily="Poppins" fontSize={17}
              color='#3a3a3a'>
              <MLink
                // href="javascript:void(0);"
                onClick={(e: any) => {
                  e?.preventDefault();
                  setSelectedJob(projects);
                  if (typeof setIsJobDetailDrawer !== "undefined") {
                    setQuoteRequestedId(projects.quoteRequestId);
                    setSelectedJob(projects);
                    setIsJobDetailDrawer(true);
                  }
                }
                }
                color="inherit"
              >
                {projects.jobTitle}
              </MLink>
            </MBox> : ""
          }

          <MMenu
            onClick={() => {
              setSelectedJob(projects);
            }}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={menu}
            onClose={handleCloseMenu}
            className={classes.menuList}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 'auto',
              },
            }}
          >
            {projects.proposalSent === 1 ?
              ''
              // <>
              //     {/*<MMenuItem onClick={() => {*/}
              //     {/*setSelectedItem(projects);*/}
              //     {/*handleSetFileId();*/}
              //     {/*// getQuoteResponseDetails();*/}
              //     {/*handleCloseMenu();*/}
              //     {/*}}>*/}
              //     {/*<MTypography>*/}
              //     {/*<FormattedMessage id="view.proposal" />*/}
              //     {/*</MTypography>*/}
              //     {/*</MMenuItem>*/}
              // </>
              :
              null
            }
            {projects.proposalSent === 2 ?
              <MMenuItem onClick={() => {
                handleCloseMenu();
                if (vendorCompanyName != "") {
                  setViewDraftOpen(true);
                  setQuoteResponseId(projects.quoteRequestId);
                } else {
                  setOpen(true);
                }
              }}>
                <MTypography>
                  <FormattedMessage id="view.draft" />
                </MTypography>
              </MMenuItem>
              : null
            }

            {((projects.proposalSent == 0) || (status == "assigned" && projects.status != 2 && projects.isPO == 0)) &&
              <div>
                {(status && (status == "assigned" || (status == "open" && projects.isPO == 1))) ?
                  <div> {projects.quoteRequestId == 0 || projects.status == 3 || projects.status == 4 || projects.status == 10 || projects.status == 13 || projects.status == 14 || projects.status == 15 || projects.status == 16 || projects.status == 17 ? <div>
                    {appUserRole != 'ROLE_CLIENT' && projects.isPO == 1 && projects.isPOSent == 1 &&
                      <div>
                        {/*<MMenuItem onClick={() => {*/}
                        {/*handleCloseMenu();*/}
                        {/*localStorage.setItem('selectedJob', String(projects.id));*/}
                        {/*setSendPOOpenModal(true);*/}
                        {/*setSelectedJob(projects);*/}
                        {/*}}>*/}
                        {/*<MTypography>*/}
                        {/*<FormattedMessage id="view.po" />*/}
                        {/*</MTypography>*/}
                        {/*</MMenuItem>*/}
                      </div>
                    }

                    {appUserRole != 'ROLE_CLIENT' && appUserRole != 'ROLE_TRANSLATOR' && appUserRole !== "ROLE_VENDOR" ?
                      <div>
                        <MMenuItem onClick={() => {
                          handleCloseMenu();
                          localStorage.setItem('selectedJob', String(projects.id))
                          localStorage.setItem('selectedType', 'task');
                          history.push('/my-jobs/files/' + projects.encryptedId);
                        }}>
                          <MTypography>
                            <FormattedMessage id="view.files" />
                          </MTypography>
                        </MMenuItem>
                      </div>
                      : ""
                      // remove detail from else case 
                    }
                  </div> :
                    <div>
                    </div>
                  }</div>
                  :
                  <div>
                  </div>
                }
              </div>
            }

            {appUserRole == 'ROLE_CLIENT' && currentUser.isPrivate && projects.customerStatus == 4 &&
              <MMenuItem onClick={() => {
                handleCloseMenu();
                setSelectedJob(projects)
                setApproveOpen(true)
              }}>
                <MTypography>
                  <FormattedMessage id="approve.task" />
                </MTypography>
              </MMenuItem>
            }

            {/*{appUserRole == 'ROLE_CLIENT' && currentUser.isPrivate && (projects.customerStatus == 4 || projects.customerStatus == 10) &&*/}
              {/*<MMenuItem onClick={() => {*/}
                {/*handleCloseMenu();*/}
                {/*const url = (process.env.REACT_APP_API_END_POINT + '/download/file?fileType=target&downloadType=zip&task_id=' + (projects ? projects.id : 0) + '&task_name=' + projects.jobTitle.split('#')[1]);*/}
                {/*window.location.href = url;*/}
              {/*}}>*/}

                {/*<MTypography>*/}
                  {/*<FormattedMessage id="download.files" />*/}
                {/*</MTypography>*/}
              {/*</MMenuItem>}*/}

            <MMenuItem onClick={() => {
              handleCloseMenu();
              setQuoteRequestedId(projects.quoteRequestId);
              setSelectedJob(projects);
              setIsJobDetailDrawer(true)
            }}>

              <MTypography>
                <FormattedMessage id="view.details" />
              </MTypography>
            </MMenuItem>

            {/*{appUserRole != 'ROLE_CUSTOMER' && projects && projects.status == 3 &&*/}
            {/*<MMenuItem onClick={() => {*/}
            {/*handleCloseMenu();*/}
            {/*if (projects.linguistBatchExist == 0) {*/}
            {/*setIsLinguistCompleted(true);*/}
            {/*} else {*/}
            {/*setIsLinguistCompleted(false);*/}
            {/*}*/}
            {/*setCompleteOpen(true);*/}
            {/*}}>*/}
            {/*<MTypography>*/}
            {/*<FormattedMessage id="dispatch" />*/}
            {/*</MTypography>*/}
            {/*</MMenuItem>*/}
            {/*}*/}
            {/*{(appUserRole != 'ROLE_CUSTOMER' && projects && projects.invoiceId == 0 && status != "open") &&*/}
            {/*<MMenuItem onClick={() => {*/}
            {/*handleCloseMenu();*/}
            {/*setSelectedJob(projects);*/}
            {/*setQuoteRequestedId(projects.quoteRequestId);*/}
            {/*setSendInvoiceOpenModal(true)*/}
            {/*}}>*/}
            {/*<MTypography>*/}
            {/*<FormattedMessage id="send.invoice" />*/}
            {/*</MTypography>*/}
            {/*</MMenuItem>*/}
            {/*}*/}
            {/*{(appUserRole != 'ROLE_CUSTOMER' && projects && projects.invoiceId > 0) &&*/}
            {/*<MMenuItem onClick={() => {*/}
            {/*handleCloseMenu();*/}
            {/*setQuoteRequestedId(projects.quoteRequestId);*/}
            {/*setViewDetailOpenModal(true);*/}
            {/*if (typeof setInvoiceId !== "undefined") {*/}
            {/*setInvoiceId(projects.invoiceId);*/}
            {/*}*/}
            {/*}}*/}
            {/*>*/}
            {/*<MTypography>*/}
            {/*<FormattedMessage id="view.invoice" />*/}
            {/*</MTypography>*/}
            {/*</MMenuItem>*/}
            {/*}*/}

            {(appUserRole == 'ROLE_CLIENT' && currentUser.isPrivate && projects && projects.customerStatus >= 13) &&
              <MMenuItem onClick={() => {
                handleCloseMenu();
                setQuoteRequestedId(projects.quoteRequestId);
                setViewDetailOpenModal(true);
                if (typeof setInvoiceId !== "undefined") {
                  setInvoiceId(projects.customerInvoiceId);
                }
              }}
              >
                <MTypography>
                  <FormattedMessage id="view.invoice" />
                </MTypography>
              </MMenuItem>
            }
          </MMenu>
        </MBox>
        {/* </MBox> */}

        <MBox display="flex" justifyContent="space-between" alignItems="center">
          <MBox flex="2">
            <MBox fontFamily="Poppins"
              className={`${classes.captionHeading} textUppercase`}>
              <FormattedMessage id="language.pairs" />
            </MBox>
            <MBox fontSize={14} fontFamily="Poppins" color="#3a3a3a">
              {projects.sourceLanguage && projects.sourceLanguage}
              &nbsp;to&nbsp;
              {(projects.targetLanguage &&
                projects.targetLanguage.slice(0, 3).map((item: ILanguage, index: number) => (
                  <span key={"span" + index}>
                    {item.code}
                    {projects.targetLanguage.length === 2 && index !== 1 && ','}
                    {projects.targetLanguage.length >= 3 && index !== 2 && ','}
                    &nbsp;
                  </span>
                ))
              )}
              {(projects?.targetLanguage && projects?.targetLanguage?.length > 3) &&
                (<LightTooltip
                  arrow
                  title={moreTargetText(projects.targetLanguage && projects.targetLanguage)}
                  placement="top">
                  <NavLink to="#">
                    {projects.targetLanguage && (projects.targetLanguage.length - 3)} <FormattedMessage id="more" />
                  </NavLink>
                </LightTooltip>)
              }
            </MBox>
          </MBox>
          <MBox flex="2" className={classes.col}>
            {projects.services && projects.services.length > 0 &&
              <MBox fontFamily="Poppins" className={`${classes.captionHeading} textUppercase`}>
                <FormattedMessage id="services" />
              </MBox>
            }
            <MBox fontSize={14} fontFamily="Poppins" color="#3a3a3a">
              {(projects?.services &&
                projects?.services?.slice(0, 2)?.map((item: IService, index: number) => (
                  <MBox key={"projectServices" + index} display="inline-block" mr={1}>
                    {item.name}
                    {projects.services.length === 2 && index !== 1 && ','}
                    {projects.services.length >= 3 && index !== 2 && ','}
                    &nbsp;
                  </MBox>
                ))
              )}
              {(projects?.services && projects?.services?.length > 2) &&
                (<LightTooltip
                  arrow
                  title={servicesText(projects.services && projects.services)}
                  placement="top">
                  <NavLink to="#">
                    {projects.services && (projects.services.length - 2)} <FormattedMessage id="more" />
                  </NavLink>
                </LightTooltip>)
              }
            </MBox>
          </MBox>
          {appUserRole !== 'ROLE_TRANSLATOR' && status == "open" && <MBox flex="2">
            <MBox fontFamily="Poppins"
              className={`${classes.captionHeading} textUppercase`}>
              <FormattedMessage id="words.count" />
            </MBox>
            <MBox fontSize={14} color="#3a3a3a">
              {projects.wordCount ? projects.wordCount : 'N/A'}
            </MBox>
          </MBox>}
          {hideFilter != true &&
            <MBox flex="1">
              <MBox fontFamily="Poppins"
                className={`${classes.captionHeading} textUppercase`}>
                <FormattedMessage id="deadline" />
              </MBox>
              <MBox fontSize={14}>
                {timeZone(projects.deadline, currentUser, 0)}
              </MBox>
            </MBox>
          }
          <MBox flex="1" className={classes.colStatus} display="flex" alignItems="center">
            {/*<MBox className={`${classes.status} ${projects.status !== 4 ? (projects.status == 8?'pending':'inprogress') : 'completed'}`}>*/}
            {projects && projects.invoiceId && projects.invoiceId > 0 && 1 != 1 ? <>
              <MBox
                className={`${classes.status} ${projects.customerStatus == 4 ? 'completed' : (projects.customerStatus == 13 ? 'invoicePending' : (projects.customerStatus == 14 ? 'approved invoice_2' : (projects.customerStatus == 16 ? 'approved invoice_5' : 'approved')))} ${projects.invoiceStatus >= 0 && projects.customerStatus != 10 && (appUserRole != 'ROLE_CLIENT' && projects.customerStatus != 4 && projects.customerStatus != 10) ? 'invoice_' + projects.invoiceStatus : ''}`}>
                {/* let statusArray = ['','Sent','Paid','Sent(Revised)','Rejected','Review']; */}
                {/* {projects && projects.invoiceStatus && 'Invoice ' + statusArray[parseInt(projects.invoiceStatus)]} */}
                {appUserRole != 'ROLE_CLIENT' || projects.customerStatus == 4 || projects.customerStatus == 10 || projects.customerStatus == 13 || projects.customerStatus == 14 || projects.customerStatus == 16 || projects.customerStatus == 15 ?
                  <>
                    {appUserRole != 'ROLE_CLIENT' && statusArray[parseInt(projects.invoiceStatus)] == 'Sent' &&
                      <MInvoicePending />
                    }
                    {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 4 &&
                      <MDispatched />
                    }
                    {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 10 &&
                      <DoneAllIcon />
                    }
                    {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 13 &&
                      <MInvoicePending />
                    }
                    {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 14 &&
                      <MInvoicePaidBlue />
                    }
                    {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 16 &&
                      <MInvoiceReview />
                    }
                    {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 15 &&
                      <MInvoiceApproved />
                    }
                    {(appUserRole != 'ROLE_CLIENT' || (projects.customerStatus != 4 && projects.customerStatus != 10 && projects.customerStatus != 13 && projects.customerStatus != 14 && projects.customerStatus != 16 && projects.customerStatus != 15)) && statusArray[parseInt(projects.invoiceStatus)] == 'Paid' &&
                      <MInvoicePaidBlue />
                    }
                    {statusArray[parseInt(projects.invoiceStatus)] == 'Sent(Revised)' &&
                      <MInvoiceApproved />
                    }
                    {statusArray[parseInt(projects.invoiceStatus)] == 'Rejected' &&
                      <MInvoiceReview />
                    }
                    {statusArray[parseInt(projects.invoiceStatus)] == 'Review' &&
                      <MInvoiceReview />
                    }
                    {statusArray[parseInt(projects.invoiceStatus)] == "Sent" && appUserRole != 'ROLE_CLIENT' ?
                      <MBox>
                        <MBox color="#A0A5B9">
                          <FormattedMessage id="invoice.sent" />
                        </MBox>
                      </MBox>
                      :
                      <>
                        {(appUserRole != 'ROLE_CLIENT' && projects.customerStatus != 4 && projects.customerStatus != 10 && projects.customerStatus != 13 && projects.customerStatus != 16 && projects.customerStatus != 15) ? 'Invoice ' + statusArray[parseInt(projects.invoiceStatus)] : ''}
                        {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 4 ? 'Delivered' : ''}
                        {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 10 ? 'Approved' : ''}
                        {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 13 ? 'Invoice Received' : ''}
                        {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 14 ? 'Invoice Paid' : ''}
                        {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 16 ? 'Invoice Review' : ''}
                        {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 15 ? 'Invoice Received (Revised)' : ''}
                      </>

                    }</> :
                  <>
                    <MBox className={`${classes.status} ${(appUserRole == 'ROLE_CLIENT' && (projects.status == 8 || projects.status == 2)) ? '' : 'inprogress'}`}>
                      <>
                        {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 4 &&
                          <><MDispatched /> <FormattedMessage id="delivered" /></>
                        }
                        {appUserRole == 'ROLE_CLIENT' && projects.customerStatus == 10 &&
                          <><DoneAllIcon /> <FormattedMessage id="approved" /></>
                        }
                        {appUserRole == 'ROLE_CLIENT' && (projects.status == 8 || projects.status == 2) &&
                          <><MInvoicePending /> <FormattedMessage id="pending" /></>
                        }
                        {appUserRole != 'ROLE_CLIENT' || (projects.customerStatus != 4 && projects.customerStatus == 10 && projects.status != 2 && projects.status != 8) &&
                          <><AutorenewOutlinedIcon /> <FormattedMessage id="working" /></>
                        }
                      </>
                    </MBox>
                  </>
                }
              </MBox> </> : <>
              {appUserRole != 'ROLE_CLIENT' ?
                <>
                  {(status && status == "assigned") ?
                    <MBox className={`${classes.status} ${(projects.status == 4 || projects.status == 10) ? (projects.status == 10 ? 'approved' : 'completed') : ((projects.proposalSent == 0 && projects.status != 3) ? 'Pending' : 'inprogress')}`}>
                      {projects.status == 4
                        ?
                        <>
                          <MDispatched /> <FormattedMessage id="delivered" />
                        </>
                        :
                        ((projects.status != 3)
                          ?
                          (projects.status != 10
                            ?
                            <>
                              <HourglassEmptyOutlinedIcon sx={{ fontSize: "16px" }} /> <FormattedMessage id="pending" />
                            </>
                            :
                            <>
                              <DoneAllIcon /> <FormattedMessage id="approved" />
                            </>
                          )
                          :
                          <>
                            <AutorenewOutlinedIcon /> <FormattedMessage id="working" />
                          </>
                        )
                      }
                    </MBox>
                    :
                    <>
                      {projects.status == 8 && projects.isPOReview == 1 && 1 != 1
                        ?
                        <>
                          <MBox className={`${classes.status}`} color="rgb(102, 101, 221)">
                            <MInvoiceReview /> <FormattedMessage id="po.review" />
                          </MBox>
                        </>
                        :
                        <MBox className={`${classes.status} ${projects.status == 4 ? 'completed' : ((projects.proposalSent == 0 && projects.status != 3) ? 'Pending' : 'Pending')}`}>
                          {projects.status == 4
                            ?
                            <>
                              <MDispatched /><FormattedMessage id="delivered" />
                            </>
                            :
                            ((projects.proposalSent == 0 && projects.status != 3)
                              ?
                              (
                                projects.status != 10
                                  ?
                                  <>
                                    <HourglassEmptyOutlinedIcon sx={{ fontSize: "16px" }} />
                                    <FormattedMessage id="pending" />
                                  </>
                                  :
                                  <>
                                    <DoneAllIcon /> <FormattedMessage id="approved" />
                                  </>
                              )
                              :
                              <>
                                <HourglassEmptyOutlinedIcon sx={{ fontSize: "16px" }} /> <FormattedMessage id="pending" />
                              </>)
                          }
                        </MBox>}
                    </>
                  }
                </>
                :
                <MBox className={`${classes.status} ${projects.customerStatus == 4 ? 'completed' : projects.customerStatus == 10 ? 'approved' : (projects.status == 2 || projects.status == 8) ? '' : 'inprogress'}`}>
                  <>
                    {projects.customerStatus == 4 &&
                      <><MDispatched /> <FormattedMessage id="delivered" /></>
                    }
                    {projects.customerStatus == 10 &&
                      <><DoneAllIcon /> <FormattedMessage id="approved" /></>
                    }
                    {(projects.status == 2 || projects.status == 8) &&
                      <><MInvoicePending /> <FormattedMessage id="pending" /></>
                    }
                    {projects.customerStatus != 10 && projects.customerStatus != 4 && projects.status != 2 && projects.status != 8 ?
                      <><AutorenewOutlinedIcon /> <FormattedMessage id="working" /></> : ''
                    }
                  </>
                </MBox>
              }
            </>
            }
          </MBox>
          {/* <MBox flex="1" display="flex" flexDirection="column" alignItems="center">
                      <MBox fontFamily="Poppins" className={`${classes.captionHeading} textUppercase`}>
                          <FormattedMessage id="created.at" />
                      </MBox>
                      <MBox className={`${classes.timeAgo}`}>
                          {projects.requestedMinutes !== 0
                              ?
                              <>
                                  {convertMin((projects && projects.requestedMinutes ? projects.requestedMinutes : 0))} <FormattedMessage id="ago" />
                              </>
                              :
                              <>
                                  <FormattedMessage id="just.now" />
                              </>
                          }
                      </MBox>
                  </MBox> */}
          {appUserRole !== "ROLE_VENDOR" && (jobEdit || jobView) ?
            <MBox flex="1" className={classes.colStatus} display="flex" alignItems="center"
              justifyContent="space-around">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget);
                }}>
                <MoreVertIcon sx={{ fill: '#a0a5b9' }} />
              </IconButton>
            </MBox> : ''
          }
          {appUserRole === "ROLE_VENDOR" && (jobEdit || jobView) ?
            <MBox flex="1" className={classes.colStatus} display="flex" alignItems="center"
              justifyContent="space-around">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => {
                  if (status === "assigned") {
                    history.push('/vendor/projects/my-jobs/' + projects.encryptedId)
                  } else {
                    handleCloseMenu();
                  }
                  setQuoteRequestedId(projects.quoteRequestId);
                  setSelectedJob(projects);
                  setIsJobDetailDrawer(true)
                }}>
                <VisibilityIcon sx={{ fill: '#a0a5b9' }} />
              </IconButton>
            </MBox> : ''
          }
        </MBox>
      </MBox >
    </>
  )
}

export default SingleJob;