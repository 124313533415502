/* eslint-disable */
import React, { MouseEvent, useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { useLazyQuery } from "@apollo/react-hooks";
import Fade from "@mui/material/Fade";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import GridViewIcon from '@mui/icons-material/GridView';
import GroupIcon from "@mui/icons-material/Group";
import { styled } from "@mui/material/styles";

import { showAlert, showModal } from "src/app/store";
import useStyles from "./VendorSidebarStyles";
import SidebarSkeleton from "./SidebarSkeleton";
import OrganizationDropdown from "./organization-dropdown/OrganizationDropdown";
import { avatarPlaceholder } from "src/app/hooks";
import CheckPermissions from "src/app/hooks/permissions/CheckPermissions";
import {
  MAvatar,
  MBox,
  MIconButton,
  MList,
  MGrid,
  MListItem,
  MListItemIcon,
  MListItemText,
  MMenu,
} from "src/app/material-ui";
import { useIntl } from "react-intl";

const GET_RATE_REQUEST_BIT = gql`
    query getRateRequestsBit($orgId: Int!){
        getRateRequestsBit(orgId: $orgId)
        
    }`;
function VendorSidebar() {
  const classes = useStyles();
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const selectedOrgLoading = useSelector(
    (store: any) => store.orgs.selectedOrgLoading
  );


  // const [vendorId,setVendorId]: any = useState('');
  const [isVendorAdmin, setIsVendorAdmin] = useState(0);
  const [allowList, setAllowList] = useState(false);
  const [peopleList, setPeopleList] = useState(false);
  const [suppliersList, setSuppliersList] = useState(false);
  const [findNewsuppliersList, setFindNewsuppliersList] = useState(false);
  const [rateRequestList, setRateRequestList] = useState(false);
  const [ratesList, setRatesList] = useState(false);
  const [customerList, setCustomerList] = useState(false);
  const [glossaryList, setGlossaryList] = useState(false);
  const [allowSidebarTM, setAllowSidebarTM] = useState(false);
  const [styleGuideList, setStyleGuideList] = useState(false);
  const [mathchRateList, setMatchRateList] = useState(false);
  const [isAllowOpenJobs, setIsAllowOpenJobs] = useState(false);
  const [isAllowMyJobs, setIsAllowMyJobs] = useState(false);
  const [isRateRequestDot, setIsRateRequestDot]: any = useState('false');

  const organizations = useSelector((store: any) => store.orgs.organizations);
  const appUserRole = useSelector((store: any) => store.userRole.userRole);
  const storeOrgs = useSelector((store: any) => store.orgs.organizations);
  // const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
  const isOwner = useSelector((store: any) => store.orgs.isOwner);
  const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
  const currentUser = useSelector((store: any) => store.auth.currentUser);
  const [slug, setSlug] = useState(currentUser.slug);
  const history = useHistory();

  const [getRateRequestBit] = useLazyQuery(
    GET_RATE_REQUEST_BIT, {
      variables: { orgId: selectedOrgId },
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        setIsRateRequestDot(data && data.getRateRequestsBit)
      }
    } as any);



  useEffect(() => {
    if (selectedOrgId) {
      getRateRequestBit();
    }
    setIsVendorAdmin(organizations?.find((itm: any) => itm.id == selectedOrgId)?.isVendorAdmin);

  }, [selectedOrgId, organizations]);

  useEffect(() => {
    setAllowList(CheckPermissions('ROLE_PROJECT_LIST', selectedOrgId, organizations));
    setPeopleList(CheckPermissions('ROLE_PEOPLE_LIST', selectedOrgId, organizations));
    setSuppliersList(CheckPermissions('ROLE_MY_SUPPLIERS_LIST', selectedOrgId, organizations));
    setFindNewsuppliersList(CheckPermissions('ROLE_FIND_NEW_SUPPLIERS_LIST', selectedOrgId, organizations));
    setRateRequestList(CheckPermissions('ROLE_RATE_REQUEST_LIST', selectedOrgId, organizations));
    setRatesList(CheckPermissions('ROLE_RATES_LIST', selectedOrgId, organizations));
    setCustomerList(CheckPermissions('ROLE_CUSTOMERS_LIST', selectedOrgId, organizations));
    setGlossaryList(CheckPermissions('ROLE_GLOSSARY_LIST', selectedOrgId, organizations));
    setAllowSidebarTM(CheckPermissions("ROLE_TM_LIST", selectedOrgId, organizations));
    setStyleGuideList(CheckPermissions('ROLE_STYLE_GUIDE_LIST', selectedOrgId, organizations));
    setMatchRateList(CheckPermissions('ROLE_MATCH_RATE_LIST', selectedOrgId, organizations));
    setIsAllowOpenJobs(CheckPermissions('ROLE_OPEN_JOBS_LIST', selectedOrgId, organizations));
    setIsAllowMyJobs(CheckPermissions('ROLE_MY_JOBS_LIST', selectedOrgId, organizations));
  }, [selectedOrgId, organizations]);

  useEffect(() => {
    if (currentUser) {
      setAvatarSrc(
        currentUser.profilePicture &&
        process.env.REACT_APP_PUBLIC_URL + "/" + currentUser.profilePicture
      );
      setSlug(currentUser.slug);
    }
  }, [currentUser]);

  const [avatarSrc, setAvatarSrc] = useState(
    currentUser &&
    currentUser.profilePicture &&
    process.env.REACT_APP_PUBLIC_URL + "/" + currentUser.profilePicture
  );

  const urlType = window.location.href.split("/");
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // const [getVendorName] = useLazyQuery(VENDOR_PROFILE, {
  //     fetchPolicy: 'no-cache',
  //     onCompleted: (data: any) => {
  //         // setVendorId(data && data.getVendorProfile.id);
  //         setAvatarSrc(data && data.getVendorProfile && data.getVendorProfile.vendor_logo && process.env.REACT_APP_PUBLIC_URL + '/' + data.getVendorProfile.vendor_logo);
  //     }
  // });

  const dispatch = useDispatch();

  function deleteCookie(name: any) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Lax";
  }

  function getCookie(name: any) {
    let cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      let [key, value] = cookie.split("=");
      if (key === name) {
        return decodeURIComponent(value);
      }
    }
    return null; // Return null if not found
  }


  const logout = () => {
    const token = getCookie("access_token");
    localStorage.clear();
    deleteCookie('access_token');

    window.location.href = "" + process.env.REACT_APP_SSO_LOGOUT_URL + token;
    dispatch(
      showAlert({
        message: intl.formatMessage({ id: "signing.out" }),
        messageType: "error",
        showAlertMessage: true,
      })
    );
    // localStorage.clear()
    // dispatch(resetState());
    // props.history.push('/')
  };

  const isProfileComplete = (link: any) => {
    if (typeof slug === undefined) {
      location.reload()
    } else {
      (currentUser.isProfile || !isOwner) ? history.push(link) : dispatch(showModal(true));
    }
  };

  // useEffect(() => {
  //     getVendorName();
  // }, []);
  const LightTooltip = styled(
    React.forwardRef(function LightTooltip({ className, ...props }: TooltipProps, ref) {
      return (
        <Tooltip
          {...props}
          classes={{ ...props?.classes, popper: className || '' }}  // Ensure className is passed safely
          ref={ref}
        />
      );
    })
  )(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#6b778c",
      fontSize: 12,
      filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
      border: "1px solid #f4f5f7",
      borderRadius: 0,
      padding: "20px 15px",
      maxWidth: 230,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
  }));

  return (
    // <Zoom in={props.drawerState} unmountOnExit>
    <MBox className={classes.sidebarWrap}>
      <MGrid item className={classes.logoBox}>
        <NavLink to="/projects">
          <img
            src="/assets/images/marshub-logo.svg"
            className="logoFull"
            alt="logo"
            width={"140px"}
            height={"64px"}
          //  loading="lazy"
          />
          <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" width="140px" loading='lazy' />
          {/* <img src="/assets/images/marshub-logo.svg" alt="logo" width="140px" /> */}
        </NavLink>
      </MGrid>
      {selectedOrgLoading ? <SidebarSkeleton isVendor={true} />
        :
        <>
          <MBox style={{ marginBottom: 9 }}>

            {/*<MBox display="flex" justifyContent="space-between">*/}
            {/*/!* <MTypography variant="subtitle1" component="span">{vendorName}</MTypography> *!/*/}
            {/*<MTypography variant="subtitle1" component="span">Vendor Menu</MTypography>*/}
            {/*</MBox>*/}

            {storeOrgs && storeOrgs.length > 0 &&
              <MGrid item >
                <OrganizationDropdown className="orgTest" headerText="Organizations" footerText="See All" />
              </MGrid>
            }
          </MBox>
          <MBox>
            <MBox className="generalLinks">
              <MList className={classes.sideBarLinks}>
                {/*<MListItem button  to="/create-project">*/}
                {/*<MListItemIcon><MDashboardOutlinedIcon/></MListItemIcon>*/}
                {/*<MListItemText primary="Create Project"/>*/}
                {/*</MListItem>*/}
                {/*<MListItem button component={NavLink} to="/projects">*/}
                {/*<MListItemIcon><MDashboardOutlinedIcon/></MListItemIcon>*/}
                {/*<MListItemText primary=" View Projects"/>*/}
                {/*</MListItem>*/}
                {/*{selectedOrgId}*/}
                {/*{isVendorAdmin?'1':'0'}*/}

                {!isVendorAdmin && <>
                  <>
                    {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner == true &&
                      <MListItem button component={NavLink} to={'/vendor/dashboard'} className={urlType[3] === 'client/dashboard' ? 'active' : ''}>
                        <MListItemIcon>
                          <LightTooltip arrow placement="right" title={<FormattedMessage id="dashboard" />}>
                            <GridViewIcon />
                          </LightTooltip>
                        </MListItemIcon>
                        <MListItemText primary={<FormattedMessage id="dashboard" />} />
                      </MListItem>}
                  </>
                  {(allowList || isAllowMyJobs) && <MListItem button onClick={() => { (!allowList && isAllowMyJobs) ? isProfileComplete("/vendor/projects/my-jobs") : isProfileComplete("/projects") }} className={(((appUserRole === "ROLE_VENDOR") && urlType[4] === 'projects') || urlType[3] === 'projects' || urlType[3] === 'create-project' || urlType[4] === 'github-integration') ? 'active' : ''}>
                    <MListItemIcon>
                      <LightTooltip arrow placement="right" title={<FormattedMessage id="projects" />}>
                        <DashboardOutlinedIcon />
                      </LightTooltip>
                    </MListItemIcon>
                    <MListItemText primary={<FormattedMessage id="projects" />} />
                  </MListItem>
                  }
                  {(isAllowMyJobs || isAllowOpenJobs) &&
                    <>
                      <MListItem button
                        className={(urlType[4] === 'open-jobs' || urlType[4] === 'my-jobs' || urlType[4] === 'files') ? 'active' : ''}
                        onClick={() => isProfileComplete("/vendor/open-jobs")}>

                        <MListItemIcon>
                          <LightTooltip arrow placement="right" title={<FormattedMessage id="jobs" />}>
                            <NextWeekIcon />
                          </LightTooltip>
                        </MListItemIcon>
                        <MListItemText primary={<FormattedMessage id="jobs" />} />
                      </MListItem>
                    </>
                  }

                  {/* <MListItem button onClick={() => isProfileComplete("/vendor/services")} >
                                    <MListItemIcon>
                                        <LightTooltip arrow placement="right" title={<FormattedMessage id="services" />}>
                                            <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.7375 16.5V12.1H17.4375V13.2H15.2375V12.1H8.6375V13.2H6.4375V12.1H3.1375V16.5H20.7375ZM5.7005 5.5L3.7865 9.9H6.4375V8.8H8.6375V9.9H15.2375V8.8H17.4375V9.9H20.0885L18.1745 5.5H5.7005ZM8.6375 2.2V3.3H15.2375V2.2H8.6375ZM22.7615 10.571C22.8715 10.813 22.9375 11.099 22.9375 11.451V16.5C22.9375 17.083 22.7065 17.6 22.2775 18.051C21.8375 18.491 21.3425 18.7 20.7375 18.7H3.1375C2.5325 18.7 2.0375 18.491 1.5975 18.051C1.1685 17.6 0.9375 17.083 0.9375 16.5V11.451C0.9375 11.099 1.0035 10.813 1.1135 10.571L3.6875 4.642C4.0615 3.751 4.7325 3.3 5.7005 3.3H6.4375V2.2C6.4375 1.595 6.6355 1.1 7.0645 0.649C7.4935 0.22 8.0215 0 8.6375 0H15.2375C15.8535 0 16.3815 0.22 16.8105 0.649C17.2395 1.1 17.4375 1.595 17.4375 2.2V3.3H18.1745C19.1425 3.3 19.8135 3.751 20.1875 4.642L22.7615 10.571Z" fill="#6B778C" />
                                            </svg>
                                        </LightTooltip>
                                    </MListItemIcon>
                                    <MListItemText primary={<FormattedMessage id="services" />} />
                                </MListItem> */}
                  {/*{(rateRequestList || ratesList) && <MListItem button onClick={() => isProfileComplete("/vendor/rate/request")} className={(urlType[5] === 'request' || urlType[4] === 'rates') ? 'active' : ''} >*/}
                  {/*<MListItemIcon>*/}
                  {/*<LightTooltip arrow placement="right" title={<FormattedMessage id="rate.request" />}>*/}
                  {/*<MonetizationOnOutlinedIcon />*/}
                  {/*</LightTooltip>*/}
                  {/*{isRateRequestDot == 'true' &&*/}
                  {/*<MBadge variant="dot" color="secondary" className={classes.dotBagde}>*/}
                  {/*</MBadge>*/}
                  {/*}*/}
                  {/*</MListItemIcon>*/}
                  {/*<MListItemText primary={<FormattedMessage id="rate.request" />} />*/}
                  {/*</MListItem>}*/}
                  {/*{mathchRateList &&*/}
                  {/*<MListItem button onClick={() => isProfileComplete("/vendor/match-rates")} className={urlType[4] === 'match-rates' ? 'active' : ''} >*/}
                  {/*<MListItemIcon>*/}
                  {/*<LightTooltip arrow placement="right" title={<FormattedMessage id="match.rates" />}>*/}
                  {/*<span>*/}
                  {/*<MMatchRate height={24} width={24} fill={urlType[4] === 'match-rates' ? '#FF5100' : '#6B778C'} />*/}
                  {/*</span>*/}
                  {/*</LightTooltip>*/}
                  {/*</MListItemIcon>*/}
                  {/*<MListItemText primary={<FormattedMessage id="match.rates" />} />*/}
                  {/*</MListItem>}*/}
                </>}

                {(suppliersList || findNewsuppliersList) && <MListItem button onClick={() => isProfileComplete('/vendors')} className={(urlType[3] === 'vendors' || urlType[3] === 'find-vendor') ? 'active' : ''}>
                  <MListItemIcon>
                    <LightTooltip arrow placement="right" title={<FormattedMessage id="suppliers" />}>
                      <PersonOutlinedIcon />
                    </LightTooltip>
                  </MListItemIcon>
                  <MListItemText primary={<FormattedMessage id="suppliers" />} />
                </MListItem>}

                {!isVendorAdmin && <>
                  {peopleList && <MListItem button component={NavLink} to={'/manage-admins'} className={(urlType[3] === 'manage-admins-invite' || urlType[3] === 'manage-group') ? 'active' : ''} >
                    <MListItemIcon>
                      <LightTooltip arrow placement="right" title={<FormattedMessage id="people" />}>
                        <PeopleAltOutlinedIcon />
                      </LightTooltip>
                    </MListItemIcon>
                    <MListItemText primary={<FormattedMessage id="people" />} />
                  </MListItem>}
                  {customerList && <MListItem button onClick={() => isProfileComplete('/customers')} className={urlType[3] === 'customers' ? 'active' : ''}>
                    <MListItemIcon>
                      <LightTooltip arrow placement="right" title={<FormattedMessage id="customers" />}>
                        <GroupIcon />
                      </LightTooltip>
                    </MListItemIcon>
                    <MListItemText primary={<FormattedMessage id="customers" />} />
                  </MListItem>}
                </>}

                {/* <MListItem button  onClick={()=>isProfileComplete()} to={'/buy-credits'} className={urlType[3] === 'customers' ? 'active' : ''}>
                                    <MListItemIcon>
                                        <LightTooltip arrow placement="right" title={<FormattedMessage id="buy.credits" />}>
                                            <span>
                                                <MBuyCredits />
                                            </span>
                                        </LightTooltip>
                                    </MListItemIcon>
                                    <MListItemText primary={<FormattedMessage id="buy.credits" />} />
                                </MListItem> */}

              </MList>
            </MBox>
            {!isVendorAdmin && <>
              <MBox className={classes.generalLinks} px={1.2} pb={12}>
                {(glossaryList || allowSidebarTM || styleGuideList) &&
                  <MBox className="orgFull">
                    <FormattedMessage id="org.resources" />
                  </MBox>}
                {/* <MBox className="orgSm">Organization Resources</MBox> */}
              </MBox>
              <MBox className="generalLinks" pb={9.5}>
                <MList className={classes.sideBarLinks}>
                  {/*<MListItem button  onClick={()=>isProfileComplete()} to={`/files`}>*/}
                  {/*<MListItemIcon style={{ color: '#6b778c' }}>*/}
                  {/*<LightTooltip arrow placement="right" title="Global Files">*/}
                  {/*<DescriptionOutlinedIcon />*/}
                  {/*</LightTooltip>*/}
                  {/*</MListItemIcon>*/}
                  {/*<MListItemText primary={<FormattedMessage id="file.drive" />} />*/}
                  {/*</MListItem>*/}
                  {allowSidebarTM &&
                    <MListItem button onClick={() => isProfileComplete(`/tm`)} className={urlType[3] === 'tm' ? 'active' : ''} >
                      <MListItemIcon style={{ color: '#6b778c' }}>
                        <LightTooltip arrow placement="right" title={<FormattedMessage id="translation.memory" />}>
                          <MemoryOutlinedIcon />
                        </LightTooltip>
                      </MListItemIcon>
                      <MListItemText primary={<FormattedMessage id="translation.memory" />} />
                    </MListItem>
                  }
                  {glossaryList &&
                    <MListItem button onClick={() => isProfileComplete(`/glossary`)} className={urlType[3] === 'glossary' ? 'active' : ''}  >
                      <MListItemIcon style={{ color: '#6b778c' }}>
                        <LightTooltip arrow placement="right" title={<FormattedMessage id="glossary" />}>
                          <MenuBookOutlinedIcon />
                        </LightTooltip>
                      </MListItemIcon>
                      <MListItemText primary={<FormattedMessage id="glossary" />} />
                    </MListItem>
                  }
                  {/*<MListItem button  onClick={()=>isProfileComplete()} to={`/projects/style-guides/`+projectId}>*/}
                  {styleGuideList &&
                    <MListItem button onClick={() => isProfileComplete(`/style-guides`)} className={urlType[3] === 'style-guides' ? 'active' : ''}>
                      <MListItemIcon style={{ color: '#6b778c' }}>
                        <LightTooltip arrow placement="right" title={<FormattedMessage id="style.guides" />}>
                          <FeedbackOutlinedIcon />
                        </LightTooltip>
                      </MListItemIcon>
                      <MListItemText primary={<FormattedMessage id="style.guides" />} />
                    </MListItem>
                  }
                </MList>
              </MBox>
            </>}

          </MBox>

          <MGrid className={classes.footerSm} item>
            {/* <NavLink to={(organizations && organizations.length > 0) ? "/projects" : "/create-organization"}> */}
            <MBox className="footerFull">
              <MBox className={classes.projectSidebarFooter + ' userDropdown'}>
                <MBox display="flex" width="calc(100% - 76px)">
                  <MAvatar className={'footerAvatar'} src={avatarSrc} alt="user-avatar" />

                  <MBox ml={1.5} width="100%" className={classes.footerDetails}>
                    {
                      <MBox className={classes.sidebarAvatar}>
                        {
                          // storeOrgs.length > 0 ? storeOrgs[0].name :
                          currentUser.firstName &&
                          <Tooltip placement="top" arrow title={currentUser.firstName + ' ' + currentUser.lastName}>
                            <Box className={classes.sidebarAvatar}>
                              {currentUser.firstName.concat(" " + currentUser.lastName).slice(0, 20) + "..."}
                            </Box>
                          </Tooltip>}
                      </MBox>
                    }

                    <MBox fontSize={14} color="#A2A4B9">
                      {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner == true && appUserRole === "ROLE_VENDOR" && <FormattedMessage id="vendor" />}
                      {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner != true && organizations.find((item: any) => item.id == selectedOrgId)?.groups && organizations.find((item: any) => item.id == selectedOrgId)?.groups.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.groups[0].name}
                      {/*{appUserRole === "ROLE_CLIENT" && 'Client'}*/}
                      {/*{appUserRole === "ROLE_TRANSLATOR" && 'Linguist'}*/}
                    </MBox>
                  </MBox>
                </MBox>
                <MBox mr={-2}>
                  <MIconButton aria-controls="notificationMenu" aria-haspopup="true" color="inherit"
                    onClick={handleClick}>
                    <MoreVertIcon style={{ fontSize: 22, color: '#8d8d8d' }} />
                  </MIconButton>
                </MBox>

                <MMenu id="notificationMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}
                  TransitionComponent={Fade} className={classes.userDropdown}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >

                  {currentUser.isProfile && slug &&
                    <MListItem button onClick={() => {
                      isProfileComplete("/vendor/profile/" + slug);
                      handleClose()
                    }}>
                      <MListItemText primary={<FormattedMessage id={appUserRole === "ROLE_VENDOR" ? "company.profile" : "my.profile"} />} />
                    </MListItem>
                  }

                  {(isOwner) && (appUserRole === "ROLE_VENDOR") &&
                    <MListItem button onClick={() => { isProfileComplete("/payments/tab"); handleClose() }}>
                      <MListItemText primary={<FormattedMessage id="subscriptions" />} />
                    </MListItem>
                  }

                  {isOwner && <MListItem button onClick={() => { history.push(("/vendor/account/settings")); handleClose() }}>
                    <MListItemText primary={<FormattedMessage id="account.settings" />} />
                  </MListItem>}

                  {(isOwner) && (appUserRole === "ROLE_VENDOR" || appUserRole === "ROLE_CLIENT") &&
                    <MListItem button onClick={() => { isProfileComplete("/invoices/payments"); handleClose() }}>
                      <MListItemText primary={<FormattedMessage id="invoices" />} />
                    </MListItem>
                  }

                  {/*<MListItem button onClick={() => { isProfileComplete("/activity-log"); handleClose() }}>*/}
                  {/*<MListItemText primary={<FormattedMessage id="activity.log" />} />*/}
                  {/*</MListItem>*/}

                  <MListItem button onClick={logout} className={`${classes.menuLink} ${classes.logoutLink}`} >
                    <MListItemText primary={<FormattedMessage id="logout" />} />
                  </MListItem>

                </MMenu>

              </MBox>
            </MBox>

            <MBox className="footerSm">
              {currentUser && currentUser.profilePicture
                ?
                <MAvatar onClick={handleClick} className={'footerAvatar'} src={currentUser && currentUser.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture : ""} />
                :
                <MAvatar className={'footerAvatar'}>
                  {currentUser && avatarPlaceholder(`${currentUser.firstName} ${currentUser.lastName}`)}
                </MAvatar>
              }
            </MBox>


          </MGrid>

        </>
      }


    </MBox >
    // </Zoom>
  )
}

export default withRouter(VendorSidebar);