/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import axios from 'axios';
import { FormattedMessage, FormattedTimeParts } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LinearProgress from '@mui/material/LinearProgress';
import SettingsIcon from '@mui/icons-material/Settings';
import { AppBar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { EmptyContent } from 'src/app/components';
import {
    FileTypes,
    MBox, MChip, MDrawer, MGrid, MIconButton, MLink, MToolbar, MTypography, TabsHorizontal,
} from 'src/app/material-ui';
import Bilingual from 'src/app/screens/client-screens/projects/bilingual-files/Bilingual';
import { showAlert } from 'src/app/store';
import useStyles from './FileUploaderModalStyles';


const after_Last_File_Process = gql`
    mutation afterLastFileProcess($projectId: String!,$type: String!,$fileId: String,$langId: ID, $targetLanguageMapping:[LanguageMapping], $sourceLanguageMapping:String, $referenceMapping:String,  $firstRecordAsHeader: Boolean){
        afterLastFileProcess(projectId: $projectId,type: $type,fileId:$fileId,langId:$langId,targetLanguageMapping:$targetLanguageMapping, sourceLanguageMapping:$sourceLanguageMapping, referenceMapping:$referenceMapping, firstRecordAsHeader:$firstRecordAsHeader)
    }
`;

interface IProps {
    projectId?: number,
    setModalOpen?: any,
    modalOpen?: boolean,
    fileType?: string,
    size?: number,
    vendorOrgId?: number,
    langId?: any,
    getProjectData?: any,
    tagParams?: any,
    selectedFolder?: any,
    fileDrive?: boolean,
    targetLanguages?: any,
    languages?: any,
    currentFileName?: any,
    project?: any
    setIsFullWidth?: any
    projectLanguagesLoading?: any
    importNotesAndCommentPopUp?: any
    setImportNotesAndCommentPopUp?: any
    importNotesAndCommentFileMetaId?: any
    setImportNotesAndCommentFileMetaId?: any
    importNotesAndCommentFileMetaIdData?: any
    setImportNotesAndCommentFileMetaIdData?: any
    importNotes?: any
    setImportNotes?: any
    setNoteChecked?: any
    importNotesAndComment?: any

}

export default function FileUploaderModal(props: IProps) {
    const maxSizeBytes = (props.size ? props.size : 500) * 1024 * 1024;
    const classes = useStyles({});
    const dispatch = useDispatch();
    const [selectedOrgId, setSelectedOrgId]: any = useState();
    const [modalCloseValue, setModalCloseValue] = useState(false);
    const [uploadedFileLength, setUploadedFilesLength]: any = useState(0);
    const [uploadedFilesData, setUploadedFilesData]: any = useState([]);
    const [allFilesId, setAllFilesId]: any = useState([]);
    const [uploadedFileIds, setUploadedFileIds]: any = useState([]);
    const [uploadFilesMeta, setUploadFilesMeta]: any = useState([]);
    const [restartUploading, setRestartUploading]: any = useState(false);
    const [startUploading, setStartUploading]: any = useState(false);
    const [fileTypeError, setFileTypeError]: any = useState(false);
    const [fileTags, setFileTags]: any = useState([]);
    const [uploadedIdArray, setUploadedIdArray]: any = useState([]);
    const [bilingualLanguageModal, setBilingualLanguageModal]: any = useState(false);
    const [bilingualLanguageFileMetaId, setBilingualLanguageFileMetaId]: any = useState('');
    const [bilingual, setBilingual]: any = useState([]);
    const [tlLangCode, setTlLangCode]: any = useState('');
    const [referenceMapping, setReferenceMapping]: any = useState([]);
    const [sourceLanguageMapping, setSourceLanguageMapping]: any = useState([]);
    const [setTabClick]: any = useState();
    const [breadcrumb, setBreadCrumb]: any = useState('Bilingual');
    const [checked, setChecked] = React.useState([]);
    const [langCode, setLangCode]: any = useState([]);
    const organizationId = useSelector((store: any) => store.orgs.selectedOrgId);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const orgs = useSelector((store: any) => store.orgs.organizations);
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const tabsData = [
        {
            label: <MBox fontFamily="Poppins" onClick={() => { setBreadCrumb('Bilingual') }} fontSize={14} color='#6B778C'><FormattedMessage id="bilingual" /></MBox>, hash: 'bilingual', content: <Bilingual projectLanguagesLoading={props.projectLanguagesLoading} checked={checked} setChecked={setChecked} bilingual={bilingual} setBilingual={setBilingual} project={props.project} targetLanguages={props.targetLanguages} setTlLangCode={setTlLangCode} referenceMapping={referenceMapping} setReferenceMapping={setReferenceMapping} sourceLanguageMapping={sourceLanguageMapping} setSourceLanguageMapping={setSourceLanguageMapping} setIsFullWidth={props?.setIsFullWidth} langCode={langCode} setLangCode={setLangCode}
                setBilingualLanguageModal={setBilingualLanguageModal} bilingualLanguageFileMetaId={bilingualLanguageFileMetaId} />, isHidden: true
        },
    ];
    const [afterLastFileProcess] = useMutation(after_Last_File_Process, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            setModalCloseValue(false);
            if (typeof props.setModalOpen !== undefined) {
                props.setModalOpen(false);
            }
            if (typeof props.getProjectData !== undefined) {
                props.getProjectData();
            }
            setUploadedFilesData([]);
            setStartUploading(false);
            allFilesId.map((item: any) => (uploadedIdArray.push(item)));
            // getProjectFiles();
        }
    });
    useEffect(() => {

        if (appUserRole && appUserRole == "ROLE_CLIENT" && currentUser.isPrivate) {
            if (orgs) {
                const org = orgs?.filter((org: any) => org.isOwner == true);
                if (org) {
                    // setSelectedOrgId(org[0].id);
                    setSelectedOrgId(org[0] && org[0].id);
                }
            }
        } else {
            setSelectedOrgId(organizationId)
        }
    }, [orgs, appUserRole]);

    const allowedTypes = ["pptx", "docx", 'xlsx'];

    const isValidFileType = (type: any) => {
        // Define allowed MIME types
        const allowedTypesString = process.env.REACT_APP_FILE_TYPES;
        const cleanedFileTypes = allowedTypesString?.replace(/,\s*/g, ',');

        const allowedTypes = cleanedFileTypes?.split(',');
        return allowedTypes?.includes(type);
    };

    const uploadModalOnChange = (fileWithMeta: any, status: any, allFiles: any) => {
        if (!isValidFileType(fileWithMeta.file.type)) {
            fileWithMeta.meta.status = "error"; // Set status to 'error' to highlight the file
        }
        
        if (fileWithMeta.file.size > maxSizeBytes) {
            fileWithMeta.meta.status = "rejected_file_size";
        }

        setUploadFilesMeta(allFiles);
        if (!startUploading && (fileWithMeta.meta.status === "uploading")) {
            fileWithMeta.cancel();
        }

        const restartRequire = allFiles.find((element: any) => (element.meta.status === 'aborted'));
        if (restartRequire !== undefined) {
            setRestartUploading(true);
        } else {
            setRestartUploading(false);
        }

        if (fileWithMeta.meta && fileWithMeta.meta.status === "rejected_file_type") {
            let extensions = process.env.REACT_APP_FILE_EXTENSIONS
            dispatch(showAlert({
                // message: fileWithMeta.meta.name + ' file not supported. Supported files are .csv .xlf .xliff .json .yaml .yml .doc .docx .tmx .sdltm',
                message: 'Files in other formats ' + process.env.REACT_APP_FILES_NOT_ALLOWED + ' are not permitted',
                // message: process.env.REACT_FILES_NOT_ALLOWED,
                messageType: 'error',
                showAlertMessage: true
            }));
        }
    }
    // shortenString = stringVal.substring(0, 3).concat('...');

    const getUploadParams = async ({ file }: any) => {

        // const maxSizeBytes = 1 * 1024 * 1024; // 5MB

        // Check if file size exceeds the maximum size
        if (file.size > maxSizeBytes) {
            // alert("File size exceeds the maximum allowed size.");
            return { abort: true, url: '' }; // Prevent upload
        }

        setModalCloseValue(true);
        const method: any = "PUT";
        const body = new FormData();
        body.append("filename", file.name);
        const { filePath, url } = await axios.post(process.env.REACT_APP_API_END_POINT + '/get-s3-presigned-url', body).then(
            (res: any) => {
                return res.data[0];
            });
        return { url: url, method: method, body: file, meta: { filePath } }
    }

    const getFileTag = (file: any) => {
        let tag = '';
        if (props.tagParams.folderData) {
            props.tagParams.folderData.forEach((sTag: any) => {
                const findFile = fileTags.find((el: any) => el.id === file.id);
                if (findFile) {
                    if (sTag.id === findFile.tagId) {
                        tag = sTag.name;
                    }
                } else {
                    if (sTag.id) {
                        if ((sTag.id === props.tagParams.selectedFolder)) {
                            tag = sTag.name;
                        }
                    } else {
                        // tag = props.tagParams.folderData[0].name;
                    }
                }
            });
        }
        return tag;
    }

    const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }: any) => {
        let count = 0;
        files.forEach(async (file: any, index: any) => {
            if (file.meta.status === "error" || file.meta.status === "rejected_file_size") {
                count++;
            }
            if (props.modalOpen) {
                setUploadedFilesLength(index + 1);
            }
            if (file.meta.status === 'done' && file.xhr.statusText === "OK") {
                const fileResponse: any = file.meta;
                // fileResponse.id = file.meta.id;
                const found = uploadedFilesData.find((f: any) => f.id === fileResponse.id);
                if (!found) {
                    if (uploadedFileLength > 0) {
                        setUploadedFilesData([...uploadedFilesData, fileResponse]);
                        if (uploadedFileIds.find((id: any) => id === file.meta.id) === undefined) {
                            let sourceLanguageMappingR: any = sourceLanguageMapping.find((its: any) => its.fileMetaId == file.meta.id);
                            if (!sourceLanguageMappingR) {
                                sourceLanguageMappingR = {};
                            }

                            let targetLanguageMappingLangCodeR: any = langCode.find((its: any) => its.fileMetaId == file.meta.id);
                            if (!targetLanguageMappingLangCodeR) {
                                targetLanguageMappingLangCodeR = {};
                            }

                            let targetLanguageMappingR: any = bilingual.find((its: any) => its.fileMetaId == file.meta.id);
                            if (!targetLanguageMappingR) {
                                targetLanguageMappingR = {};
                            }

                            let referenceMappingR: any = referenceMapping.find((its: any) => its.fileMetaId == file.meta.id);
                            if (!referenceMappingR) {
                                referenceMappingR = {};
                            }

                            let firstRowHeadingR: any = checked.find((its: any) => its.fileMetaId == file.meta.id);
                            if (!firstRowHeadingR) {
                                firstRowHeadingR = {};
                            }

                            let fileMetaData = {};

                            if (typeof sourceLanguageMappingR != "undefined" && sourceLanguageMappingR != 'undefined') {
                                fileMetaData = {
                                    firstRowHeading: firstRowHeadingR,
                                    sourceMapping: sourceLanguageMappingR,
                                    targetLanguageMappingLangCodeR: targetLanguageMappingLangCodeR,
                                    targetLanguageMappingR: targetLanguageMappingR,
                                    referenceMapping: referenceMappingR
                                };
                            }
                            if (typeof props.importNotesAndCommentFileMetaIdData !== "undefined" && Array.isArray(props.importNotesAndCommentFileMetaIdData) && props.importNotesAndCommentFileMetaIdData.length > 0) {

                                let importNotesAndComment: any = props.importNotesAndCommentFileMetaIdData.find((its: any) => its.importNotesAndCommentFileMetaId == file.meta.id);

                                if (!importNotesAndComment) {
                                    importNotesAndComment = {};
                                }
                                if (typeof importNotesAndComment !== "undefined") {
                                    fileMetaData = {
                                        importNotesAndCommentFileMetaIdData: importNotesAndComment,
                                    };
                                }
                            }
                            // alert(JSON.stringify(importNotesAndCommentFileMetaIdData));

                            const response = await afterFileUpload(file.meta, fileMetaData);
                            //
                            const OldFilesId: any = allFilesId;
                            OldFilesId[index] = response.fileId;
                            setAllFilesId(OldFilesId);
                            if (Object.keys(OldFilesId).length === uploadedFileLength) {



                                // setModalCloseValue(false);
                                const newArray: any = OldFilesId.map((item: any) => { if (uploadedIdArray.indexOf(item) == -1) { return item } });
                                // alert(JSON.stringify(langCode));
                                afterLastFileProcess({
                                    variables: {
                                        projectId: props.projectId,
                                        type: (props.fileType && props.fileType === "preprocessing") ? props.fileType : "source",
                                        fileId: newArray.join(','),
                                        langId: JSON.stringify(props.langId) !== undefined ? JSON.stringify(props.langId) : 0,
                                        // sourceLanguageMapping: (xlsxFilesLength > 0 && Object.keys(sourceLanguageMappingR).length!=0)? sourceLanguageMappingR : null,
                                        // referenceMapping: (xlsxFilesLength > 0 && Object.keys(referenceMapping).length!=0)? referenceMapping : null,
                                        // targetLanguageMapping: (Object.keys(langCode).length!=0 && xlsxFilesLength > 0) ? [
                                        //     { tlLangCode: langCode, columnKey: bilingual }
                                        // ] : null,
                                        // firstRecordAsHeader: xlsxFilesLength > 0 ? checked: null

                                    }
                                });

                            }
                        }
                    }
                    // files.filter((f: any) => {
                    //     if (f.meta.name === fileResponse.name) {
                    //         f.remove();
                    //     }
                    //     return f;
                    // });
                }
            } else if (file.meta.status === 'exception_upload' && file.xhr.statusText === "OK") {
                const fileResponse: any = { message: file.meta.name + ' response error' };
                fileResponse.id = file.meta.id;
                const found = uploadedFilesData.find((f: any) => f.id === fileResponse.id);
                if (!found) {
                    if (modalCloseValue && uploadedFileLength > 0) {
                        setUploadedFilesData([...uploadedFilesData, fileResponse]);
                    }
                }
            }
        });
        if (count > 0) {
            setFileTypeError(true)
        } else {
            setFileTypeError(false)
        }
        //it will check the filter file with extension xlsx and check the length
        const xlsxFilesLength = previews
            .reverse()
            .some((element: any) => {
                const fileExtension = element.props.fileWithMeta.meta.name.split('.').pop();
                return ['xlsx', 'pptx', 'docx'].includes(fileExtension);
            });

        return (
            <MBox>
                {(!modalCloseValue || restartUploading) ? 
                    <MBox {...dropzoneProps} sx={{ border: '1px dashed lightgrey', justifyContent: 'center' }}>
                                {input}
                            {/* <MBox fontSize={18} fontFamily={'Poppins'}>
                                <FormattedMessage id="wait.upload.files" />
                            </MBox> */}
                    </MBox>
                    : ''
                }
               
                {typeof props.fileType === "undefined" && xlsxFilesLength > 0 && (
                    <MBox my={2} className={classes.infoText} sx={{
                        maxWidth: '100% !important',
                        textAlign: 'left !important'
                    }}>
                        <FormattedMessage id='bilingual.file.configuration' />
                    </MBox>

                )}

                <MBox display="flex" justifyContent="center" alignItems="center">
                    {(previews.length > 0) ?
                        <MBox width="100%" height={274}>
                            <MBox className={classes.fileWrapScroll}>
                                {previews.reverse().map((element: any, index: number) => (
                                    <React.Fragment key={index}>
                                        <MBox
                                            key={"previews-" + index}
                                            className={classes.fileProgressStyle}
                                            mb={index === (previews.length - 1) ? 0 : 1}
                                        >
                                            {/* {((Math.round(element.props.fileWithMeta.meta.percent) < 100) &&
                                (Math.round(element.props.fileWithMeta.meta.percent) !== 0)) &&
                                    <MBox className={classes.fileProgressOverlay} sx={{transform:`translateX(calc(${Math.round(element.props.fileWithMeta.meta.percent) - 100}% + 12px))`}}></MBox>
                                } */}
                                            <MGrid container key={index} justifyContent="space-between" alignItems="center">
                                                <MGrid item xs={8} lg={8} xl={8} sx={{ textAlign: 'left', paddingRight: 18 }}>
                                                    <MBox className={`${classes.fileNameWrap} ${'gap-15'}`} display='flex' alignItems='center'>
                                                        <MTypography variant="span" className="fileName" sx={{ display: 'flex', alignItems: 'center' }}>
                                                            {/* extracted file type from file name */}
                                                            {element.props.fileWithMeta.meta.name.split('.').slice(0, -1)}.
                                                            {/* file type */}
                                                            {element.props.fileWithMeta.meta.name.split('.').pop()}

                                                        </MTypography>
                                                        {(props.tagParams && (getFileTag(element.props.fileWithMeta.meta) !== '')) && <MChip size="small" color="default" label={getFileTag(element.props.fileWithMeta.meta)} variant="outlined" />}

                                                        {bilingual.find((its: any) => its.fileMetaId == element.props.fileWithMeta.meta.id) ? <MBox sx={{
                                                            backgroundColor: '#e5f1f6', padding: "5px 15px", fontSize: '13px', borderRadius: '5px'
                                                        }}><FormattedMessage id="bilingual" /></MBox> : ''}
                                                    </MBox>
                                                    <MBox className="fileData">
                                                        <span>
                                                            <FormattedTimeParts
                                                                value={element.props.fileWithMeta.meta.uploadedDate}
                                                                year="numeric"
                                                                month="long"
                                                                day="2-digit"
                                                            >
                                                                {(parts: any) => (

                                                                    <>
                                                                        {typeof parts[0] != "undefined" && parts[0].value}
                                                                        {typeof parts[1] != "undefined" && parts[1].value}
                                                                        {typeof parts[2] != "undefined" && parts[2].value}
                                                                        {typeof parts[3] != "undefined" && parts[3].value}
                                                                        {typeof parts[4] != "undefined" && parts[4].value}
                                                                        &nbsp;<FormattedMessage id='at' />&nbsp;
                                                                        {typeof parts[6] != "undefined" && parts[6].value}
                                                                        {typeof parts[7] != "undefined" && parts[7].value}
                                                                        {typeof parts[8] != "undefined" && parts[8].value}
                                                                        {typeof parts[9] != "undefined" && parts[9].value}
                                                                        {typeof parts[10] != "undefined" && parts[10].value}
                                                                    </>
                                                                )}
                                                            </FormattedTimeParts>
                                                        </span>
                                                        <span>
                                                            <b>
                                                                <span style={{ fontSize: 22, margin: '0 7px', lineHeight: 0 }}>
                                                                    .
                                                                </span>
                                                                {formatBytes(element.props.fileWithMeta.meta.size, 1)}
                                                            </b>
                                                        </span>
                                                    </MBox>
                                                </MGrid>
                                                <MGrid item xs={4} lg={4} xl={4}>
                                                    <MBox display='flex' justifyContent="flex-end">
                                                        {(Math.round(element.props.fileWithMeta.meta.percent) === 100) && (element.props.fileWithMeta.meta.status === 'done' && ((element.props.fileWithMeta.xhr && element.props.fileWithMeta.xhr.statusText === "OK") && element.props.fileWithMeta.xhr.status === 200)) &&
                                                            <span style={{ color: '#64dba1', fontSize: 12 }}>
                                                                <FormattedMessage id="completed" />
                                                                <CheckCircleOutlineIcon sx={{ color: '#64dba1', height: 20, width: 20, verticalAlign: 'middle' }} />
                                                            </span>
                                                        }

                                                        {((Math.round(element.props.fileWithMeta.meta.percent) <= 100) &&
                                                            (Math.round(element.props.fileWithMeta.meta.percent) !== 0) &&
                                                            (element.props.fileWithMeta.meta.status !== 'done') && element.props.fileWithMeta.meta.status !== "error") &&
                                                            <>
                                                                {/* <span style={{
                                                    color: '#6b778c',
                                                    fontSize: 12,
                                                    marginRight: 10
                                                }}>{Math.round(element.props.fileWithMeta.meta.percent)}%</span>
                                                <LinearProgress variant="determinate"
                                                                className={'progressStyle'}
                                                                value={Math.round(element.props.fileWithMeta.meta.percent)}
                                                                color="secondary"
                                                /> */}
                                                                <LinearProgress className={classes.fileProgressBar} color="secondary" variant="determinate" value={Math.round(element.props.fileWithMeta.meta.percent)} />


                                                            </>
                                                        }

                                                    </MBox>
                                                    <MBox ml={2} display="flex" justifyContent="flex-end" alignItems="center" >
                                                        <MBox minWidth={24}>
                                                            {startUploading && (element.props.meta.status !== 'done') &&
                                                                <MBox mr={1}>
                                                                    {(element.props.canCancel && (element.props.meta.status === 'uploading')) &&
                                                                        <MIconButton className={classes.playPauseButton} onClick={() => element.props.fileWithMeta.cancel()}>
                                                                            <PauseIcon />
                                                                        </MIconButton>
                                                                    }
                                                                    {(element.props.canRestart && (element.props.meta.status === 'aborted')) &&
                                                                        <MIconButton className={classes.playPauseButton} onClick={() => element.props.fileWithMeta.restart()}>
                                                                            <PlayArrowIcon />
                                                                        </MIconButton>
                                                                    }
                                                                </MBox>
                                                            }
                                                        </MBox>

                                                        <>
                                                            {element.props.fileWithMeta.meta.size > maxSizeBytes && <>
                                                                <span style={{ color: '#FF0000', fontSize: 12, marginRight: 10 }} >
                                                                    <FormattedMessage id="file.size.not.supported" /> {props.size ? props.size : 500}&nbsp;mb
                                                                </span>
                                                            </>}
                                                        </>

                                                        {/* props:{currentFileName} */}

                                                        {element.props.fileWithMeta.meta.status === "error" &&
                                                            <>
                                                                <span style={{ color: '#FF0000', fontSize: 12, marginRight: 10 }} >
                                                                    <FormattedMessage id="file.type.not.supported" />
                                                                </span>
                                                                <MIconButton className={classes.fileDeleteButton} onClick={() => {
                                                                    element.props.fileWithMeta.remove();
                                                                    setModalCloseValue(false);
                                                                    setUploadedFilesLength(uploadedFileLength - 1);
                                                                }}
                                                                >
                                                                    <CloseRoundedIcon />
                                                                </MIconButton>
                                                            </>
                                                        }

                                                        {!((Math.round(element.props.fileWithMeta.meta.percent) === 100) && (element.props.fileWithMeta.meta.status === 'done' && ((element.props.fileWithMeta.xhr && element.props.fileWithMeta.xhr.statusText === "OK") && element.props.fileWithMeta.xhr.status === 200))) && typeof props.fileType === "undefined" &&
                                                            allowedTypes.includes(element.props.fileWithMeta.meta.name.split('.').pop())
                                                            ?


                                                            <MIconButton
                                                                onClick={() => {
                                                                    element.props.fileWithMeta.meta.name.split('.').pop() === "xlsx" ?
                                                                        handleClick(element.props.fileWithMeta.meta.id, 'openSettingDrawer')
                                                                        :
                                                                        handleClick(element.props.fileWithMeta.meta.id, 'importContentAndComments')

                                                                }
                                                                }
                                                                sx={{
                                                                    padding: '5px !important', marginRight: '5px'
                                                                }}
                                                            >
                                                                {/*{JSON.stringify(sourceLanguageMapping)}*/}
                                                                {/*{element.props.fileWithMeta.meta.id}*/}
                                                                <SettingsIcon sx={{ fill: '#A0A5B9' }} />
                                                            </MIconButton>
                                                            : null}

                                                        {((element.props.canRemove) && (Math.round(element.props.fileWithMeta.meta.percent) != 100) && element.props.fileWithMeta.meta.status !== "error") &&
                                                            <MIconButton sx={{ padding: '5px !important' }} className={classes.fileDeleteButton} onClick={() => {
                                                                element.props.fileWithMeta.remove();
                                                                setModalCloseValue(false);
                                                                setUploadedFilesLength(uploadedFileLength - 1);
                                                            }}
                                                            >
                                                                <CloseRoundedIcon />
                                                            </MIconButton>
                                                        }
                                                    </MBox>
                                                </MGrid>

                                            </MGrid>
                                        </MBox>
                                        <MDrawer
                                            open={bilingualLanguageModal}
                                            hideBackdrop={true}
                                            variant="temporary"
                                            anchor='right'
                                            className={`${classes.fileUploadDrawer} ${(bilingualLanguageModal) ? 'fileUploadDrawer' : ''}`}
                                        >
                                            <AppBar className='drawerAppBar'>
                                                <MToolbar>
                                                    <MBox display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                                                        <MBox display='flex' alignItems='center'>
                                                            <MBox display="inline-block" mr={1}>
                                                                <MIconButton onClick={() => { setBilingualLanguageModal(false); props.setIsFullWidth(false) }}>
                                                                    <ArrowBackIcon />
                                                                    {/*{bilingualLanguageModal?'t':'f'}*/}
                                                                </MIconButton>
                                                            </MBox>
                                                            <MBox display="flex" justifyContent="flex-end">
                                                                <MBox fontSize={18} display="flex" alignItems='center' fontFamily="Poppins">
                                                                    {element?.props?.fileWithMeta?.meta?.name && (
                                                                        <FormattedMessage id={element.props.fileWithMeta.meta.name} />
                                                                    )}

                                                                    &nbsp;
                                                                    <ArrowRightAltIcon fill='#6B778C' />
                                                                    &nbsp;

                                                                    <MBox sx={{
                                                                        color: '#6B778C'
                                                                    }}>
                                                                        {props.project && props.project.sl && props.project.sl.name} ({props?.project?.sl?.code})
                                                                    </MBox>
                                                                </MBox>

                                                            </MBox>
                                                        </MBox>

                                                    </MBox>
                                                </MToolbar>
                                            </AppBar>
                                            <MBox pb={5}>
                                                <MGrid container>
                                                    <MGrid item xs={12}>
                                                        <MBox color="palette.secondary">
                                                            <MGrid justifyContent="space-between" container item xs={12} sx={{ marginTop: '30px', padding: '0px 36px' }}>
                                                                <MBox width="100%">
                                                                    {tabsData &&
                                                                        <TabsHorizontal data={tabsData} tabClick={setTabClick} />
                                                                    }
                                                                </MBox>
                                                            </MGrid>
                                                        </MBox>
                                                    </MGrid>
                                                </MGrid>
                                            </MBox>
                                        </MDrawer>
                                    </React.Fragment>
                                ))}
                            </MBox>
                        </MBox>
                        :
                        <div>
                            {!(props.selectedFolder) && props.fileDrive &&
                                <MBox className={classes.selectFolderLabel} justifyContent="center">
                                    <FormattedMessage id="please.select.folder" />
                                </MBox>
                            }
                            <MBox display="flex" alignItems="center">
                                <EmptyContent />
                            </MBox>
                        </div>
                    }
                </MBox>
                {/* {files.length > 0 && submitButton} */}
            </MBox>
        )
    }

    const Input = () => {

        return (
            <>
                <MBox>
                    <MGrid container direction="row" justifyContent="center" alignItems="center">
                        <MBox mr={1}>
                            <img src="/assets/images/file-uploader-icon.png" alt="logo" style={{ width: '90px' }} />
                        </MBox>

                        <MBox>
                            <MTypography className={classes.DropZoneInput}>
                                <FormattedMessage id="add.files" />
                            </MTypography>

                            <MTypography className={classes.DropZoneDetailInput}>
                                <FormattedMessage id="drop.files.click" />
                                <MLink color="secondary" sx={{ cursor: "pointer" }}>
                                    <FormattedMessage id="browse" />
                                </MLink>
                                <FormattedMessage id="through.computer" />
                            </MTypography>
                        </MBox>
                    </MGrid>
                    <MBox>
                        <FileTypes fileType={props.fileType} size={props.size} />

                    </MBox>
                </MBox>
            </>
        )
    }
    function getCookie(name:any) {
        let cookies = document.cookie.split("; ");
        for (let cookie of cookies) {
            let [key, value] = cookie.split("=");
            if (key === name) {
                return decodeURIComponent(value);
            }
        }
        return null; // Return null if not found
    }

    const formatBytes = (bytes: number, decimals: number) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    const afterFileUpload = async (file: any, fileMetaData: any) => {
        setUploadedFileIds([...uploadedFileIds, file.id])

        const body = new FormData();
        body.append("fileName", file.name);
        body.append("filePath", file.filePath);
        body.append("size", file.size);
        if (appUserRole == 'ROLE_CLIENT' && currentUser.isPrivate && organizationId) {
            body.append("vendorOrgId", organizationId);
        }
        if (props.fileType) {
            body.append("type", props.fileType);
        }

        if (typeof fileMetaData != "undefined" && typeof fileMetaData.sourceMapping != "undefined" && fileMetaData.sourceMapping && typeof fileMetaData.sourceMapping.label != "undefined") {
            body.append('sourceMapping', fileMetaData.sourceMapping?.label);
            body.append('targetLanguageMappingLangCodeR', fileMetaData.targetLanguageMappingLangCodeR.label);
            body.append('targetLanguageMappingR', fileMetaData.targetLanguageMappingR.label);
            body.append('referenceMapping', fileMetaData.referenceMapping.label);
            body.append('firstRowHeading', fileMetaData.firstRowHeading.label);
        }


        if (fileMetaData && typeof fileMetaData.importNotesAndCommentFileMetaIdData != "undefined" && fileMetaData.importNotesAndCommentFileMetaIdData.importNotes == 1) {

            body.append('importNotesAndCommentFileMetaIdData', fileMetaData.importNotesAndCommentFileMetaIdData.importNotes);
        }


        // let fileMetaData = {sourceMapping: sourceLanguageMappingR, targetLanguageMappingLangCodeR: targetLanguageMappingLangCodeR, targetLanguageMappingR: targetLanguageMappingR,referenceMapping: referenceMappingR};



        const headers = {
            'accept': 'application/json',
            'authorization': 'Bearer ' + getCookie('access_token')
        }

        let url = process.env.REACT_APP_API_END_POINT + '/project-api/upload-project-files/' + props.projectId;
        if (props.fileType == 'organization_files') {
            url = process.env.REACT_APP_API_END_POINT + '/organization-api/upload-organization-files/' + selectedOrgId;
        }
        // let response:any = await axios.post(process.env.REACT_APP_API_END_POINT + '/api/upload-project-files/' + props.projectId, body, {headers: headers});
        const response: any = await axios.post(url, body, { headers: headers }).then(
            (res) => {
                if (props.tagParams && props.tagParams.selectedFolder && (props.tagParams.selectedFolder !== -1)) {
                    setFileTags([...fileTags, { id: file.id, tagId: props.tagParams.selectedFolder }]);
                    props.tagParams.createFileTag({
                        variables: {
                            orgId: props.tagParams.orgId,
                            projectId: props.projectId,
                            fileIds: res.data[0][0].fileId.toString(),
                            tagId: props.tagParams.selectedFolder.toString(),
                            vendorOrgId: (appUserRole == "ROLE_CLIENT" && currentUser.isPrivate) ? organizationId : 0
                        }

                    })
                }
                return res;
            }
        );
        return response && response.data[0][0];
    };

    const handleClose = () => {
        // if (uploadedFileLength > 0) {
        // setLoadingContent(true);
        // }
        setUploadedFilesLength(0);
        setAllFilesId([]);
        setUploadedIdArray([]);
        setUploadedFilesData([]);
        // setSelectedFolder(-1);
        setRestartUploading(false);
        setModalCloseValue(false)
        if (props.setImportNotesAndCommentFileMetaIdData) {
            props.setImportNotesAndCommentFileMetaIdData([]);
        }
        // setImportNotes(0)
        // getFiles.current = setInterval(getProjectFiles, 10000);
    };

    useEffect(() => {
        if (props && props.importNotesAndComment && props.importNotesAndComment.type == 'openSettingDrawer') {
            setBilingualLanguageModal(true);
            setBilingualLanguageFileMetaId(props.importNotesAndComment.fileId);

            if (typeof props.setIsFullWidth == "function") {
                props.setIsFullWidth(true);
            }
        } else if (props && props.importNotesAndComment && props.importNotesAndComment.type == 'importContentAndComments') {
            props.setImportNotesAndCommentPopUp(true);
            props.setImportNotesAndCommentFileMetaId(props.importNotesAndComment.fileId);
            // props.setNoteChecked(props.importNotesAndComment.fileId);
        }

    }, [props.importNotesAndComment]);

    const handleClick = (fileMetaId: any, type: any) => {
        if (typeof type !== "undefined" && type == 'openSettingDrawer') {
            setBilingualLanguageModal(true);
            setBilingualLanguageFileMetaId(fileMetaId);
            props.setIsFullWidth(true);
        } else {
            props.setImportNotesAndCommentPopUp(true);
            props.setImportNotesAndCommentFileMetaId(fileMetaId);
            const isIdFound = props.importNotesAndCommentFileMetaIdData &&
                props.importNotesAndCommentFileMetaIdData.some(
                    (entry: any) => entry.importNotesAndCommentFileMetaId === fileMetaId
                );

            // Allow checking/unchecking based on user input only if ID is found
            if (isIdFound) {
                props.setNoteChecked(true);  // Use the user's input on first click
            } else {
                props.setNoteChecked(false);  // Uncheck if the ID is not found
            }
        }
    }

    return {
        uploadModalOnChange: uploadModalOnChange,
        getUploadParams: getUploadParams,
        Layout: Layout,
        Input: Input,
        uploadFilesMeta: uploadFilesMeta,
        setUploadFilesMeta: setUploadFilesMeta,
        setUploadedFilesLength: setUploadedFilesLength,
        allFilesId: allFilesId,
        setAllFilesId: setAllFilesId,
        setUploadedFilesData: setUploadedFilesData,
        restartUploading: restartUploading,
        setRestartUploading: setRestartUploading,
        modalCloseValue: modalCloseValue,
        setModalCloseValue: setModalCloseValue,
        uploadedFileLength: uploadedFileLength,
        setStartUploading: setStartUploading,
        startUploading: startUploading,
        fileTypeError: fileTypeError,
        handleClose: handleClose

    }
}
