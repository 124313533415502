/* eslint-disable */
import React from 'react'
import {
    MMasterCard,
    MVisaCard,
    MAmexCard,
    MJcbCard,
    MDiscoverCard,
    MDinersCard,
    MUnionpayCard,
    MInvoiceReview,
    MInvoiceSent,
    MInvoiceApproved,
    MInvoicePaidBlue,
    MInvoiceReceived,
} from '../../app/material-ui/icons/index';

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { MBox } from '../material-ui';
import { useIntl } from "react-intl";
import { FormattedMessage } from 'react-intl';


const avatarPlaceholder = (avatarFullName?: string) => {
    if (avatarFullName) {
        avatarFullName = avatarFullName.trim();
    }
    let avatar = '';
    if (avatarFullName && (avatarFullName.split(' ').length > 1 || avatarFullName.split('-').length > 1 || avatarFullName.split('_').length > 1 || avatarFullName.split('.').length > 1)) {

        let splitArr = avatarFullName.split(' ');
        if (avatarFullName.split('-').length > 1) {
            splitArr = avatarFullName.split('-');
        }
        else if (avatarFullName.split('_').length > 1) {
            splitArr = avatarFullName.split('_');
        }
        else if (avatarFullName.split('.').length > 1) {
            splitArr = avatarFullName.split('.');
        }

        avatar = splitArr[0][0] + splitArr[1][0];
    } else if (avatarFullName && avatarFullName.split(' ').length == 1) {
        if (avatarFullName.split(' ')[0].length > 1) {
            avatar = avatarFullName.split(' ')[0][0] + avatarFullName.split(' ')[0][1];
        } else {
            avatar = avatarFullName.split(' ')[0][0];
        }

    } else if (avatarFullName && avatarFullName.split('-').length == 1) {
        if (avatarFullName.split('-')[0].length > 1) {
            avatar = avatarFullName.split('-')[0][0] + avatarFullName.split('-')[0][1];
        } else {
            avatar = avatarFullName.split('-')[0][0];
        }

    } else if (avatarFullName && avatarFullName.split('_').length == 1) {
        avatar = avatarFullName.split('_')[0][0] + avatarFullName.split('_')[0][1];
    } else if (avatarFullName && avatarFullName.split('.').length == 1) {
        avatar = avatarFullName.split('.')[0][0] + avatarFullName.split('.')[0][1];
    }

    return avatar;
};
export default avatarPlaceholder;

export const cardIcon = (type: string) => {
    if (type === 'visa') {
        return <MVisaCard />;
    } else if (type === 'amex') {
        return <MAmexCard />;
    } else if (type === 'mastercard') {
        return <MMasterCard />;
    } else if (type === 'jcb') {
        return <MJcbCard />;
    } else if (type === 'discover') {
        return <MDiscoverCard />;
    } else if (type === 'unionpay') {
        return <MUnionpayCard />;
    } else if (type === 'diners') {
        return <MDinersCard />;
    }
};

export const transactionsStatus = (status: number) => {
    if (status === 0 || status === 4) {
        //inprogress
        return (
            <MBox display='flex' alignItems='center'>
                <AutorenewOutlinedIcon sx={{ fill: '#E69B0E' }} />
                &nbsp;
                <MBox color='#E69B0E' fontSize={13}>
                    <FormattedMessage id="inprogress" />
                </MBox>
            </MBox>
        );

    } else if (status === 1 || status === 5) {
        //Succeed
        return (
            <MBox display='flex' alignItems='center'>
                <DoneAllIcon sx={{ fill: '#16C68E' }} />
                &nbsp;
                <MBox color='#16C68E' fontSize={13}>
                    <FormattedMessage id="successed" />
                </MBox>
            </MBox>
        );
    } else if (status === 2 || status === 6) {
        //Failed
        return (
            <MBox display='flex' alignItems='center'>
                <CloseOutlinedIcon sx={{ fill: '#FF0102' }} />
                &nbsp;
                <MBox color='#FF0102' fontSize={13}>
                    <FormattedMessage id="failed" />
                </MBox>
            </MBox>

        );

    }
};
//this is status for  invoices page
export const invoicesStatus = (status: number, source: string) => {
    const intl = useIntl();

    const arr = [<> {source == 'tasks' ? <MInvoiceReceived /> : <MInvoiceSent />}</>, <MInvoiceReview />, <MInvoiceReview />];
    const statusArray = [
        [<MBox display='flex' alignItems='center'>{arr[0]} &nbsp; <MBox color={source == 'tasks' ? '#16C68E' : '#E69B0E'} fontSize={13} fontFamily='Poppins'>

            {source == 'tasks' ? intl.formatMessage({ id: "invoice.received" }) : intl.formatMessage({ id: "invoice.sent" })}

        </MBox> </MBox>],

        [<MBox display='flex' alignItems='center'>{arr[1]} &nbsp; <MBox color='#6665DD' fontSize={13} fontFamily='Poppins'>
            <FormattedMessage id="invoice.review" />
        </MBox> </MBox>],

        [<MBox display='flex' alignItems='center'><MInvoiceApproved /> &nbsp; <MBox color='#16C68E' fontSize={13} fontFamily='Poppins'>
            <FormattedMessage id="invoice.paid" />
        </MBox> </MBox>],

        [<MBox display='flex' alignItems='center'><MInvoicePaidBlue /> &nbsp; <MBox color='#5785eb' fontSize={13} fontFamily='Poppins'>
            <FormattedMessage id="invoice.approved" />
        </MBox> </MBox>],


        [<MBox display='flex' alignItems='center'><MInvoicePaidBlue /> &nbsp; <MBox color='#6665DD' fontSize={13} fontFamily='Poppins'>
            <FormattedMessage id="invoice.review" />
        </MBox> </MBox>]

    ];


    if (status === 1) {

        return (
            <MBox display='flex' alignItems='center'>
                {statusArray[0]}
            </MBox>
        );

    } else if (status === 2) {

        return (
            <MBox display='flex' alignItems='center'>
                {statusArray[2]}
            </MBox>
        );
    } else if (status === 3) {

        return (
            <MBox display='flex' alignItems='center'>
                {statusArray[3]}
            </MBox>

        );

    } else if (status === 5) {

        return (
            <MBox display='flex' alignItems='center'>
                {statusArray[4]}
            </MBox>

        );

    }
};



export const consoleLog = (value?: any) => {

    if (process.env.NODE_ENV === 'production') {
        console.log(value);
    }
};
