/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
    MAppBar, MBox,
    MButton, MCircularProgress, MDrawer, MForm, MFormik, MFormLabel, MGrid, MIconButton, MLink,
    // MPagination, MSkeleton, 
    MTextField, MToolbar
} from 'src/app/material-ui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
// import MatchRatesSkeleton from './MatchRatesSkeleton';
import useStyles from './MatchRatesStyles';
import CheckPermissions from 'src/app/hooks/permissions/CheckPermissions';


export default function AddMatchRates({ openMatchRateDrawer, setOpenMatchRateDrawer, createMatchRate, createLoading }: any) {

    const classes = useStyles({});
    const intl = useIntl();
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const organizations = useSelector((store: any) => store.orgs.organizations);
    const [mathchRateCreate, setMatchRateCreate] = useState(false);

    useEffect(() => {
        setMatchRateCreate(CheckPermissions('ROLE_MATCH_RATE_CREATE', selectedOrgId, organizations));
    }, [selectedOrgId, organizations]);


    return (
        <div className="matchRateScreen">
            <MBox>
                <MGrid container>

                    <MDrawer open={openMatchRateDrawer} variant="temporary" anchor='right' className={classes.fileUploadDrawer}>
                        <MAppBar className='drawerAppBar'>
                            <MToolbar>
                                <MBox display="inline-block">
                                    <MIconButton onClick={() => setOpenMatchRateDrawer(false)}>
                                        <ArrowBackIcon />
                                    </MIconButton>
                                </MBox>
                                <MBox width="100%" fontSize={18} fontFamily="Poppins">
                                    <FormattedMessage id="add.match.rates" />
                                </MBox>
                            </MToolbar>
                        </MAppBar>
                        <MBox px={3} py={4} mb={2} pt={2}>
                            <MBox maxWidth={700} className={classes.formBoxNew}>
                                <FormattedMessage id="enter.valid.percentage">
                                    {enterValidPercentage =>
                                        <FormattedMessage id="required">
                                            {required =>
                                                <MFormik validateOnChange enableReinitialize="true"
                                                    initialValues={{
                                                        name: '',
                                                        new: '',
                                                        Fifty_seventyFour: '',
                                                        SeventyFive_ninetyEight: '',
                                                        EightyFive_NinetyFour: '',
                                                        NinetyFive_NinetyNine: '',
                                                        Hundred: '',
                                                        repetition: '',
                                                        crossFileRepetitions: '',
                                                    }}

                                                    validationSchema={Yup.object({
                                                        name: Yup.string().required(String(required)),
                                                        new: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                        Fifty_seventyFour: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                        SeventyFive_ninetyEight: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                        EightyFive_NinetyFour: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                        NinetyFive_NinetyNine: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                        Hundred: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                        repetition: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                        crossFileRepetitions: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                    })}

                                                    onSubmit={(values: any, { resetForm }: any) => {
                                                        createMatchRate({
                                                            variables: {
                                                                orgId: selectedOrgId,
                                                                name: values.name,
                                                                new: values.new,
                                                                fifty_seventyFour: values.Fifty_seventyFour,
                                                                seventyFive_ninetyEight: values.SeventyFive_ninetyEight,
                                                                eightyFive_NinetyFour: values.EightyFive_NinetyFour,
                                                                ninetyFive_NinetyNine: values.NinetyFive_NinetyNine,
                                                                hundred: values.Hundred,
                                                                repetition: values.repetition,
                                                                crossFileRepetitions: values.crossFileRepetitions,
                                                            }
                                                        });
                                                        setOpenMatchRateDrawer(false);
                                                        resetForm();
                                                    }}
                                                >
                                                    {({ resetForm, submitForm }: any) => (

                                                        <MForm>
                                                            <MBox mb={5}>
                                                                <MBox mb={2} fontFamily="Poppins">
                                                                    <MFormLabel className={classes.formLabel} required component="legend">
                                                                        <FormattedMessage id="name" />
                                                                    </MFormLabel>
                                                                </MBox>
                                                                <MBox>
                                                                    <FormattedMessage id="name">
                                                                        {name =>
                                                                            <MTextField
                                                                                fullWidth
                                                                                name="name"
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                size="small"
                                                                                disabled={false}
                                                                                placeholder={name}

                                                                            />
                                                                        }
                                                                    </FormattedMessage>
                                                                </MBox>
                                                            </MBox>

                                                            <MBox>
                                                                <MBox mb={2} fontFamily="Poppins">
                                                                    <FormattedMessage id="add.match.rates" />
                                                                </MBox>
                                                                <MBox className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                                                    <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                        <MBox width="82px" mr={1.2} textAlign="right">
                                                                            <MFormLabel className={classes.formLabel} required component="legend">
                                                                                <FormattedMessage id="new" />
                                                                            </MFormLabel>
                                                                        </MBox>
                                                                        <MBox width="160px" position="relative">
                                                                            <MTextField fullWidth type="number" name="new" variant="outlined" color="secondary" size="small" disabled={false} placeholder="100%"
                                                                                inputProps={{
                                                                                    max: "100",
                                                                                    min: "0",
                                                                                    step: "any"
                                                                                }}
                                                                            />
                                                                        </MBox>
                                                                    </MBox>
                                                                    <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                        <MBox width="82px" mr={1.2} textAlign="right">
                                                                            <MFormLabel className={classes.formLabel} required component="legend">
                                                                                <FormattedMessage id="fifty.to.seventyfourpercent" />
                                                                            </MFormLabel>
                                                                        </MBox>
                                                                        <MBox width="160px">
                                                                            <MTextField fullWidth type="number" name="Fifty_seventyFour" variant="outlined" color="secondary" size="small" disabled={false} placeholder="60%"
                                                                                inputProps={{
                                                                                    max: "100",
                                                                                    min: "0",
                                                                                    step: "any"
                                                                                }}
                                                                            />
                                                                        </MBox>
                                                                    </MBox>
                                                                </MBox>
                                                                <MBox className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                                                    <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                        <MBox width="82px" mr={1.2} textAlign="right">
                                                                            <MFormLabel className={classes.formLabel} required component="legend">
                                                                                <FormattedMessage id="seventyfive.to.eightyfour" />
                                                                            </MFormLabel>
                                                                        </MBox>
                                                                        <MBox width="160px">
                                                                            <MTextField fullWidth type="number" name="SeventyFive_ninetyEight" variant="outlined" color="secondary" size="small" disabled={false}
                                                                                placeholder="80%"
                                                                                inputProps={{
                                                                                    max: "100",
                                                                                    min: "0",
                                                                                    step: "any"
                                                                                }}
                                                                            />
                                                                        </MBox>
                                                                    </MBox>
                                                                    <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                        <MBox width="82px" mr={1.2} textAlign="right">
                                                                            <MFormLabel className={classes.formLabel} required component="legend">
                                                                                <FormattedMessage id="eightyfive.to.ninetyfour" />
                                                                            </MFormLabel>
                                                                        </MBox>
                                                                        <MBox width="160px">
                                                                            <MTextField fullWidth type="number" name="EightyFive_NinetyFour" variant="outlined" color="secondary" size="small" disabled={false}
                                                                                placeholder="90%"
                                                                                inputProps={{
                                                                                    max: "100",
                                                                                    min: "0",
                                                                                    step: "any"
                                                                                }}
                                                                            />
                                                                        </MBox>
                                                                    </MBox>
                                                                </MBox>
                                                                <MBox className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                                                    <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                        <MBox width="82px" mr={1.2} textAlign="right">
                                                                            <MFormLabel className={classes.formLabel} required component="legend">
                                                                                <FormattedMessage id="ninetyfive.to.ninetynine" />
                                                                            </MFormLabel>
                                                                        </MBox>
                                                                        <MBox width="160px">
                                                                            <MTextField fullWidth type="number" name="NinetyFive_NinetyNine" variant="outlined" color="secondary" size="small" disabled={false}
                                                                                placeholder="97%"
                                                                                inputProps={{
                                                                                    max: "100",
                                                                                    min: "0",
                                                                                    step: "any"
                                                                                }}
                                                                            />
                                                                        </MBox>
                                                                    </MBox>
                                                                    <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                        <MBox width="82px" mr={1.2} textAlign="right">
                                                                            <MFormLabel className={classes.formLabel} required component="legend">
                                                                                <FormattedMessage id="hundered.percent" />
                                                                            </MFormLabel>
                                                                        </MBox>
                                                                        <MBox width="160px">
                                                                            <MTextField
                                                                                fullWidth
                                                                                type="number"
                                                                                name="Hundred"
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                size="small"
                                                                                disabled={false}
                                                                                placeholder="100%"
                                                                                inputProps={{
                                                                                    max: "100",
                                                                                    min: "0",
                                                                                    step: "any"
                                                                                }}
                                                                            />
                                                                        </MBox>
                                                                    </MBox>
                                                                </MBox>
                                                                <MBox className="inputsRow" display="flex" justifyContent="space-between" alignItems="center">
                                                                    <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                        <MBox width="82px" mr={1.2} textAlign="right">
                                                                            <MFormLabel className={classes.formLabel} sx={{ whiteSpace: 'nowrap' }} required component="legend">
                                                                                <FormattedMessage id="repetition" />
                                                                            </MFormLabel>
                                                                        </MBox>
                                                                        <MBox width="160px">
                                                                            <MTextField
                                                                                fullWidth
                                                                                type="number"
                                                                                name="repetition"
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                size="small"
                                                                                disabled={false}
                                                                                placeholder="0%"
                                                                                inputProps={{
                                                                                    max: "100",
                                                                                    min: "0",
                                                                                    step: "any"
                                                                                }}
                                                                            />
                                                                        </MBox>
                                                                    </MBox>
                                                                    <MBox display="flex" alignItems="center">
                                                                        <MBox mr={1.2}>
                                                                            <MFormLabel className={classes.formLabel} required component="legend">
                                                                                <FormattedMessage id="cross.file.repetitions" />
                                                                            </MFormLabel>
                                                                        </MBox>
                                                                        <MBox width="160px">
                                                                            <MTextField
                                                                                fullWidth
                                                                                type="number"
                                                                                name="crossFileRepetitions"
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                size="small"
                                                                                disabled={false}
                                                                                placeholder="0%"
                                                                                inputProps={{
                                                                                    max: "100",
                                                                                    min: "0",
                                                                                    step: "any"
                                                                                }}
                                                                            />
                                                                        </MBox>
                                                                    </MBox>
                                                                </MBox>

                                                            </MBox>
                                                            <MBox display="flex" mt={3} mb={12}>
                                                                <MBox className={classes.footerBottom}>
                                                                    <MBox display="inline-block" mr={2} mt={1}>
                                                                        <MLink color="inherit" href="#" onClick={() => {
                                                                            setOpenMatchRateDrawer(false);
                                                                            resetForm();
                                                                        }}>
                                                                            <FormattedMessage id="cancel" />
                                                                        </MLink>
                                                                    </MBox>
                                                                    {mathchRateCreate &&
                                                                        <MButton className="btnSmall" color="primary" variant="contained" disabled={createLoading}
                                                                            onClick={() => {
                                                                                submitForm();
                                                                            }} type='submit'
                                                                        >
                                                                            {createLoading && <MCircularProgress color="inherit" size={24} />}
                                                                            <FormattedMessage id="save" />
                                                                        </MButton>}

                                                                </MBox>
                                                            </MBox>
                                                        </MForm>
                                                    )}
                                                </MFormik>
                                            }
                                        </FormattedMessage>
                                    }
                                </FormattedMessage>
                            </MBox>
                        </MBox>
                    </MDrawer>
                </MGrid>
            </MBox>
        </div>
    );
}
