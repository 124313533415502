import React, { useState, MouseEvent, useEffect } from 'react'
import Fade from '@mui/material/Fade';
import useStyles from './HeaderStyles';
import OrganizationDropdown from './organization-dropdown/OrganizationDropdown';
import { MGrid, MIconButton, MBox, MMenu, MButton, MAvatar, MBadge, MLink, MSkeleton, MMenuItem } from "src/app/material-ui";
import { NavLink, useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarType, showAlert, showComingSoonModal, setUserRole } from 'src/app/store';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpIcon from '@mui/icons-material/Help';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRoleRedirect } from 'src/app/hooks';
import { FormattedMessage, useIntl } from "react-intl";

function Header(props: any) {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const intl = useIntl();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [jobMenu, setJobMenu] = useState<null | HTMLElement>(null);
    const [notification, setNotification] = React.useState<null | HTMLElement>(null);
    const [organizations, setOrganization]: any = useState();

    const orgs = useSelector((store: any) => store.orgs.organizations);
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const isProjectAdmin = useSelector((store: any) => store.orgs.isProjectAdmin);
    const isVendorAdmin = useSelector((store: any) => store.orgs.isVendorAdmin);
    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const isGraphqlError = useSelector((store: any) => store.alert.showAlertMessage);
    const location = useLocation();
    const { handelRedirect } = useRoleRedirect(props);
    // const [roleType, setRoleType] = useState('');

    useEffect(() => {
        if (orgs && (orgs.length > 0)) {
            setOrganization(orgs)
        }
    }, [orgs])

    const switchRole = (role: string) => {
        setAnchorEl(null);
        if (role !== '') {
            dispatch(setUserRole(role));
            dispatch(setSidebarType(role));
            // localStorage.setItem('user_role',role);
            handelRedirect(role)
        }
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirectToPage = (url: string) => {
        props.history.push(url);
        handleClose();
    }

    const logout = () => {
        const token = localStorage.getItem('access_token');
        localStorage.clear();
        window.location.href = "" + process.env.REACT_APP_SSO_LOGOUT_URL + token;
        dispatch(showAlert({ message: intl.formatMessage({ id: "signing.out" }), messageType: 'error', showAlertMessage: true }))
        // localStorage.clear()
        // dispatch(resetState());
        // props.history.push('/')
    };
    const noPageModalOpen = (e: any) => {
        e?.preventDefault();
        dispatch(showComingSoonModal(true));
    }


    const handleNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
        setNotification(event.currentTarget)
    }
    const handleCloseNotification = () => {
        setNotification(null);
    };

    const openJobMenu = (event: MouseEvent<HTMLElement>) => {
        setJobMenu(event.currentTarget)
    }

    const closeJobMenu = () => {
        setJobMenu(null)
    }

    const jobMenuLink = (link: string) => {
        props.history.push(link);
        closeJobMenu();
    }

    return (
        // Menu and right icons
        <MGrid container alignItems="center">
            <MGrid key={1} item sm={10}>
                <MGrid container alignItems="center">
                    {(appUserRole === "ROLE_CLIENT")
                        ?
                        <MGrid item className={classes.logoBox}>
                            <NavLink to={(organizations && organizations.length > 0)
                                ?
                                "/projects"
                                :
                                "/create-organization"}>
                                <img src="/assets/images/marshub-logo.svg" alt="logo" width="140px" />
                            </NavLink>
                        </MGrid>
                        :
                        <MGrid item className={classes.logoBox}>
                            <NavLink to={((appUserRole === "ROLE_TRANSLATOR")
                                ?
                                "/linguist/profile"
                                :
                                "/vendor/open-jobs")}>
                                <img src="/assets/images/marshub-logo.svg" alt="logo" width="140px" />
                            </NavLink>
                        </MGrid>
                    }
                    {organizations && organizations.length > 0 &&
                        <MGrid item>
                            <OrganizationDropdown headerText="Organizations" footerText="See All" />
                        </MGrid>
                    }
                    {(!orgs && !isGraphqlError) &&
                        <MBox display="flex" alignItems="center" pl={3} mr={4}>
                            <MSkeleton variant="circular" width={36} height={36} />
                            <MBox ml={1}>
                                <MSkeleton height={21} width={90} />
                            </MBox>
                        </MBox>
                    }
                    {organizations && organizations.length > 0
                        &&
                        <MGrid item container alignItems="center">
                            {/*{(currentRole === "ROLE_CLIENT" || currentRole === "projectBar") ?*/}
                            {(appUserRole === "ROLE_CLIENT")
                                ?
                                <MBox key={101} className={classes.mainNav} component="ul" display="flex" p={0}>
                                    {(isAdmin || isProjectAdmin || isOwner)
                                        &&
                                        <>
                                            <li>
                                                <NavLink exact className={classes.menuLink} to="/projects">
                                                    <FormattedMessage id="projects" />
                                                </NavLink>
                                            </li>

                                        </>
                                    }

                                    {isAdmin || isOwner
                                        ?
                                        <li>
                                            <NavLink exact className={classes.menuLink} to="/manage-admins">
                                                <FormattedMessage id="people" />
                                            </NavLink>
                                        </li>
                                        :
                                        ""
                                    }

                                    {(isAdmin || isVendorAdmin || isOwner)
                                        &&
                                        <>
                                            <li>
                                                <NavLink exact className={classes.menuLink} onClick={() => localStorage.setItem('searchVendor', '')} to="/vendors">
                                                    <FormattedMessage id="vendor" />
                                                </NavLink>
                                            </li>
                                        </>
                                    }

                                    {(isAdmin || isOwner) &&
                                        <>
                                            <li>
                                                <NavLink exact className={classes.menuLink} to="/rate-request" >
                                                    <FormattedMessage id="rate.requests" />
                                                </NavLink>
                                            </li>
                                        </>
                                    }
                                </MBox>
                                :
                                (appUserRole === "ROLE_VENDOR")
                                &&
                                <MBox key={102} className={classes.mainNav} component="ul" display="flex" p={0}>
                                    <li>
                                        <MLink aria-haspopup="true" aria-controls="job-menu"
                                            className={`
                                                ${classes.menuLink} 
                                                ${(jobMenu ? 'activeSubMenu' : ((location.pathname === "/vendor/open-jobs") || (location.pathname === "/vendor/my-jobs")) ? 'active' : '')}
                                            `}
                                            href="#"
                                            onClick={openJobMenu}
                                        >
                                            <FormattedMessage id="jobs" />
                                            <ArrowDropDownIcon />
                                        </MLink>
                                        <MMenu
                                            className={classes.jobMenuWrap}
                                            id="job-menu"
                                            anchorEl={jobMenu}
                                            keepMounted
                                            open={Boolean(jobMenu)}
                                            onClose={closeJobMenu}
                                            TransitionComponent={Fade}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <MMenuItem
                                                className={`${location.pathname === "/vendor/open-jobs" ? classes.selectedJobMenu : ''}`}
                                                onClick={() => jobMenuLink('/vendor/open-jobs')}
                                            >
                                                <FormattedMessage id="open.jobs" />
                                            </MMenuItem>
                                            <MMenuItem
                                                className={`${location.pathname === "/vendor/my-jobs" ? classes.selectedJobMenu : ''}`}
                                                onClick={() => jobMenuLink('/vendor/my-jobs')}
                                            >
                                                <FormattedMessage id="assigned.jobs" />
                                            </MMenuItem>
                                        </MMenu>
                                    </li>

                                    <li>
                                        <NavLink exact className={classes.menuLink} to="/vendor/services">
                                            <FormattedMessage id="services" />
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink exact className={classes.menuLink} to="/vendor/rates">
                                            <FormattedMessage id="rates" />
                                        </NavLink>
                                    </li>
                                    <>
                                        <li>
                                            <NavLink exact className={classes.menuLink} onClick={() => localStorage.setItem('searchVendor', '')} to="/vendor/linguists">
                                                <FormattedMessage id="linguist" />
                                            </NavLink>
                                        </li>
                                    </>
                                    <li>
                                        <NavLink exact className={classes.menuLink} to="/vendor/match-rates">
                                            <FormattedMessage id="match.rate" />
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink exact className={classes.menuLink} to="#"
                                            onClick={(e: any) => noPageModalOpen(e)}>
                                            <FormattedMessage id="reports" />
                                        </NavLink>
                                    </li>
                                </MBox>
                            }

                            {appUserRole === "ROLE_TRANSLATOR" && <MBox key={103} className={classes.mainNav} component="ul" display="flex" p={0}>
                                <li>
                                    <NavLink exact className={classes.menuLink} to="/linguist/services">
                                        <FormattedMessage id="services" />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink exact className={classes.menuLink} to="/linguist/rates">
                                        <FormattedMessage id="rates" />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink exact className={classes.menuLink} to="#"
                                        onClick={(e: any) => noPageModalOpen(e)}>
                                        <FormattedMessage id="reports" />
                                    </NavLink>
                                </li>
                            </MBox>
                            }

                        </MGrid>
                    }
                    {(!orgs && !isGraphqlError) &&
                        Array(((appUserRole === "ROLE_CLIENT") ? 4 : 5)).fill(null).map((_, index: number) => <MBox key={"box" + index} ml={3}><MSkeleton width={78} height={20} /></MBox>)
                    }
                </MGrid>
            </MGrid>

            <MGrid key={2} item sm={2} container justifyContent="flex-end">
                <MBox display="flex" flexDirection="row" p={1} alignItems="center">
                    <MBox>
                        {organizations && (organizations.length < 1) && (window.location.href.split('/').pop() === 'dashboard') &&
                            <MButton variant="contained" color="secondary"
                                onClick={() => props.history.push("/create-organization")}>
                                <FormattedMessage id="create.organization" />
                            </MButton>
                        }
                    </MBox>

                    <MBox key={202}>
                        <MIconButton aria-label="Help icon" onClick={handleNotification}>
                            <HelpIcon className={classes.headerIcon}></HelpIcon>
                        </MIconButton>
                        <MMenu
                            id="simple-menu"
                            anchorEl={notification}
                            keepMounted
                            open={Boolean(notification)}
                            onClose={handleCloseNotification}
                        >
                            {/* <MMenuItem onClick={handleCloseNodification}>Profile</MMenuItem> */}
                            <MBox>d</MBox>

                        </MMenu>
                    </MBox>
                    <MBox key={203}>
                        <MIconButton aria-label="show 17 new notifications" aria-controls="notificationMenu"
                            aria-haspopup="true" onClick={() => dispatch(showComingSoonModal(true))}>
                            <MBadge variant="dot" color="secondary" className={classes.dotBagde}>
                                <NotificationsIcon className={classes.headerIcon} />
                            </MBadge>
                        </MIconButton>
                    </MBox>
                    <MBox pl={1} key={204}>
                        <MIconButton aria-controls="UserMenu" aria-haspopup="true" color="inherit"
                            onClick={handleClick} className={classes.userAvatarWrapper}>
                            {/* <AccountCircle /> */}
                            <MAvatar variant="circular" alt="Remy Sharp"
                                src={currentUser && currentUser.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture : "/assets/images/avatar.jpg"} />
                        </MIconButton>
                        <MMenu
                            id="UserMenu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                            className={classes.userDropdown}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MBox p={1} pb={2} mb={1} textAlign="center" className={classes.avatarBox}>
                                <MAvatar variant="circle" alt="Remy Sharp" src={currentUser && currentUser.profilePicture
                                    ?
                                    process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture
                                    :
                                    "/assets/images/avatar.jpg"}
                                />
                                <MBox>
                                    {currentUser && currentUser.firstName + ' ' + currentUser.lastName}
                                </MBox>
                            </MBox>
                            <MMenuItem className={classes.menuLink} onClick={() => redirectToPage((appUserRole === "ROLE_CLIENT")
                                ?
                                "/projects"
                                :
                                (appUserRole === "ROLE_TRANSLATOR")
                                    ?
                                    "/linguist/profile"
                                    :
                                    '/vendor/open-jobs')}
                            >
                                <FormattedMessage id="dashboard" />
                            </MMenuItem>
                            <MMenuItem className={classes.menuLink} onClick={() => redirectToPage("/my-account")}>
                                <FormattedMessage id="my.account" />
                            </MMenuItem>
                            {(isAdmin || isOwner) && (appUserRole === "ROLE_CLIENT") &&
                                <MMenuItem className={classes.menuLink} onClick={() => redirectToPage(('/transactions'))}>
                                    <FormattedMessage id="transactions" />
                                </MMenuItem>
                            }
                            <MMenuItem className={classes.menuLink} onClick={() => redirectToPage((appUserRole === "ROLE_VENDOR"
                                ?
                                '/vendor/settings'
                                :
                                (appUserRole === "ROLE_TRANSLATOR")
                                    ?
                                    '/linguist/profile'
                                    :
                                    '/settings'))}
                            >
                                <FormattedMessage id="settings" />
                            </MMenuItem>

                            {/*{(organizations && organizations.length > 0) &&*/}
                            {(isOwner || isOwner === "")
                                ?
                                <MBox key={401} mt={1} mb={.75} pb={1} className={classes.roleWrap}>
                                    <MBox key={501} mt={1.5} ml={1.25} mb={.5} fontSize={12} color="#6b778c">
                                        <FormattedMessage id="switch.role" />
                                    </MBox>

                                    <MMenuItem key={502} className={(appUserRole === "ROLE_CLIENT") ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_CLIENT')}>
                                        <FormattedMessage id="client" />
                                    </MMenuItem>
                                    <MMenuItem key={503} className={appUserRole === "ROLE_VENDOR" ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_VENDOR')}>
                                        <FormattedMessage id="vendor" />
                                    </MMenuItem>
                                    <MMenuItem key={504} className={appUserRole === "ROLE_TRANSLATOR" ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_TRANSLATOR')}>
                                        <FormattedMessage id="linguist.side" />
                                    </MMenuItem>

                                </MBox>
                                :
                                <MBox key={402} sx={{ marginTop: 10, marginBottom: 5, borderTop: "1px solid #d8d9db" }}></MBox>
                            }
                            <MMenuItem onClick={logout} className={`${classes.logoutLink}`}>
                                <span className={`${classes.menuLink} ${classes.logoutLink}`}>
                                    <FormattedMessage id="logout" />
                                </span>
                            </MMenuItem>
                        </MMenu>
                    </MBox>
                </MBox>
            </MGrid>
        </MGrid>
    );
}

export default withRouter(Header);