/* eslint-disable */
import React, { useState, useEffect } from 'react';
import useStyles from './TargetLanguageSelectStyles'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import { MKeyboardArrowDownRoundedIcon } from "src/app/material-ui/icons";
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

interface Language {
  id: number,
  name: string,
  code: string
}

const SOURCE_LANGUAGE_GQL = gql`
  {
    sourceLanguages {
      code
      id
      name
    }
  }
`;

export function TargetLanguageSelect(props: any) {
  const classes: any = useStyles({});
  const [targetLanguage, setTargetLanguage] = useState([])
  const [defaultLanguage, setDefaultLanguage]: any = useState([]);
  // const [setShow]:any = useState(false)
  const { data } = useQuery(SOURCE_LANGUAGE_GQL, {
    onCompleted: (data1: any) => {
      if (data1 && data1.sourceLanguages) {
        setTargetLanguage(data1.sourceLanguages)
      }
    }
  });

  const mountedRef = React.useRef(true);

  // Cleanup the mountedRef when the component unmounts
  useEffect(() => {
    // Set mountedRef to false when the component unmounts
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const { selectedTargetLang } = props;

  const filterTargetLanguage = (allTargetLanguage: Array<Language>) => {
    let newTargetLanguages: any = allTargetLanguage;
    if (props.filterLanguages && props.filterLanguages.length > 0) {
      props.filterLanguages.forEach((targetLanguageCode: any) => {
        newTargetLanguages = newTargetLanguages?.filter((element: any) => element.code !== targetLanguageCode);
      });
      setTargetLanguage(newTargetLanguages);
    } else {
      setTargetLanguage(newTargetLanguages);
    }
  }

  useEffect(() => {
    if ((targetLanguage && targetLanguage.length > 0)) {

      if (props.targetlanguages) {
        setTargetLanguage(props.targetlanguages);
      }
    }

    const langArr: any = [];
    if (props.defaultLanguage && (props.defaultLanguage.length > 0)) {
      props.defaultLanguage.forEach((lang: any) => {
        const index = targetLanguage.findIndex((x: any) => x.id === lang.id);
        langArr.push(targetLanguage[index])
      });
    }

    setDefaultLanguage(langArr);

  }, [props.defaultLanguage, targetLanguage, props.targetlanguages])

  return (

    <>
      <FormattedMessage id="select.target.languages">
        {() =>
          <>
            {props.isUseValue == 1 && defaultLanguage ?
              <Autocomplete
                multiple
                limitTags={1}
                filterSelectedOptions
                // customChipSize is using only for send quote page due to responsive issue
                className={clsx(classes.inputWidth, classes[props.size ? props.size : 'sizeMd'], (props.customChipSize && classes.customChipSize))}
                id="tags-outlined"
                options={targetLanguage}
                size="small"
                popupIcon={<MKeyboardArrowDownRoundedIcon />}
                getOptionLabel={(option: any) => (option?.code && option?.name) ? option.name + ' (' + option.code + ') ' : ""}
                defaultValue={defaultLanguage}
                value={defaultLanguage || null}
                onOpen={() => filterTargetLanguage(data && data.sourceLanguages)}
                sx={{ backgroundColor: '#fff' }}
                onChange={(event: any, value: any) => {
                  const languagesCode: any[] = [];
                  const newTargetLanguages = data && data.sourceLanguages;
                  value.forEach((languageObj: Language) => {

                    if (props.returnInstance && props.returnInstance === 'id') {
                      languagesCode.push(languageObj.id);
                      newTargetLanguages.filter((element: Language) => element.id !== languageObj.id);
                    } else if (props.returnInstance && props.returnInstance === 'object') {
                      languagesCode.push({ 'id': languageObj.id, name: languageObj.name, code: languageObj.code });
                      newTargetLanguages.filter((element: Language) => element.id !== languageObj.id);
                    } else {
                      languagesCode.push(languageObj.code);
                      newTargetLanguages.filter((element: Language) => element.code !== languageObj.code);
                    }
                  });
                  if (props.setFieldValue && props.name) {
                    props.setFieldValue(props.name, languagesCode)
                  } else {
                    props.setTargetLanguages(languagesCode);
                  }
                  filterTargetLanguage(newTargetLanguages)
                  if (data.sourceLanguages && value.length < 1) {
                    filterTargetLanguage(data.sourceLanguages)
                  }
                }}
                renderInput={params => <TextField {...params}
                  key={props?.key}
                  variant="outlined"
                  label={props.label && props.label ? props.label + "" : null}
                  placeholder={props.placeholder ? props.placeholder + "" : ""}
                  color="secondary" />}
              />
              :
              <Autocomplete
                multiple
                limitTags={1}
                filterSelectedOptions
                // customChipSize is using only for send quote page due to responsive issue
                className={clsx(props.className ? props.className : '', classes.inputWidth, classes[props.size ? props.size : 'sizeMd'], (props.customChipSize && classes.customChipSize))}
                id="checkboxes-tags-demo"
                // name="targetLanguage"
                options={targetLanguage}
                // disableCloseOnSelect
                size="small"
                popupIcon={<MKeyboardArrowDownRoundedIcon />}
                getOptionLabel={(option: any) => (option?.code && option?.name) ? option.name + ' (' + option.code + ') ' : ""}
                defaultValue={(selectedTargetLang ? selectedTargetLang : defaultLanguage) || null}
                // value={defaultLanguage}
                onOpen={() => filterTargetLanguage(data && data.sourceLanguages)}
                sx={{ backgroundColor: '#fff' }}
                onChange={(event: any, value: any) => {
                  const languagesCode: any[] = [];
                  const newTargetLanguages = data && data.sourceLanguages;
                  value.forEach((languageObj: Language) => {
                    if (props.returnInstance && props.returnInstance === 'id') {
                      languagesCode.push(languageObj.id);
                      newTargetLanguages.filter((element: Language) => element.id !== languageObj.id);
                    } else if (props.returnInstance && props.returnInstance === 'object') {
                      languagesCode.push({ 'id': languageObj.id, name: languageObj.name, code: languageObj.code });
                      newTargetLanguages.filter((element: Language) => element.id !== languageObj.id);
                    } else {
                      languagesCode.push(languageObj.code);
                      newTargetLanguages.filter((element: Language) => element.code !== languageObj.code);
                    }
                  });

                  if (props.setFieldValue && props.name) {
                    props.setFieldValue(props.name, languagesCode);
                    if (props.setTargetLanguages) {
                      props.setTargetLanguages(languagesCode);
                    }
                  } else {
                    props.setTargetLanguages(languagesCode);
                  }
                  filterTargetLanguage(newTargetLanguages)
                  if (data.sourceLanguages && value.length < 1) {
                    filterTargetLanguage(data.sourceLanguages)
                  }
                }}
                renderInput={params => <TextField
                  {...params}
                  key={props?.key}
                  variant="outlined"
                  label={props.label && props.label ? props.label + "" : null}
                  placeholder={props.placeholder ? props.placeholder + "" : ""}
                  color="secondary" />}
              />

            }


          </>
        }
      </FormattedMessage>
    </>
  );
}


