/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { FormattedMessage, FormattedDateParts } from 'react-intl';
import Toolbar from '@mui/material/Toolbar';
import { useIntl } from "react-intl";
import AppBar from '@mui/material/AppBar';
import { htmlToText } from 'html-to-text';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { IJob, IProps } from './interface';
import SingleJob from './SingleJob';
import { MFilterAltOutlinedIcon } from 'src/app/material-ui/icons';
import SendQuote from '../../screens/vendor/send-quote/SendQuote';
import {
    MBox, MDrawer, MToolbar, MIconButton, MButton, MAvatar,
    MGrid, MPagination, MSkeleton, MLink,
} from 'src/app/material-ui';
import { EmptyContent } from 'src/app/components';
import { JobListingFilterMbl } from 'src/app/components'
import { JobListingFilterDsk } from 'src/app/components'
import { ListingSkeleton } from 'src/app/components'
import { fetchJobsRequest } from 'src/app/store';
import useStyles from './JobListingStyles';

const ALL_SERVICES = gql`
    query getAllServices {
    getAllServices{
                id
                name
                }
 }
`;

const SOURCE_LANGUAGE_GQL = gql`
  query sourceLanguages {
    sourceLanguages {
      code
      id
      name
    }
  }
`;

const QUOTE_RESPONSE_DETAILS = gql`
    query quoteResponseDetails($moduleName: String!,$quoteResponseId:ID) {
        quoteResponseDetails(moduleName: $moduleName,quoteResponseId: $quoteResponseId) {
            currency
            quote {
                id
                client {
                    id
                    profilePicture
                    firstName
                    lastName
                }
                module {
                    id
                    title
                    description
                    instruction
                    sourceLanguage {
                        id
                        name
                        code
                    }
                    industry {
                        id
                        name
                    }
                }
                createdAt
                vendorJobCount

            }
            id
            quoteServices {
                id
                serviceName
                targetLanguages {
                    id
                    name
                    code
                }
                words
                unitPrice
                unit
                subTotal
                matchRateDetails
            }
            description
            totalAmount
            discountedAmount
            taxAmount
            balance
            isPending
            taxPercentage
            discountPercentage
        }
    }`;

export default function JobListing(props: IProps) {
    const classes = useStyles({});
    const intl = useIntl();

    const [jobsList, setJobsList]: any = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [serviceData, setServiceData] = useState([]);
    const [languagesData, setLanguagesData] = useState([]);
    const [servicesFieldValue, setServicesFieldValue] = useState('');
    const [statusFieldValue, setStatusFieldValue] = useState('');
    const [languagesFieldValue, setLanguagesFieldValue] = useState('');
    const [clientName, setClientName] = useState('');
    const [formJobTitle, setFormJobTitle] = useState('');
    const [open, setOpen] = React.useState(false);
    const [isDrawer, setIsDrawer] = useState(false);
    const [filter, setFilter] = useState(false);
    const [jobQuoteDetails, setJobQuoteDetails]: any = useState();
    const [selectedQuoteResponse, setSelectedQuoteResponse]: any = useState([]);
    const [isFullWidth, setIsFullWidth] = React.useState(false);
    const [currency, setCurrency]: any = useState("");
    const currencies = process.env.REACT_APP_CURRENCIES && JSON.parse(process.env.REACT_APP_CURRENCIES) || [];

    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const jobListData = useSelector((store: any) => store.jobs.jobsList);
    const openProjectsLoading = useSelector((store: any) => store.jobs.jobLoading);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    let jobId: any = 0;
    if (id) {
        jobId = id;
    }
    let statusArray: any = [];
    const dispatch = useDispatch();
    if (props.status == 'open') {
        statusArray = [{ id: '2', name: intl.formatMessage({ id: "pending" }) }, { id: '8', name: intl.formatMessage({ id: "po.review" }) }];
    } else {
        statusArray = [{ id: '3', name: intl.formatMessage({ id: "working" }) }, { id: '4', name: intl.formatMessage({ id: "dispatched" }) }, { id: '10', name: intl.formatMessage({ id: "approved" }) }, { id: '13', name: intl.formatMessage({ id: "invoice.pending" }) }, { id: '14', name: intl.formatMessage({ id: "invoice.paid" }) }, { id: '15', name: intl.formatMessage({ id: "invoice.approved" }) },
        { id: '16', name: intl.formatMessage({ id: "invoice.rejected" }) }, { id: '17', name: intl.formatMessage({ id: "invoice.review" }) }];
    }

    const urlType = window.location.href.split('/')[4];


    useQuery(ALL_SERVICES, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setServiceData(data.getAllServices);
        },
    });

    useQuery(SOURCE_LANGUAGE_GQL, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setLanguagesData(data.sourceLanguages);
        },
    });

    // alert(hideFilter)
    const [getQuoteResponseDetails, { loading: isLoadingQuoteData }] = useLazyQuery(QUOTE_RESPONSE_DETAILS, {
        variables: { moduleName: "job", quoteResponseId: props.selectedItem ? props.selectedItem.quoteResponseId : 0 },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setSelectedQuoteResponse(data && data.quoteResponseDetails);
            setCurrency(data && data.quoteResponseDetails?.currency ? currencies?.find((curr: any) => curr.code === data.quoteResponseDetails?.currency) : "");
            setJobQuoteDetails(data && data.quoteResponseDetails);
            // if (props.setProposalTitle) {
            //     props.setProposalTitle(data.quoteResponseDetails.quote.module.title);
            // }

        },
    });

    // const [sendDrawer, setSendDrawer] = useState(false);

    useEffect(() => {
        if (props.selectedItem) {
            getQuoteResponseDetails();
            // handleSetFileId();
        }
        setIsDrawer(props.isProposalViewDrawer);
    }, [props.isProposalViewDrawer]);

    useEffect(() => {
        if (selectedOrgId!=null && selectedOrgId !== 0 && currentUser.id != 0) {
            if (typeof urlType !== "undefined" && urlType === 'open-jobs') {
                subscribeOpenJobEvent();
            }

            if (typeof urlType !== "undefined" && urlType !== 'open-jobs') {
                subscribMyJobJobEvent();
            }
        }
    }, [currentUser, props.status, selectedOrgId]);

    useEffect(() => {
        if (jobId !== 0) {
            getAllJobs("");
        }
    }, [jobId]);

    useEffect(() => {
        if (jobListData) {
            setJobsList(jobListData?.getVendorOpenJobList.openProjectInfo);
            setTotalPages(Math.ceil(jobListData?.getVendorOpenJobList.totalProjects / 10));
            if (props.selectedJob) {
                props.setSelectedJob(jobListData?.getVendorOpenJobList.openProjectInfo.find((item: any) => {
                    return item.id == props.selectedJob.id
                }));
            }
        }
    }, [jobListData])

    useEffect(() => {
        if (selectedOrgId) {
            // dispatch(fetchJobsRequest({
            //     cancel: 1
            // }));
            setTimeout(function () {
                getAllJobs("");
            }, 1000);

        }
    }, [page, props.refreshProjects, props.updatePOOpenModal, filter, selectedOrgId]);
    useEffect(() => {
        if (props.proposalDetailsDrawer) {
            // setIsDrawer(true);
            handleSetFileId();
        } else {
            if (typeof props.setIsProposalViewDrawer != 'undefined') {
                props.setIsProposalViewDrawer(false);
            }
            setIsDrawer(false);
        }
    }, [props.proposalDetailsDrawer]);

    useEffect(() => {
        if (!isDrawer && typeof props.setProposalDetailsDrawer !== "undefined") {
            props.setProposalDetailsDrawer(false);
        }
        if (!isDrawer && typeof props.setIsProposalViewDrawer !== "undefined") {
            props.setIsProposalViewDrawer(false);
        }
    }, [isDrawer]);

    function subscribeOpenJobEvent() {
        const url: any = new URL(process.env.REACT_APP_MERCURE_URL + '/.well-known/mercure');
        url.searchParams.append('topic', 'request/open-job/' + selectedOrgId);
        const eventSource = new EventSource(url);
        // The callback will be called every time an update is published
        eventSource.onmessage = ((e: any) => {
            const urlType = window.location.href.split('/')[4];

            if (urlType === 'open-jobs') {
                getAllJobs('open');
            }
        });
    }

    function subscribMyJobJobEvent() {
        const url: any = new URL(process.env.REACT_APP_MERCURE_URL + '/.well-known/mercure');
        if (appUserRole == 'ROLE_TRANSLATOR') {
            url.searchParams.append('topic', 'request/my-job/' + currentUser?.id);
        } else {
            url.searchParams.append('topic', 'request/my-job/' + selectedOrgId);
        }
        const eventSource = new EventSource(url);
        // The callback will be called every time an update is published
        eventSource.onmessage = ((e: any) => {
            const urlType = window.location.href.split('/')[4];
            if (urlType !== 'open-jobs') {
                getAllJobs('assigned')
            }
        });
    }

    const getAllJobs = (status?: any) => {
        // console.log('status',status);
        let statusVal = props.status;
        if (typeof status != "undefined" && status != "") {
            statusVal = status;
        }

        if(selectedOrgId!=null && selectedOrgId!=0) {
            // console.log('selectedOrgId',selectedOrgId);

            dispatch(fetchJobsRequest({
                orgId: localStorage.getItem('selectedOrgId'),
                page: page,
                clientName: clientName,
                langIds: languagesFieldValue,
                serviceIds: servicesFieldValue,
                jobTitle: formJobTitle,
                jobId: +jobId,
                statusValues: statusFieldValue,
                projectId: props.projectId ? props.projectId : "0",
                status: statusVal
            }));
        }
        // ...(props.status && { status: props.status })
    }



    //   const [getAllJobs, { loading: openProjectsLoading }] = useLazyQuery(props.JOBS_QUERY, {
    //      fetchPolicy: 'no-cache',
    //      variables: {
    //          page: page,
    //          clientName: clientName,
    //          langIds: languagesFieldValue,
    //          serviceIds: servicesFieldValue,
    //          jobTitle: formJobTitle,
    //          statusValues: statusFieldValue,
    //          projectId: props.projectId ? props.projectId : 0,
    //          ...(props.status && { status: props.status })
    //      },
    //      onCompleted: (data: any) => {
    //          setJobsList(data.getVendorOpenJobList.openProjectInfo);
    //          setTotalPages(Math.ceil(data.getVendorOpenJobList.totalProjects / 10));

    //          // alert(props.selectedJob);
    //          // alert(props.setSelectedJob);
    //          if(props.selectedJob) {
    //              props.setSelectedJob(data.getVendorOpenJobList.openProjectInfo.find((item:any) => {
    //                  return item.id = props.selectedJob.id
    //              }));
    //          }
    //      },
    //  }); 


    const decodedDescription = htmlToText(selectedQuoteResponse.description, {
        // wordwrap: 2
    });

    const handlePageChange = (event: React.ChangeEvent<any>, value: number) => {
        setPage(value);
        event?.preventDefault();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSetFileId = () => {
        getQuoteResponseDetails();
        setIsDrawer(true);
    };


    return (
        <MBox>
            <MGrid container>
                <MDrawer
                    open={props.sendDrawer}
                    variant="temporary"
                    anchor='right'
                    className={`${classes.fileUploadDrawer} ${isFullWidth?'fullWidth':''}`}
                >
                    <AppBar className='drawerAppBar'>
                        <MToolbar>
                            <MBox display="inline-block" mr={1}>
                                <MIconButton onClick={() => props.setSendDrawer(false)}>
                                    <ArrowBackIcon />
                                </MIconButton>
                            </MBox>
                            <MBox display="flex" justifyContent="flex-end">
                                <MBox width="1000px" fontSize={23} fontFamily="Poppins">
                                    <FormattedMessage id="send.bid.proposal" />
                                </MBox>
                            </MBox>
                        </MToolbar>
                    </AppBar>
                    <MBox pb={5}>
                        {props.selectedItem && <SendQuote getAllJobs={getAllJobs} setSendDrawer={props.setSendDrawer} sendDrawer={props.sendDrawer}
                            quoteResponseId={props.selectedItem.quoteRequestId} setIsFullWidth={setIsFullWidth} />}
                    </MBox>
                </MDrawer>

                <MDrawer
                    open={isDrawer}
                    variant="temporary"
                    anchor='right'
                    className={classes.fileUploadDrawer}
                >
                    <AppBar className='drawerAppBar'>
                        <Toolbar>
                            <MBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
                                <MBox display="flex">
                                    <MBox display="inline-block" mr={1}>
                                        <MIconButton onClick={() => setIsDrawer(false)}>
                                            <ArrowBackIcon />
                                        </MIconButton>
                                    </MBox>
                                    <MBox fontSize={18} fontFamily="Poppins"
                                        mt={1.25}><FormattedMessage id="view.proposal" /></MBox>
                                </MBox>
                                {props.selectedItem && <MBox>
                                    <MLink underline="none"
                                        href={process.env.REACT_APP_API_END_POINT + '/download/quote/pdf/' + props.selectedItem.quoteResponseId}>
                                        <MButton
                                            variant="contained"
                                            sx={{
                                                marginLeft: '10px', backgroundColor: '#A6A6A6',
                                                color: '#fff',
                                            }}>
                                            <FormattedMessage id="download.proposal" />
                                        </MButton>
                                    </MLink>
                                </MBox>}
                            </MBox>
                        </Toolbar>
                    </AppBar>
                    <MBox pb={5}>
                        <MBox className={classes.formBox}>
                            <MGrid container>
                                {isLoadingQuoteData ?
                                    <>
                                        <MBox mb={2} width="100%" display="flex" justifyContent="space-between">
                                            <MBox width="100%">
                                                <MBox width="100%" display="flex" justifyContent="space-between">
                                                    <MBox fontFamily="Poppins" fontSize={24} pl={1.3}>
                                                        <MSkeleton width={200} height='100%' />
                                                    </MBox>
                                                    <MBox display="flex" justifyContent="space-between"
                                                        alignItems="flex-end">
                                                        <MBox mr={6}>
                                                            <MSkeleton width={150} height='100%' />
                                                        </MBox>
                                                        <MBox>
                                                            <MSkeleton width={150} height='100%' />
                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                            </MBox>
                                        </MBox>
                                        <MGrid item xs={12}>
                                            <MBox color="palette.secondary" className={classes.contentBox}>

                                                <MBox mb={1} display="flex" alignItems="center" pl={1.3}>
                                                    <MSkeleton variant='circle' width={40} height={40} />
                                                </MBox>

                                                <MBox pl={1.3}>
                                                    <MSkeleton width={150} height='100%' />
                                                </MBox>

                                                {jobQuoteDetails && jobQuoteDetails.quote.module.instruction &&
                                                    <MBox className={classes.instructionWrap} color="palette.secondary">
                                                        <MSkeleton width={150} height='100%' />
                                                    </MBox>
                                                }

                                                <MBox className={classes.hr} my={5}></MBox>
                                                <MBox mb={4} pl={2} display="flex" justifyContent="space-between"
                                                    width="45%">
                                                    <MBox>
                                                        <MBox mb={1.8} className={classes.greyUppercaseText}>
                                                            <MSkeleton width={150} height='100%' />
                                                        </MBox>
                                                        <MBox>
                                                            <MSkeleton width={150} height='100%' />
                                                        </MBox>
                                                    </MBox>
                                                    <MBox width="calc(50% - -7px)">
                                                        <MBox mb={1.8} className={classes.greyUppercaseText}>
                                                            <MSkeleton width={150} height='100%' />
                                                        </MBox>
                                                        <MBox>
                                                            <MSkeleton width={150} height='100%' />
                                                        </MBox>
                                                    </MBox>
                                                </MBox>

                                                <MBox pr={2} display="flex" justifyContent="space-between"
                                                    alignItems="center">
                                                    <MBox width="45%" display="flex" justifyContent="space-between"
                                                        p={2}
                                                        className={classes.headerLeftCol}>
                                                        <MBox className={classes.greyUppercaseText}>
                                                            <MSkeleton width='100%' height='100%' />
                                                        </MBox>
                                                        <MBox className={classes.greyUppercaseText}>
                                                            <MSkeleton width='100%' height='100%' />
                                                        </MBox>
                                                    </MBox>
                                                    <MBox width="45%">
                                                        <MBox display="flex" justifyContent="space-between" p={2}
                                                            pl={0.87}
                                                            className={classes.headerRightCol} alignItems="center">
                                                            <MBox className={classes.greyUppercaseText} maxWidth={83}>
                                                                <MSkeleton width='100%' height='100%' />
                                                            </MBox>
                                                            <MBox className={classes.greyUppercaseText}>
                                                                <MSkeleton width='30%' height='100%' />
                                                            </MBox>
                                                            <MBox className={classes.greyUppercaseText} maxWidth={50}>
                                                                <MSkeleton width='100%' height='100%' />
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                </MBox>

                                                {(selectedQuoteResponse && selectedQuoteResponse.quoteServices ?
                                                    selectedQuoteResponse.quoteServices.map((serviceValue: any, index: number) => (
                                                        <MBox key={"quoteServices-" + index} display="flex">
                                                            <MBox width="100%" className={classes.bgLightGrey}
                                                                display="flex"
                                                                key={"box" + index}
                                                                justifyContent="space-between" mb={1}
                                                                borderRadius={4}>
                                                                <MBox width="44.5%" display="flex"
                                                                    justifyContent="space-between" p={2}
                                                                    className={classes.leftCol}>
                                                                    <MBox position="relative">
                                                                        <MSkeleton width='100%' height='100%' />
                                                                    </MBox>
                                                                    <MBox position="relative">
                                                                        {serviceValue.targetLanguages.map(() => (
                                                                            <MBox key="targetLang" className={classes.greyUppercaseText}>
                                                                                <MSkeleton width='100%' height='100%' />
                                                                            </MBox>
                                                                        ))}
                                                                    </MBox>
                                                                </MBox>

                                                                <MBox width="45%">
                                                                    <MBox display="flex" justifyContent="space-between"
                                                                        p={2} pl={0}
                                                                        className={classes.rightCol}>
                                                                        <MBox display="flex"
                                                                            justifyContent="space-between"
                                                                            maxWidth={210}>
                                                                            <MBox className={classes.greyUppercaseText}
                                                                                maxWidth={80}><MSkeleton width='100%'
                                                                                    height='100%' /></MBox>
                                                                        </MBox>
                                                                        <MBox position="relative" maxWidth={90}
                                                                            mr={8.3}><MSkeleton width='100%'
                                                                                height='100%' /></MBox>
                                                                        <MBox pt={1} maxWidth={40}><MSkeleton
                                                                            width='100%' height='100%' /></MBox>
                                                                    </MBox>
                                                                </MBox>
                                                            </MBox>
                                                        </MBox>
                                                    ))
                                                    : "")
                                                }
                                                <MBox pt={2}>
                                                    <MBox display="flex" justifyContent="flex-end">
                                                        <MBox width="49%" pl={7.2} sx={{ paddingRight: 22 }}>
                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"
                                                                pb={1}>
                                                                <MBox className={classes.colorLightGrey}>
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                                <MBox>
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                            </MBox>
                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"
                                                                py={1} mb={1}>
                                                                <MBox width="55%" py={1} display="flex"
                                                                    justifyContent="space-between"
                                                                    alignItems="center">
                                                                    <MBox width="100%"
                                                                        className={classes.colorLightGrey}>
                                                                        <MSkeleton width={70} height='100%' />
                                                                    </MBox>
                                                                    <MBox width="50%" textAlign="right">
                                                                        <MSkeleton width={70} height='100%' />
                                                                    </MBox>
                                                                </MBox>
                                                                <MBox>
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                            </MBox>

                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"
                                                                py={1} mb={1}>
                                                                <MBox width="55%" py={1} display="flex"
                                                                    justifyContent="space-between"
                                                                    alignItems="center">
                                                                    <MBox width="100%" borderRadius={4}
                                                                        className={classes.colorLightGrey}>
                                                                        <MSkeleton width={70} height='100%' />
                                                                    </MBox>
                                                                    <MBox width="50%" textAlign="right">
                                                                        <MSkeleton width={70} height='100%' />
                                                                    </MBox>
                                                                </MBox>

                                                                <MBox>
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                            </MBox>

                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"
                                                                py={1} mb={1}>
                                                                <MBox className={classes.colorLightGrey}>
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                                <MBox width="50%" textAlign="right">
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                            </MBox>

                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"
                                                                py={1} mb={1}>
                                                                <MBox className={classes.colorLightGrey}>
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                                <MBox fontSize={22} color="secondary.main"
                                                                    fontFamily="Poppins Bold">
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                            </MBox>
                                        </MGrid>
                                    </>
                                    :
                                    <>
                                        <MBox mb={2} width="100%" display="flex" justifyContent="space-between">
                                            <MBox width="100%">
                                                <MBox width="100%" display="flex" justifyContent="space-between">
                                                    <MBox fontFamily="Poppins"
                                                        fontSize={24} pl={1.3}>
                                                        {/* {selectedQuoteResponse && selectedQuoteResponse.quote && selectedQuoteResponse.quote.module.title}<br /> */}
                                                        {(props.selectedItem && typeof props.selectedItem.isPO != "undefined" && props.selectedItem.isPO) ? 'Purchase Order #' : 'Proposal #'}
                                                        {selectedQuoteResponse && selectedQuoteResponse.quote && selectedQuoteResponse.quote.module.id}</MBox>
                                                    <MBox display="flex" justifyContent="space-between" alignItems="flex-end">
                                                        <MBox mr={6}>
                                                            <span style={{ color: '#a0a5b9', marginRight: 15 }}>
                                                                <FormattedMessage id="date.issue" />:
                                                            </span>
                                                            <FormattedDateParts
                                                                value={jobQuoteDetails && jobQuoteDetails.module && jobQuoteDetails.module.createdAt}
                                                                year="numeric"
                                                                month="2-digit"
                                                                day="2-digit">
                                                                {parts => (
                                                                    <>
                                                                        {parts[2].value}
                                                                        {parts[1].value}
                                                                        {parts[0].value}
                                                                        {parts[3].value}
                                                                        {parts[4].value}
                                                                    </>
                                                                )}
                                                            </FormattedDateParts>
                                                        </MBox>
                                                        <MBox>
                                                            <span style={{ color: '#a0a5b9', marginRight: 15 }}>
                                                                <FormattedMessage id="date.due" />:
                                                            </span>
                                                            <FormattedDateParts
                                                                value={jobQuoteDetails && jobQuoteDetails.module && jobQuoteDetails.module.deadline}
                                                                year="numeric"
                                                                month="2-digit"
                                                                day="2-digit">
                                                                {parts => (
                                                                    <>
                                                                        {parts[2].value}
                                                                        {parts[1].value}
                                                                        {parts[0].value}
                                                                        {parts[3].value}
                                                                        {parts[4].value}
                                                                    </>
                                                                )}
                                                            </FormattedDateParts>
                                                        </MBox>

                                                    </MBox>
                                                </MBox>
                                            </MBox>
                                        </MBox>

                                        <MGrid item xs={12}>
                                            <MBox color="palette.secondary" className={classes.contentBox}>
                                                {(jobQuoteDetails && jobQuoteDetails.quote.client && jobQuoteDetails.quote.client.profilePicture ?
                                                    <MBox mb={1} display="flex" alignItems="center" pl={1.3}>
                                                        <MAvatar
                                                            src={process.env.REACT_APP_PUBLIC_URL + '/' + jobQuoteDetails.quote.client.profilePicture}
                                                            alt="logo"
                                                            sx={{ width: 60, height: 60 }} mr={1} />
                                                        <MBox color="palette.secondary" maxWidth="737px" ml={2}>
                                                            {jobQuoteDetails && jobQuoteDetails.quote.client && jobQuoteDetails.quote.client.firstName + ' ' + jobQuoteDetails.quote.client.lastName}
                                                        </MBox>
                                                    </MBox> :
                                                    <MBox mb={1} display="flex" alignItems="center" pl={1.3}>
                                                        <MAvatar alt="logo" src="/assets/images/avatar.jpg"
                                                            width="60px" height="60px" />
                                                    </MBox>
                                                )}
                                                <MBox pl={1.3}>
                                                    {selectedQuoteResponse && decodedDescription}
                                                </MBox>
                                                {jobQuoteDetails && jobQuoteDetails.quote.module.instruction &&
                                                    <MBox className={classes.instructionWrap} color="palette.secondary">
                                                        <b><FormattedMessage id="instruction:" /> </b>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: jobQuoteDetails && jobQuoteDetails.quote.module.instruction ? jobQuoteDetails.quote.module.instruction : '' }} />
                                                    </MBox>
                                                }
                                                <MBox className={classes.hr} my={5}></MBox>
                                                <MBox mb={4} pl={2} display="flex" justifyContent="space-between"
                                                    width="45%">
                                                    <MBox>
                                                        <MBox mb={1.8} className={classes.greyUppercaseText}>
                                                            <FormattedMessage id="source.language" />
                                                        </MBox>
                                                        <MBox>
                                                            {selectedQuoteResponse && selectedQuoteResponse.quote && selectedQuoteResponse.quote.module && selectedQuoteResponse.quote.module.sourceLanguage && selectedQuoteResponse.quote.module.sourceLanguage.name}
                                                        </MBox>
                                                    </MBox>
                                                    <MBox width="calc(50% - -7px)">
                                                        <MBox mb={1.8} className={classes.greyUppercaseText}>
                                                            <FormattedMessage id="subject" />
                                                        </MBox>
                                                        <MBox>
                                                            {selectedQuoteResponse && selectedQuoteResponse.quote && selectedQuoteResponse.quote.module.industry && selectedQuoteResponse.quote.module.industry.name}
                                                        </MBox>
                                                    </MBox>
                                                </MBox>

                                                <MBox pr={2} display="flex" justifyContent="space-between" alignItems="center">
                                                    <MBox width="45%" display="flex" justifyContent="space-between" p={2} className={classes.headerLeftCol}>
                                                        <MBox className={classes.greyUppercaseText}>
                                                            <FormattedMessage id="services" />
                                                        </MBox>
                                                        <MBox className={classes.greyUppercaseText}>
                                                            <FormattedMessage id="target.languages" />
                                                        </MBox>
                                                    </MBox>
                                                    <MBox width="45%">
                                                        <MBox display="flex" justifyContent="space-between" p={2} pl={0.87} className={classes.headerRightCol} alignItems="center">
                                                            <MBox className={classes.greyUppercaseText} maxWidth={83}>
                                                                <FormattedMessage id="unit.price" />({currency?.symbol})
                                                            </MBox>
                                                            <MBox className={classes.greyUppercaseText} maxWidth={0}>
                                                                <FormattedMessage id="qty" />
                                                            </MBox>
                                                            <MBox className={classes.greyUppercaseText} maxWidth={50}>
                                                                <FormattedMessage id="subtotal" />
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                                {(selectedQuoteResponse && selectedQuoteResponse.quoteServices ?
                                                    selectedQuoteResponse.quoteServices.map((serviceValue: any, _idx: number) => (
                                                        <MBox key={"quoteServices1-" + _idx} display="flex">
                                                            <MBox width="100%" className={classes.bgLightGrey}
                                                                display="flex"
                                                                key="box1"
                                                                justifyContent="space-between" mb={1}
                                                                borderRadius={4}>
                                                                <MBox width="44.5%" display="flex"
                                                                    justifyContent="space-between" p={2}
                                                                    className={classes.leftCol}>
                                                                    <MBox position="relative">
                                                                        {serviceValue.serviceName}
                                                                    </MBox>
                                                                    <MBox position="relative">
                                                                        {serviceValue.targetLanguages.map((targetLanguage: any, idx: number) => (
                                                                            <MBox key={"serviceValue-" + idx}
                                                                                className={classes.greyUppercaseText}>{targetLanguage.name} &nbsp; ({targetLanguage.code})</MBox>
                                                                        ))}
                                                                    </MBox>
                                                                </MBox>

                                                                <MBox width="45%">
                                                                    <MBox display="flex" justifyContent="space-between"
                                                                        p={2} pl={0}
                                                                        className={classes.rightCol}>
                                                                        <MBox display="flex"
                                                                            justifyContent="space-between"
                                                                            maxWidth={210}>
                                                                            <MBox className={classes.greyUppercaseText}
                                                                                maxWidth={80}>{serviceValue.unitPrice}</MBox>
                                                                        </MBox>
                                                                        <MBox position="relative" maxWidth={90}
                                                                            mr={8.3}>{serviceValue.words} {serviceValue.unit}</MBox>
                                                                        <MBox pt={1}
                                                                            maxWidth={40}>{currency?.symbol}{serviceValue.subTotal}</MBox>
                                                                    </MBox>
                                                                </MBox>
                                                            </MBox>
                                                        </MBox>
                                                    ))
                                                    : "")}

                                                <MBox pt={2}>
                                                    <MBox display="flex" justifyContent="flex-end">
                                                        {/* <MBox width="45%">
                                                        <MBox mb={1} display="flex" justifyContent="space-between"
                                                            alignItems="center" pb={1} pr={2}>
                                                            <MBox className={classes.colorLightGrey}>Cover Letter</MBox>
                                                        </MBox>
                                                        <MBox className={classes.coverLetterBox} backgroundColor="#f1f1f1"
                                                            minHeight={100} borderRadius={4}>
                                                            {selectedQuoteResponse && decodedDescription}
                                                        </MBox>
                                                    </MBox> */}
                                                        {/* final calculations */}
                                                        <MBox width="49%" pl={7.2} sx={{ paddingRight: 22 }}>
                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"
                                                                pb={1}>
                                                                <MBox className={classes.colorLightGrey}>
                                                                    <FormattedMessage id="total.price" />
                                                                </MBox>
                                                                <MBox>{currency?.symbol}{selectedQuoteResponse.totalAmount && selectedQuoteResponse.totalAmount}</MBox>
                                                            </MBox>
                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"
                                                                py={1} mb={1}>
                                                                <MBox width="55%" py={1}
                                                                    display="flex" justifyContent="space-between"
                                                                    alignItems="center">
                                                                    <MBox width="100%" className={classes.colorLightGrey}>
                                                                        <FormattedMessage id="discount" />
                                                                    </MBox>
                                                                    <MBox width="50%"
                                                                        textAlign="right">{selectedQuoteResponse.discountedAmount && selectedQuoteResponse.discountPercentage} %</MBox>
                                                                </MBox>
                                                                <MBox>{currency?.symbol}{selectedQuoteResponse.discountedAmount && selectedQuoteResponse.discountedAmount}</MBox>
                                                            </MBox>
                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"
                                                                py={1} mb={1}>
                                                                <MBox width="55%" py={1}
                                                                    display="flex" justifyContent="space-between"
                                                                    alignItems="center">
                                                                    <MBox width="100%" borderRadius={4} className={classes.colorLightGrey}>
                                                                        <FormattedMessage id="tax" />
                                                                    </MBox>
                                                                    <MBox width="50%" textAlign="right">
                                                                        {selectedQuoteResponse.taxAmount && selectedQuoteResponse.taxPercentage} %
                                                                    </MBox>
                                                                </MBox>

                                                                <MBox>
                                                                {currency?.symbol} {selectedQuoteResponse.taxAmount && selectedQuoteResponse.taxAmount}
                                                                </MBox>
                                                            </MBox>

                                                            {/* <MBox display="flex" justifyContent="space-between" alignItems="center" py={1} mb={1}>
                                                                <MBox className={classes.colorLightGrey}>
                                                                    <FormattedMessage id="paid.to.date" />
                                                                </MBox>
                                                                <MBox width="50%" textAlign="right">0</MBox>
                                                            </MBox> */}

                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"
                                                                py={1} mb={1}>
                                                                <MBox className={classes.colorLightGrey}>
                                                                    <FormattedMessage id="balance" />({currency?.symbol})
                                                                </MBox>
                                                                <MBox fontSize={22} color="secondary.main" fontFamily="Poppins Bold">
                                                                    {currency?.symbol} {selectedQuoteResponse && selectedQuoteResponse.balance}
                                                                </MBox>
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                            </MBox>

                                        </MGrid>
                                    </>
                                }
                            </MGrid>
                        </MBox>
                    </MBox>
                </MDrawer>

                <MBox mb={2.25} width="100%" display="flex" justifyContent="space-between">
                    <MBox width="100%" display="flex">
                        {/* <MBox>
                            <BreadcrumbsNavigation/>
                        </MBox>
                        {(openProjectsLoading || (jobsList && jobsList.length>0)) &&
                            <MBox fontFamily="Poppins" fontSize={24}><FormattedMessage id="open.jobs"/></MBox>
                        } */}
                        <MBox sx={{ display: { xs: "block", md: 'none' } }}>
                            <MBox variant="none" width="auto" marginLeft="auto" color="#42526E" onClick={handleClickOpen}>
                                <MFilterAltOutlinedIcon />
                                <span style={{ color: '#42526E', marginLeft: '5px' }}>
                                    <FormattedMessage id="filters" />
                                </span>
                            </MBox>
                        </MBox>
                        <MBox>
                            {/*****  Start For Mobile  *****/}
                            <MBox sx={{ display: { xs: "block", md: 'none' } }}>
                                <JobListingFilterMbl
                                    setFormJobTitle={setFormJobTitle}
                                    formJobTitle={formJobTitle}
                                    setClientName={setClientName}
                                    clientName={clientName}
                                    setStatusFieldValue={setStatusFieldValue}
                                    setLanguagesFieldValue={setLanguagesFieldValue}
                                    statusArray={statusArray}
                                    languagesFieldValue={languagesFieldValue}
                                    statusFieldValue={statusFieldValue}
                                    servicesFieldValue={servicesFieldValue}
                                    getAllJobs={getAllJobs}
                                    setOpen={setOpen}
                                    openProjectsLoading={openProjectsLoading}
                                    jobsList={jobsList} open={open}
                                    setServicesFieldValue={setServicesFieldValue}
                                    handleClose={handleClose}
                                    classes={classes}
                                    languagesData={languagesData}
                                    serviceData={serviceData}
                                    filter={filter}
                                    setFilter={setFilter}
                                />
                            </MBox>
                            {/*****  End For Mobile  *****/
                                /*****  Start For Desktop  *****/}
                            <MBox sx={{ display: { xs: "none", md: 'block' } }}>
                                <JobListingFilterDsk
                                    setFormJobTitle={setFormJobTitle}
                                    formJobTitle={formJobTitle}
                                    setClientName={setClientName}
                                    clientName={clientName}
                                    setStatusFieldValue={setStatusFieldValue}
                                    setLanguagesFieldValue={setLanguagesFieldValue}
                                    statusArray={statusArray}
                                    languagesFieldValue={languagesFieldValue} statusFieldValue={statusFieldValue} servicesFieldValue={servicesFieldValue}
                                    getAllJobs={getAllJobs} openProjectsLoading={openProjectsLoading} jobsList={jobsList}
                                    setServicesFieldValue={setServicesFieldValue}
                                    classes={classes}
                                    languagesData={languagesData}
                                    serviceData={serviceData}
                                    filter={filter}
                                    setFilter={setFilter}
                                />
                            </MBox>
                        </MBox>
                    </MBox>
                </MBox>
                <MGrid item xs={12}>
                    {(openProjectsLoading || !jobsList) &&
                        <ListingSkeleton classes={classes} />
                    }

                    {!openProjectsLoading && jobsList && jobsList.length > 0 &&
                        <MBox className={classes.mblTableResponsive}>
                            <MBox className="mblRowResponsive">
                                {jobsList && jobsList.map((projects: IJob, index: number) => (
                                    <SingleJob key={"jobListKey" + index} handleSetFileId={handleSetFileId} setSelectedItem={props.setSelectedItem} projects={projects} setRefreshProjects={props.setRefreshProjects} getAllJobs={getAllJobs} {...props} isDrawer={isDrawer} jobEdit={props.jobEdit} jobView={props.jobView} />
                                ))}
                            </MBox>
                        </MBox>
                    }
                    {!openProjectsLoading && (jobsList && jobsList.length < 1) &&
                        <MBox className={classes.formBox} p={3}>
                            <MBox fontFamily="Poppins" fontSize={18}>
                                {props.status == 'open' ? <FormattedMessage id="open.jobs" /> : <FormattedMessage id="jobs" />}
                            </MBox>
                            <MBox minHeight={300} display="flex" alignItems="center" pb={5}>
                                <EmptyContent text={intl.formatMessage({ id: "no.jobs.found" })} />
                            </MBox>
                        </MBox>
                    }
                    {totalPages > 1 ?
                        <MBox mt={3} display="flex" justifyContent="flex-end">
                            <MPagination count={totalPages} page={page && page} onChange={handlePageChange} />
                        </MBox> : " "
                    }
                </MGrid>
            </MGrid>
        </MBox>
    )
}
