
import { put, takeEvery } from "redux-saga/effects";

import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";
import { GET_PROJECT_TASK_FETCH } from "./ProjectTaskActionTypes";

const query = gql`
query getMyCreatedTasks($orgId: ID,$projectId:String!,$page: Int!,$batchId: Int,$type: String,$projectType: String) {
  getMyCreatedTasks(orgId: $orgId,projectId:$projectId, page: $page,batchId: $batchId, type: $type, projectType: $projectType) {
      batch{
          id
          name
          isDirectAssign
          isPoSent
          status
          createdAt
          customerStatus
          industryName
          createdBy
          assignee {
              id
              firstName
              profilePicture
          }
          statusLabel
          statusClass
          statusMsg
          deadLine
          bidProposalDeadline
          isPO
          catProjectId
          isPOReview
          isPOApproved
          POReviewBy
          proposalsCount
          quoteSentCount
          quoteRequestId
          quoteResponseId
          invoices {
              id
              status
              statusLabel
          }
          files {
              id
              createdAt
              name
              editorData
              catFileId
              isOnline
              size
          }
          refFiles {
              id
              createdAt
              name
              size
          }
          sourceLanguage {
              id
              name
              code
          }
          languages {
              id
              name
              code
          }
          services {
              id
              name
              swId
          }
          servicePercentages {
              name
              percentage
              color
          }
          langServicePercentages {
              file
              langName
              langCode
              langFileId
              services {
                  langName
                  name
                  percentage
                  color
                  swId
              }
          }
          invoiceStatus
          invoiceId
          invoiceBalance
          customerInvoiceId
      }
      total
      projectTitle
  }
}
    `;

function* getProjectTaskFetch(variables: any): any {
    try {

        const projectTask = yield client.query({ query, variables, fetchPolicy: "no-cache" });
        yield put({ type: 'GET_PROJECT_TASK_FETCH_SUCCESS', response: projectTask.data });

    } catch (error) {
        console.log('error', error);
        // yield put({ type: 'API_CALL_FAILURE', error });
    }
}

export default function* projectTaskSaga() {
    yield takeEvery(GET_PROJECT_TASK_FETCH, getProjectTaskFetch);

}