/* eslint-disable */
/* eslint-disable @typescript-eslint/no-var-requires */
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { gql } from "apollo-boost";

import { FieldArray } from "formik";
// import Editor from 'material-ui-editor';
import React, { useEffect, useState } from "react";
import { FormattedDateParts, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FormErrorMessage } from "src/app/components";
import {
  MAutoComplete,
  MAvatar,
  MBox,
  MButton,
  MCircularProgress,
  MForm,
  MFormControlLabel,
  MFormik,
  MFormLabel,
  MGrid,
  MIconButton,
  MLink,
  MRadio,
  MSkeleton,
  MTextField,
} from "src/app/material-ui";
import { selectedVendorJobName, showAlert } from "src/app/store";
import * as Yup from "yup";
import AddMatchRateModal from "./match-rate-modal/AddMatchRateModal";
import useStyles from "./SendQuoteStyles";
import { UnitSelect } from "./unit-select/UnitSelect";

import { Autocomplete } from "@mui/material";
import { avatarPlaceholder } from "../../../hooks/index";
import SendQuoteSkeleton from "./SendQuoteSkeleton";

import TextField from "@mui/material/TextField";
import { CreateCustomerPOContext } from "../../client-screens/jobs/assign-job-modal/AssginJobModalContext";

import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useIntl } from "react-intl";


const QUOTE_RESPONSE_DETAILS = gql`
  query quoteResponseDetails($moduleName: String!, $quoteResponseId: ID) {
    quoteResponseDetails(
      moduleName: $moduleName
      quoteResponseId: $quoteResponseId
    ) {
      id
      currency
      quote {
        id
        projectId
        isPO
        client {
          id
          profilePicture
          firstName
          lastName
        }
        module {
          id
          title
          description
          sourceLanguage {
            id
            name
            code
          }
          industry {
            id
            name
          }
        }
        createdAt
        vendorJobCount
      }
      quoteServices {
        id
        serviceName
        targetLanguages {
          id
          name
          code
        }
        words
        unitPrice
        unit
        subTotal
        matchRateDetails
        matchRate {
          id
          name
        }
      }
      description
      totalAmount
      discountedAmount
      taxAmount
      balance
      isPending
      taxPercentage
      discountPercentage
    }
  }
`;

const JOB_QUOTE_DETAILS = gql`
  query quoteDetails($moduleName: String!, $quoteId: ID!) {
    quoteDetails(moduleName: $moduleName, quoteId: $quoteId) {
      id
      isPO
      client {
        id
        profilePicture
        firstName
        lastName
      }
      unitPrice
      module {
        title
        description
        instruction
        id
        words
        unit {
          id
          name
        }
        industry {
          id
          name
        }
        sourceLanguage {
          id
          name
          code
        }
        targetLanguages {
          id
          name
          code
        }
        createdAt
        deadline
      }
      moduleTl {
        id
        name
        code
      }
      moduleServices {
        id
        swId
        name
        isMatchRate
      }
      analysis {
        id
        new
        fifty_seventyFour
        seventyFive_ninetyEight
        eightyFive_NinetyFour
        ninetyFive_NinetyNine
        hundred
        repetition
        crossFileRepetitions
        langCode
      }
      proposalSent
      createdAt
      quoteResponseId
    }
  }
`;

const createVendorQuote = gql`
  mutation createVendorQuote(
    $taskIds: String
    $clientQuoteId: Int!
    $totalAmount: Float!
    $currency: String
    $discountedAmount: Float!
    $taxAmount: Float!
    $balance: Float!
    $description: String!
    $services: String!
    $taxPercentage: Float
    $discountPercentage: Float
    $matchRateServices: String
    $isDraft: Int
  ) {
    createVendorQuote(
      taskIds: $taskIds
      clientQuoteId: $clientQuoteId
      totalAmount: $totalAmount
      currency: $currency
      discountedAmount: $discountedAmount
      taxAmount: $taxAmount
      balance: $balance
      description: $description
      services: $services
      taxPercentage: $taxPercentage
      discountPercentage: $discountPercentage
      matchRateServices: $matchRateServices
      isDraft: $isDraft
    )
  }
`;

const unitSelectArr = [
  { id: 1, name: "Words" },
  { id: 2, name: "Minutes" },
  { id: 3, name: "Pages" },
  { id: 4, name: "Match Rates" },
];

export default function SendQuote(props: any) {
  const createCustomerPO: any = React.useContext(CreateCustomerPOContext);
  const classes = useStyles({});
  const dispatch = useDispatch();
  const intl = useIntl();

  const [jobQuoteDetails, setJobQuoteDetails]: any = useState();
  // const [staticFormData, setStaticFormData] = useState();
  const [matchRateId, setMatchRateId] = useState("");
  // const [sendProposal, setSendProposal] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [serviceIndex, setServiceIndex]: any = useState("");
  const [selectedService, setSelectedService]: any = useState({});
  const [showAddMatchRateModal, setShowAddMatchRateModal] = useState(false);
  const [servicesMatchRate, setServicesMatchRate]: any = useState([]);
  const [jobQuoteAnalysis, setJobQuoteAnalysis]: any = useState();
  const [quoteAnalysis, setQuoteAnalysis]: any = useState({});
  const [isEdit, setIsEdit]: any = useState(0);
  const [isDraft, setIsDraft]: any = useState(0);
  const [quoteResponseId, setQuoteResponseId]: any = useState();
  const [draftDetail, setDraftDetail]: any = useState();
  const [serviceFormData, setServiceFormData]: any = useState([]);
  // const [decodedInstructions, setDecodedInstructions]: any = useState('');
  const [quoteRequestId, setQuoteRequestId]: any = useState(0);
  const [selectedProjectId, setSelectedProjectId]: any = useState(null);
  const [selectedTaskId, setSelectedTaskId]: any = useState(null);
  const SelectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
  const organizations = useSelector((store: any) => store.orgs.organizations);
  const selectedOrganization: any = organizations?.find((org: any) => org.id === +SelectedOrgId);
  // const currentUser = useSelector((store: any) => store.auth.currentUser);
  const currencies = process.env.REACT_APP_CURRENCIES && JSON.parse(process.env.REACT_APP_CURRENCIES) || [];




  // let quoteRequestId:any = 0;


  useEffect(() => {
    if (props.selectedTaskId) {
      setSelectedProjectId(props.selectedProjectId);
    }
    if (props.selectedTaskId) {
      setSelectedTaskId(props.selectedTaskId);
    }
  }, [props.selectedProjectId, props.selectedTaskId]);

  useEffect(() => {
    // alert(props.quoteId);
    if (typeof props.quoteId != "undefined") {
      setQuoteRequestId(props.quoteId);
      // alert(quoteRequestId);
      getJobQuoteDetails({
        variables: { moduleName: 'job', quoteId: props.quoteId }, // Pass quoteId here
      });
    } else if (typeof props.quoteResponseId != "undefined") {
      setQuoteRequestId(props.quoteResponseId);
      // alert(quoteRequestId);
      getJobQuoteDetails({
        variables: { moduleName: 'job', quoteId: props.quoteResponseId }, // Pass quoteId here
      });

    } else {
      let quote_requestId = window.location.href.split('/').pop();
      if (quote_requestId) {
        setQuoteRequestId(quote_requestId);
        getJobQuoteDetails({
          variables: { moduleName: 'job', quoteId: quote_requestId }, // Pass quoteId here
        });
      }
    }

  }, []);

  const [createQuote, { loading: createLoading }] = useMutation(
    createVendorQuote,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        // setSendProposal(1);
        if (
          jobQuoteDetails &&
          !jobQuoteDetails.isPO &&
          typeof props.getAllJobs != "undefined"
        ) {
          props.getAllJobs();
        }

        if (jobQuoteDetails && jobQuoteDetails.isPO) {
          props.setSendPOOpenModal(false);
          // if(typeof props.setIsJobDetailDrawerNew != "undefined") {
          // props.setIsJobDetailDrawerNew(false);
          // }
          // props.getProjectData();
          // props.history.push('projects/jobs/'+jobQuoteDetails.projectId);
        } else if (props.setSendDrawer) {
          props.setSendDrawer(false);
          props.getAllJobs();
        } else {
          props.history.push("/vendor/open-jobs");
        }

        if (typeof props.getProjectData != "undefined") {
          props.getProjectData();
        }
        if (typeof props.getMyTasksData != "undefined") {
          props.getMyTasksData();
        }
        dispatch(
          showAlert({
            message:
              jobQuoteDetails.isPO == 1
                ? "PO sent successfully"
                : isDraft == 1
                  ? "Draft saved successfully"
                  : "Quote sent successfully",
            messageType: "success",
            showAlertMessage: true,
          })
        );
      },
    }
  );

  useEffect(() => {
    const updatedServices: any = [];
    if (languages && languages.length > 0) {
      if (
        draftDetail &&
        draftDetail.quoteServices &&
        draftDetail.quoteServices.length > 0
      ) {
        draftDetail.quoteServices.forEach((element: any) => {
          updatedServices.push({
            service: jobQuoteDetails.moduleServices.find(
              (ser: any) => ser.name === element.serviceName
            ),
            targetLanguages: element.targetLanguages,
            number: element.words,
            unit: unitSelectArr.find(
              (un: any) => un.name.toLowerCase() === element.unit.toLowerCase()
            ),
            price: Number(element.unitPrice),
            subTotal: Number(element.subTotal),
            matchRateId: element.matchRate
              ? Number(element.matchRate.id)
              : null,
            matchRateObj: element.matchRate ? Number(element.matchRate) : null,
          });
        });
      } else {
        const selectedServiceUnit: any = "";
        // if (jobQuoteDetails.moduleServices) {
        //     for (let i = 0; i < jobQuoteDetails.moduleServices.length; i++) {
        //         if(jobQuoteDetails.moduleServices[i].isMatchRate == 1) {
        //             selectedServiceUnit =  {id: 1, name: 'Words'};
        //         }
        //     }
        // }

        // alert(JSON.stringify(selectedService));

        updatedServices.push({
          service: "",
          targetLanguages:
            languages && languages.length === 1 ? [languages[0]] : [],
          number: "",
          unit: selectedServiceUnit,
          price: "",
          subTotal: "",
          matchRateId: null,
          matchRateObj: null,
        });
      }

      // alert(JSON.stringify(updatedServices));
      setServiceFormData(updatedServices);
    }
  }, [draftDetail, languages]);

  useEffect(() => {
    if (showAddMatchRateModal) {
      props.setIsFullWidth(true);
    } else {
      props.setIsFullWidth(false);
    }
  }, [showAddMatchRateModal]);

  const [getDraftData, { loading: isLoadingDraftData }] = useLazyQuery(
    QUOTE_RESPONSE_DETAILS,
    {
      variables: { moduleName: "job", quoteResponseId: quoteResponseId },
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        setDraftDetail(data && data.quoteResponseDetails);
      },
    }
  );


  const [getJobQuoteDetails, { loading: jobDetailLoading }] = useLazyQuery(
    JOB_QUOTE_DETAILS,
    {
      // variables: { moduleName: "job", quoteId: quoteRequestId },
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        // setLanguages(data && data.quoteDetails.module.targetLanguages)
        if (data && data?.quoteDetails?.moduleTl) {
          setLanguages(data.quoteDetails.moduleTl);
        }
        if (data && data?.quoteDetails?.module?.targetLanguages?.length == 1) {
          setQuoteAnalysis(data.quoteDetails.analysis);
        } else {
          setQuoteAnalysis("");
        }
        if (data && data?.quoteDetails?.analysis) {
          setJobQuoteAnalysis(data.quoteDetails.analysis);
        }
        if (data && data?.quoteDetails) {
          setJobQuoteDetails(data.quoteDetails);
        }
        if (data.quoteDetails.module && data.quoteDetails.module.title) {
          dispatch(
            selectedVendorJobName(
              data.quoteDetails.module.title
            )
          );
        }

        const servicesArr: any = [];
        if (data?.quoteDetails?.unitPrice && data?.quoteDetails?.module?.words) {
          for (let i = 0; i < data?.quoteDetails?.moduleServices?.length; i++) {
            servicesArr.push({
              service: data.quoteDetails.moduleServices[i],
              targetLanguages: data.quoteDetails.module.targetLanguages,
              number: data.quoteDetails.module.words,
              unit: data.quoteDetails.module.unit,
              price: data.quoteDetails.unitPrice,
              subTotal:
                parseFloat(data.quoteDetails.unitPrice) *
                parseFloat(data.quoteDetails.module.words),
            });
          }
        }

        // setStaticFormData(servicesArr);
        if (data?.quoteDetails?.quoteResponseId) {
          setQuoteResponseId(Number(data.quoteDetails.quoteResponseId));
        }

        if (
          (data.quoteDetails.proposalSent === 2 &&
            Number(data.quoteDetails.quoteResponseId) !== 0) && quoteResponseId
        ) {
          getDraftData();
        }
      },
    } as any
  );

  const calculateTotalPrice = (values: any) => {
    let total = 0;

    values.serviceForms.forEach((row: any, index: any) => {
      const matchRateValue = getServiceTotalAmount(index);
      if (matchRateValue != 0) {
        total += Number(matchRateValue);
      } else {
        total += Number(row.number) * Number(row.price);
      }
    });

    return Number(total).toFixed(2);
  };

  const calculateTotalPriceAfterDiscount = (values: any) => {
    let total: any;

    total = calculateTotalPrice(values);

    if (values && total && values.discount != "" && values.discount != 0) {
      total = Number(total) - total * (Number(values.discount) / 100);
    }

    return Number(total).toFixed(2);
  };

  const calculateTotalPriceAfterTax = (values: any) => {
    let total: any;

    total = calculateTotalPriceAfterDiscount(values);

    if (
      values &&
      total &&
      typeof values.taxPercent != "undefined" &&
      values.taxPercent != "" &&
      values.taxPercent != 0
    ) {
      total = Number(total) + total * (Number(values.taxPercent) / 100);
    }

    return Number(total).toFixed(2);
  };

  const getServiceTotalWords = (index: any) => {
    let words = 0;
    servicesMatchRate.forEach((row: any) => {
      if (row.index == index && row.totalWords) {
        words = row.totalWords;
      }
    });
    return words;
  };

  const getServiceTotalAmount = (index: any) => {
    let amt = 0;
    servicesMatchRate.forEach((row: any) => {
      if (row.index == index && row.totalAmount) {
        amt = row.totalAmount;
      }
    });

    return amt;
  };

  const removeMatchRateService = (index: any) => {
    const servicesMatchRateArr: any = [];
    servicesMatchRate.forEach((row: any) => {
      if (row.index != index) {
        servicesMatchRateArr.push(row);
      }
    });

    setServicesMatchRate(servicesMatchRateArr);
  };



  return (
    <div className="projectRatesComponent">
      <MBox>
        <MGrid container>
          <MGrid item xs={12} pt={2} px={4.5}>
            {jobQuoteDetails &&
              jobQuoteDetails.moduleServices &&
              !isLoadingDraftData ? (
              <FormattedMessage id="required.file">
                {(requiredField) => (
                  <MFormik
                    enableReinitialize="true"
                    initialValues={{
                      desc:
                        draftDetail && draftDetail.description
                          ? draftDetail.description
                          : "",
                      name: "",
                      serviceForms: serviceFormData, // staticFormData && staticFormData,
                      currency: selectedOrganization && selectedOrganization.currency ? currencies?.find((curr: any) => curr.code === selectedOrganization?.currency) : "",
                      discount:
                        draftDetail && draftDetail.discountPercentage
                          ? draftDetail.discountPercentage
                          : 0,
                      taxPercent:
                        draftDetail && draftDetail.taxPercentage
                          ? draftDetail.taxPercentage
                          : 0,
                    }}
                    validationSchema={Yup.object({
                      serviceForms: Yup.array().of(
                        Yup.object().shape({
                          service: Yup.mixed()
                            .test(
                              'services',
                              String(requiredField),
                              (value: any) => {
                                if (!value) return false; // Value is required
                                if (typeof value === 'string') {
                                  return true;
                                }
                                if (typeof value === 'object') {
                                  return true;
                                }
                                return false; // Invalid if neither condition matches
                              }
                            )
                            .required(String(requiredField)), //Yup.string().required(String(requiredField)),
                          targetLanguages: Yup.array().min(
                            1,
                            String(requiredField)
                          ),
                          price: Yup.string()
                            .required(String(requiredField))
                            .matches(
                              /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/,
                              "Enter greater than 0"
                            ),
                          number: Yup.string()
                            .required(String(requiredField))
                            .matches(
                              /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/,
                              "Enter greater than 0"
                            ),
                          unit: Yup.mixed()
                            .test(
                              'unit',
                              String(requiredField),
                              (value: any) => {
                                if (!value) return false; // Value is required
                                if (typeof value === 'string') {
                                  return true;
                                }
                                if (typeof value === 'object') {
                                  return true;
                                }
                                return false; // Invalid if neither condition matches
                              }
                            )
                            .required(String(requiredField)),
                        })
                      ),
                      taxPercent: Yup.number()
                        .min(0, "Enter minimum 0")
                        .max(100, "Enter maximum 100"),
                      discount: Yup.number()
                        .min(0, "Enter minimum 0")
                        .max(99, "Enter maximum 99"),
                    })}
                    onSubmit={(values: any) => {
                      const servicesArr = [];
                      for (
                        let index = 0;
                        index < values.serviceForms.length;
                        index++
                      ) {
                        const tlIds = [];

                        for (
                          let i = 0;
                          i < values.serviceForms[index].targetLanguages.length;
                          i++
                        ) {
                          tlIds.push(
                            values.serviceForms[index].targetLanguages[i].code
                          );
                        }

                        servicesArr.push({
                          quantity: values.serviceForms[index].number,
                          subTotal:
                            Number(values.serviceForms[index].number) *
                            Number(values.serviceForms[index].price),
                          unit: values.serviceForms[index].unit.id,
                          unitPrice: Number(values.serviceForms[index].price),
                          // tlIds: values.serviceForms[index].targetLanguages.join(','),
                          tlIds: tlIds.join(","),
                          matchRateId: values.serviceForms[index].matchRateId,
                          service_id: values.serviceForms[index].service.swId,
                        });
                      }

                      const totalAmt = calculateTotalPrice(values);
                      const discountedAmt =
                        calculateTotalPriceAfterDiscount(values);
                      const taxAmt = calculateTotalPriceAfterTax(values);

                      const newServiceRate = [];

                      for (let i = 0; i < servicesMatchRate.length; i++) {
                        if (
                          servicesMatchRate[i] &&
                          typeof servicesMatchRate[i].values != "undefined" &&
                          servicesMatchRate[i].values.words
                        ) {
                          const selectedServicePrice =
                            servicesMatchRate[i].selectedService.price;
                          newServiceRate.push({
                            index: servicesMatchRate[i].index,
                            values: {
                              totalWords: servicesMatchRate[i].totalWords,
                              totalAmount: servicesMatchRate[i].totalAmount,
                              new: {
                                word: servicesMatchRate[i].values.words[0],
                                ratePercentage:
                                  servicesMatchRate[i].values.percentOfRate[0],
                                rateValue:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[0] /
                                    100),
                                subTotal:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[0] /
                                    100) *
                                  servicesMatchRate[i].values.words[0],
                              },
                              fifty_seventyFour: {
                                word: servicesMatchRate[i].values.words[1],
                                ratePercentage:
                                  servicesMatchRate[i].values.percentOfRate[1],
                                rateValue:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[1] /
                                    100),
                                subTotal:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[1] /
                                    100) *
                                  servicesMatchRate[i].values.words[1],
                              },
                              seventyFive_ninetyEight: {
                                word: servicesMatchRate[i].values.words[2],
                                ratePercentage:
                                  servicesMatchRate[i].values.percentOfRate[2],
                                rateValue:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[2] /
                                    100),
                                subTotal:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[2] /
                                    100) *
                                  servicesMatchRate[i].values.words[2],
                              },
                              eightyFive_NinetyFour: {
                                word: servicesMatchRate[i].values.words[3],
                                ratePercentage:
                                  servicesMatchRate[i].values.percentOfRate[3],
                                rateValue:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[3] /
                                    100),
                                subTotal:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[3] /
                                    100) *
                                  servicesMatchRate[i].values.words[3],
                              },
                              ninetyFive_NinetyNine: {
                                word: servicesMatchRate[i].values.words[4],
                                ratePercentage:
                                  servicesMatchRate[i].values.percentOfRate[4],
                                rateValue:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[4] /
                                    100),
                                subTotal:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[4] /
                                    100) *
                                  servicesMatchRate[i].values.words[4],
                              },
                              hundred: {
                                word: servicesMatchRate[i].values.words[5],
                                ratePercentage:
                                  servicesMatchRate[i].values.percentOfRate[5],
                                rateValue:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[5] /
                                    100),
                                subTotal:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[5] /
                                    100) *
                                  servicesMatchRate[i].values.words[5],
                              },
                              repetition: {
                                word: servicesMatchRate[i].values.words[6],
                                ratePercentage:
                                  servicesMatchRate[i].values.percentOfRate[6],
                                rateValue:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[6] /
                                    100),
                                subTotal:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[6] /
                                    100) *
                                  servicesMatchRate[i].values.words[6],
                              },
                              crossFileRepetitions: {
                                word: servicesMatchRate[i].values.words[7],
                                ratePercentage:
                                  servicesMatchRate[i].values.percentOfRate[7],
                                rateValue:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[7] /
                                    100),
                                subTotal:
                                  selectedServicePrice *
                                  (servicesMatchRate[i].values
                                    .percentOfRate[7] /
                                    100) *
                                  servicesMatchRate[i].values.words[7],
                              },
                            },
                          });
                        }
                      }

                      createQuote({
                        variables: {
                          taskIds:
                            props.selectedTaskId &&
                              props.selectedTaskId.length > 0
                              ? props.selectedTaskId
                                .map(function (a: any) {
                                  return a.id;
                                })
                                .join(",")
                              : "",
                          clientQuoteId: quoteRequestId
                            ? parseInt(quoteRequestId)
                            : 0,
                          totalAmount: parseInt(totalAmt),
                          currency: values.currency?.code,
                          discountedAmount: parseFloat(discountedAmt),
                          taxAmount: parseFloat(taxAmt),
                          balance: parseFloat(taxAmt),
                          isDraft: isDraft,
                          discountPercentage: values.discount
                            ? values.discount
                            : 0,
                          taxPercentage: values.taxPercent
                            ? values.taxPercent
                            : 0,
                          description: values.desc ? values.desc : "",
                          services: JSON.stringify(servicesArr),
                          matchRateServices: JSON.stringify(newServiceRate),
                          matchRateId: matchRateId,
                        },
                      });
                    }}
                  >
                    {({ errors, touched, setFieldValue, values }: any) => {
                      return (
                        <MForm>
                          <MBox
                            color="palette.secondary"
                            className={classes.contentBox}
                            pt={2}
                            pb={9}
                          >
                            <MBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              mb={1}
                            >
                              <MBox
                                fontFamily="Poppins"
                                fontSize={24}
                                color="#393939"
                                mb={1}
                              >
                                {jobQuoteDetails.isPO ? (
                                  <>
                                    <FormattedMessage id="purchase.order" /> #{" "}
                                    {jobQuoteDetails && jobQuoteDetails.id}
                                  </>
                                ) : (
                                  ""
                                )}
                              </MBox>
                              <MBox display="flex">
                                <MBox display="flex" mr={0}>
                                  <MBox
                                    sx={{
                                      color: "#a0a5b9",
                                    }}
                                  >
                                    <FormattedMessage id="date.issue" />:
                                  </MBox>
                                  &nbsp;
                                  <MBox>
                                    {jobDetailLoading ? (
                                      <MSkeleton width={71} height={18} />
                                    ) : (
                                      <FormattedDateParts
                                        value={
                                          jobQuoteDetails &&
                                          jobQuoteDetails.module &&
                                          jobQuoteDetails.module.createdAt
                                        }
                                        year="numeric"
                                        month="2-digit"
                                        day="2-digit"
                                      >
                                        {(parts) => (
                                          <>
                                            {parts[2].value}
                                            {parts[1].value}
                                            {parts[0].value}
                                            {parts[3].value}
                                            {parts[4].value}
                                          </>
                                        )}
                                      </FormattedDateParts>
                                    )}
                                  </MBox>
                                </MBox>
                              </MBox>
                            </MBox>

                            <MBox display="flex" alignItems="center">
                              <MBox display="flex" alignItems="center">
                                <MBox mr={1.75} ml={1.75}>
                                  {jobQuoteDetails &&
                                    jobQuoteDetails.client &&
                                    jobQuoteDetails.client.profilePicture ? (
                                    <MAvatar
                                      src={
                                        process.env.REACT_APP_PUBLIC_URL +
                                        "/" +
                                        jobQuoteDetails.client.profilePicture
                                      }
                                      alt="logo"
                                      width="28"
                                      height="28"
                                      sx={{ borderRadius: "50%" }}
                                    />
                                  ) : (
                                    <MAvatar sx={{ width: 35, height: 35 }}>
                                      {jobQuoteDetails &&
                                        avatarPlaceholder(
                                          jobQuoteDetails.client.firstName
                                        )}
                                    </MAvatar>
                                  )}
                                </MBox>
                                <MBox
                                  color="palette.secondary"
                                  maxWidth="737px"
                                >
                                  {jobQuoteDetails &&
                                    jobQuoteDetails.client &&
                                    jobQuoteDetails.client.firstName}
                                </MBox>
                              </MBox>
                            </MBox>
                            {jobQuoteDetails &&
                              jobQuoteDetails.module &&
                              jobQuoteDetails.module.instruction && (
                                <MBox
                                  className={classes.instructionWrap}
                                  mt={2}
                                  mr={1.75}
                                  ml={1.75}
                                  color="palette.secondary"
                                >
                                  <MBox
                                    fontSize={14}
                                    color="#3a3a3a"
                                    fontFamily="Poppins"
                                    mb={1.5}
                                  >
                                    <FormattedMessage id="instruction" />:
                                  </MBox>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        jobQuoteDetails.module.instruction,
                                    }}
                                  />
                                </MBox>
                              )}
                            <MBox display="flex" alignItems="center" justifyContent="flex-end" color="palette.secondary" mt={2}>
                              <MBox>
                                <FormattedMessage id="select.default.currency">
                                  {currency =>
                                    <MAutoComplete
                                      className={classes.taskList}
                                      placeholder={String(currency)}
                                      name="currency"
                                      id="currency"
                                      sx={{ width: 270 }}
                                      disableClearable={true}
                                      value={values.currency || currencies[0]}
                                      defaultValue={values.currency ? values.currency?.name + ' (' + values.currency?.symbol + ')' : currencies[0]?.name + ' (' + currencies[0]?.symbol + ')'}
                                      options={currencies}
                                      getOptionLabel={(option: any) => option?.name + ' (' + option?.symbol + ')'}
                                      onChange={(event: any, value: any) => {
                                        setFieldValue('currency', value ? value : currencies[0])
                                      }}
                                      renderInput={(params: any) => (
                                        <MTextField
                                          {...params}
                                          variant="outlined"
                                          placeholder={String(currency)}
                                          label={intl.formatMessage({ id: "currency" })}
                                        />
                                      )}
                                    />
                                  }
                                </FormattedMessage>
                              </MBox>
                            </MBox>
                            <MBox className={classes.hr} my={5}></MBox>

                            {typeof props.isCustomerPO != "undefined" &&
                              props.isCustomerPO && (
                                <MBox display="flex" ml={2} mb={2}>
                                  <MBox>
                                    <MFormLabel
                                      sx={{ marginBottom: "5px" }}
                                      className={classes.formLabel}
                                      component="legend"
                                      required
                                    >
                                      {" "}
                                      Select Project
                                    </MFormLabel>

                                    <Autocomplete
                                      size="small"
                                      id="checkboxes-tags-demo"
                                      className={classes.taskList}
                                      sx={{ width: 270 }}
                                      options={props.projects}
                                      value={
                                        props.selectedProjectId
                                          ? {
                                            id: props.selectedProjectId,
                                            name: props.projects.find(
                                              (element: any) =>
                                                element.id ==
                                                props.selectedProjectId
                                            ).name,
                                          }
                                          : null
                                      }
                                      disableCloseOnSelect
                                      getOptionLabel={(option: any) =>
                                        option.name
                                      }
                                      onChange={(event: any, value: any) => {
                                        props.setSelectedProjectId(
                                          value ? value.id : 0
                                        );
                                        props.setSelectedTaskId([]);
                                      }}
                                      renderOption={(props: object, option: any) => (
                                        // <MBox className={classes.radioBtn}>
                                        //   <MBox>
                                        <div {...props}>
                                          <MFormControlLabel
                                            value={option.name}
                                            control={
                                              <MRadio
                                                checked={
                                                  selectedProjectId &&
                                                    selectedProjectId == option.id
                                                    ? true
                                                    : false
                                                }
                                                className={classes.radioOptions}
                                              />
                                            }
                                            label={option.name}
                                          />
                                        </div>
                                        //  </MBox>
                                        // <MBox>#{option.id}</MBox>
                                        // </MBox>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          placeholder="Select Project"
                                        />
                                      )}
                                    />
                                  </MBox>
                                  <MBox ml={2}>
                                    <MFormLabel
                                      sx={{ marginBottom: "5px" }}
                                      className={classes.formLabel}
                                      component="legend"
                                      required
                                    >
                                      <FormattedMessage id="select.task" />
                                    </MFormLabel>
                                    <Autocomplete
                                      multiple
                                      size="small"
                                      id="checkboxes-tags-demo"
                                      className={classes.taskList}
                                      options={props.tasks}
                                      disableCloseOnSelect
                                      value={
                                        props.selectedTaskId
                                          ? props.selectedTaskId
                                          : []
                                      }
                                      // value={[{id:'',name:''}]}
                                      getOptionLabel={(option: any) =>
                                        option.name
                                      }
                                      onChange={(event: any, value: any) => {
                                        props.setSelectedTaskId(
                                          value ? value : []
                                        );

                                        if (
                                          createCustomerPO &&
                                          typeof createCustomerPO.createCustomerPO ==
                                          "function"
                                        ) {
                                          const taskIds = value.map(function (
                                            a: any
                                          ) {
                                            return a.id;
                                          });
                                          createCustomerPO.createCustomerPO({
                                            variables: {
                                              projectId: String(
                                                props.selectedProjectId
                                              ),
                                              jobIds: taskIds.join(","),
                                              quoteId: Number(props.quoteId),
                                            },
                                          });
                                        }
                                      }}
                                      renderOption={(props: object, option: any) => (
                                        // <MBox className={classes.radioBtn}>
                                        //   <MBox>
                                        <div {...props}>
                                          <MFormControlLabel
                                            value={option ? option.name : ""}
                                            control={
                                              <MRadio
                                                checked={
                                                  selectedTaskId?.length && selectedTaskId.map(
                                                    (object: any) =>
                                                      object.id
                                                  )
                                                    .indexOf(option.id) >= 0
                                                    ? true
                                                    : false
                                                }
                                                className={classes.radioOptions}
                                              />
                                            }
                                            label={option ? option.name : ""}
                                          />
                                        </div>
                                        // </MBox>
                                        // <MBox>
                                        //   #{option ? option.id : ""}
                                        // </MBox>
                                        // </MBox>
                                      )}
                                      sx={{ width: 270 }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          placeholder="Select Task"
                                        />
                                      )}
                                    />
                                  </MBox>
                                </MBox>
                              )}

                            <MBox mb={4} pl={2} display="flex" width="44%">
                              <MBox flex="1 1 0">
                                <MBox
                                  mb={1.8}
                                  className={classes.greyUppercaseText}
                                >
                                  <FormattedMessage id="source.language" />
                                </MBox>
                                <MBox>
                                  {jobQuoteDetails &&
                                    jobQuoteDetails.module &&
                                    jobQuoteDetails.module.sourceLanguage.name}
                                </MBox>
                              </MBox>
                              <MBox flex="1 1 0">
                                <MBox
                                  mb={1.8}
                                  className={classes.greyUppercaseText}
                                >
                                  <FormattedMessage id="subject" />
                                </MBox>
                                <MBox>
                                  {jobQuoteDetails &&
                                    jobQuoteDetails.module &&
                                    jobQuoteDetails.module.industry &&
                                    jobQuoteDetails.module.industry.name}
                                </MBox>
                              </MBox>
                            </MBox>
                            <MBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              className="gap-5"
                              p={1.8}
                            >
                              <MBox
                                flex="2 1 0"
                                className={classes.greyUppercaseText}
                              >
                                <MFormLabel
                                  className={classes.formLabel}
                                  component="legend"
                                  required
                                >
                                  <FormattedMessage id="services" />
                                </MFormLabel>
                              </MBox>
                              <MBox
                                flex="2 1 0"
                                className={classes.greyUppercaseText}
                              >
                                <MFormLabel
                                  className={classes.formLabel}
                                  component="legend"
                                  required
                                >
                                  <FormattedMessage id="target.languages" />
                                </MFormLabel>
                              </MBox>
                              <MBox
                                flex="1 1 0"
                                textAlign="left"
                                className={classes.greyUppercaseText}
                              >
                                <MFormLabel
                                  className={classes.formLabel}
                                  component="legend"
                                  required
                                >
                                  <FormattedMessage id="select.unit" />
                                </MFormLabel>
                              </MBox>
                              <MBox
                                flex="1 1 0"
                                className={classes.greyUppercaseText}
                              >
                                <MFormLabel
                                  className={classes.formLabel}
                                  component="legend"
                                  required
                                >
                                  <FormattedMessage id="qty" />
                                </MFormLabel>
                              </MBox>
                              <MBox flex="1 1 0" pr="5px">
                                <MFormLabel
                                  className={classes.formLabel}
                                  component="legend"
                                  required
                                >
                                  <FormattedMessage id="unit.price" /> ({values.currency?.symbol})
                                </MFormLabel>
                              </MBox>
                              {/* <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center"> */}

                              <MBox
                                flex="2 1 0"
                                display="flex"
                                justifyContent="center"
                                className={classes.greyUppercaseText}
                              >
                                <FormattedMessage id="subtotal" />
                                &nbsp; &nbsp;
                              </MBox>
                            </MBox>
                            <FieldArray
                              name="serviceForms"
                              render={(arrayHelper) => (
                                <>
                                  {values.serviceForms &&
                                    values.serviceForms.map(
                                      (formValue: any, index: number) => (
                                        <MBox
                                          key={index}
                                          display="flex"
                                          position="relative"
                                          justifyContent="space-between"
                                          sx={{ marginBottom: 2 }}
                                          className={`${classes.bgLightGrey
                                            } ${"gap-10"}`}
                                        >
                                          <MBox flex="2 1 0">
                                            <FormattedMessage id="select.service">
                                              {(selectService) => (
                                                <MAutoComplete
                                                  id="services"
                                                  size="medium"
                                                  className={
                                                    classes.autoCompleteSizeSm
                                                  }
                                                  defaultValue={
                                                    values.serviceForms[index]
                                                      .service
                                                  }
                                                  value={
                                                    values.serviceForms[index]
                                                      .service != ""
                                                      ? values.serviceForms[
                                                        index
                                                      ].service
                                                      : null
                                                  }
                                                  options={
                                                    jobQuoteDetails.moduleServices
                                                  }
                                                  getOptionLabel={(
                                                    option: any
                                                  ) => option.name}
                                                  placeholder={String(
                                                    selectService
                                                  )}
                                                  onChange={(
                                                    event: any,
                                                    value: any
                                                  ) => {
                                                    if (value === null) {
                                                      const updatedArray =
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            values.serviceForms
                                                          )
                                                        );
                                                      updatedArray[
                                                        index
                                                      ].service.name = "";

                                                      setFieldValue(
                                                        `serviceForms`,
                                                        updatedArray
                                                      );
                                                    } else {
                                                      let alreadyPaired: any =
                                                        false;
                                                      let alreadyFoundLang: any;
                                                      if (
                                                        values.serviceForms[
                                                          index
                                                        ].targetLanguages
                                                          .length > 0
                                                      ) {
                                                        values.serviceForms[
                                                          index
                                                        ].targetLanguages.find(
                                                          (lang: any) => {
                                                            values.serviceForms.find(
                                                              (
                                                                obj: any,
                                                                i: number
                                                              ) => {
                                                                if (
                                                                  i !== index &&
                                                                  (obj.service !==
                                                                    "" &&
                                                                    obj.service
                                                                      .name) ===
                                                                  (value.name &&
                                                                    value.name) &&
                                                                  obj.targetLanguages.find(
                                                                    (
                                                                      sLang: any
                                                                    ) =>
                                                                      sLang.id ===
                                                                      lang.id
                                                                  )
                                                                ) {
                                                                  alreadyPaired =
                                                                    true;
                                                                  alreadyFoundLang =
                                                                    languages.find(
                                                                      (
                                                                        l: any
                                                                      ) =>
                                                                        l.id ===
                                                                        Number(
                                                                          lang.id
                                                                        )
                                                                    );
                                                                }
                                                              }
                                                            );
                                                          }
                                                        );
                                                      }

                                                      if (
                                                        alreadyPaired === true
                                                      ) {
                                                        alert(
                                                          alreadyFoundLang.name +
                                                          " already paired with " +
                                                          value.name
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `serviceForms.${index}.service`,
                                                          value
                                                        );

                                                        if (
                                                          value &&
                                                          value.isMatchRate
                                                        ) {
                                                          setFieldValue(
                                                            `serviceForms.${index}.unit`,
                                                            {
                                                              id: 1,
                                                              name: "Words",
                                                            }
                                                          );
                                                        }
                                                      }
                                                    }
                                                  }}
                                                />
                                              )}
                                            </FormattedMessage>
                                            <FormErrorMessage
                                              message={
                                                touched.serviceForms &&
                                                  touched.serviceForms[index] &&
                                                  touched.serviceForms[index]
                                                    .service &&
                                                  touched.serviceForms[index]
                                                    .service
                                                  ? errors.serviceForms &&
                                                  errors.serviceForms[
                                                  index
                                                  ] &&
                                                  errors.serviceForms[index]
                                                    .service &&
                                                  errors.serviceForms[index]
                                                    .service
                                                  : ""
                                              }
                                            />
                                          </MBox>
                                          <MBox flex="2 1 0">
                                            <FormattedMessage id="project.create.select.languages">
                                              {(selectLang) => (
                                                <MAutoComplete
                                                  id="target_lang"
                                                  size="medium"
                                                  className={
                                                    classes.autoCompleteSizeSm
                                                  }
                                                  // size={size === 'medium' ? 'medium' : 'small'}
                                                  // size='medium'
                                                  // multiple
                                                  disabled={
                                                    values.serviceForms[
                                                      index
                                                    ] &&
                                                      values.serviceForms[index]
                                                        .service === ""
                                                      ? true
                                                      : false
                                                  }
                                                  filterSelectedOptions
                                                  options={
                                                    languages && languages
                                                  }
                                                  defaultValue={
                                                    values.serviceForms[index]
                                                      .targetLanguages[0]
                                                  }
                                                  getOptionLabel={(
                                                    option: any
                                                  ) =>
                                                    `${option.name} ${option.code}`
                                                  }
                                                  // className={classes.autoCompleteSizeSm}
                                                  // disableClearable={true}
                                                  placeholder={String(
                                                    selectLang
                                                  )}
                                                  limitTags={2}
                                                  onChange={(
                                                    event: any,
                                                    value: any
                                                  ) => {
                                                    const arrVal = [value];
                                                    let alreadyPaired: any =
                                                      false;
                                                    let alreadyFoundLang: any;
                                                    if (
                                                      values.serviceForms[index]
                                                        .service !== ""
                                                    ) {
                                                      arrVal.find(
                                                        (lang: any) => {
                                                          values.serviceForms.find(
                                                            (
                                                              obj: any,
                                                              i: number
                                                            ) => {
                                                              if (
                                                                i !== index &&
                                                                obj.service
                                                                  .swId &&
                                                                obj.service
                                                                  .name ===
                                                                (values
                                                                  .serviceForms[
                                                                  index
                                                                ].service
                                                                  .name &&
                                                                  values
                                                                    .serviceForms[
                                                                    index
                                                                  ].service
                                                                    .name) &&
                                                                obj.targetLanguages.find(
                                                                  (
                                                                    sLang: any
                                                                  ) =>
                                                                    sLang.id ===
                                                                    lang.id
                                                                )
                                                              ) {
                                                                alreadyPaired =
                                                                  true;
                                                                alreadyFoundLang =
                                                                  languages.find(
                                                                    (l: any) =>
                                                                      l.id ===
                                                                      Number(
                                                                        lang.id
                                                                      )
                                                                  );
                                                              }
                                                            }
                                                          );
                                                        }
                                                      );
                                                    }
                                                    if (
                                                      alreadyPaired === true
                                                    ) {
                                                      alert(
                                                        alreadyFoundLang.name +
                                                        " already paired with " +
                                                        values.serviceForms[
                                                          index
                                                        ].service.name
                                                      );
                                                      setFieldValue(
                                                        `serviceForms.${index}.targetLanguages`,
                                                        []
                                                      );
                                                    } else {
                                                      setFieldValue(
                                                        `serviceForms.${index}.targetLanguages`,
                                                        arrVal
                                                      );
                                                    }
                                                    const result =
                                                      jobQuoteAnalysis &&
                                                      jobQuoteAnalysis.filter(
                                                        (obj: any) => {
                                                          return (
                                                            obj.langCode ===
                                                            value.code
                                                          );
                                                        }
                                                      );
                                                    setQuoteAnalysis(result);
                                                  }}
                                                />
                                              )}
                                            </FormattedMessage>
                                            <FormErrorMessage
                                              message={
                                                touched.serviceForms &&
                                                  touched.serviceForms[index] &&
                                                  touched.serviceForms[index]
                                                    .targetLanguages &&
                                                  touched.serviceForms[index]
                                                    .targetLanguages
                                                  ? errors.serviceForms &&
                                                  errors.serviceForms[
                                                  index
                                                  ] &&
                                                  errors.serviceForms[index]
                                                    .targetLanguages &&
                                                  errors.serviceForms[index]
                                                    .targetLanguages
                                                  : ""
                                              }
                                            />
                                          </MBox>

                                          <MBox flex="1 1 0">
                                            <UnitSelect
                                              // size="sizeSm"
                                              value={
                                                values.serviceForms[index].unit !== '' ? values.serviceForms[index].unit : null
                                              }
                                              defaultValue={
                                                values.serviceForms[index].unit
                                              }
                                              disabled={
                                                values.serviceForms[index]
                                                  .service === ""
                                                  ? true
                                                  : false
                                                    ||
                                                    values.serviceForms[index].price == 0
                                                    ? true
                                                    : false
                                              }
                                              name={`serviceForms.${index}.unit`}
                                              setFieldValue={setFieldValue}
                                              setServiceIndex={setServiceIndex}
                                              index={index}
                                              // readOnly={
                                              //     getServiceTotalAmount(index) > 0
                                              //         ? true
                                              //         : false
                                              // }
                                              selectedService={
                                                values.serviceForms[index]
                                              }
                                              setSelectedService={
                                                setSelectedService
                                              }
                                              setShowAddMatchRateModal={
                                                setShowAddMatchRateModal
                                              }
                                              setIsEdit={setIsEdit}
                                            />
                                            <FormErrorMessage
                                              message={
                                                touched.serviceForms &&
                                                  touched.serviceForms[index] &&
                                                  touched.serviceForms[index]
                                                    .unit &&
                                                  touched.serviceForms[index].unit
                                                  ? errors.serviceForms &&
                                                  errors.serviceForms[
                                                  index
                                                  ] &&
                                                  errors.serviceForms[index]
                                                    .unit &&
                                                  errors.serviceForms[index]
                                                    .unit
                                                  : ""
                                              }
                                            />
                                          </MBox>

                                          <MBox
                                            flex="1 1 0"
                                            className={
                                              classes.hideerrorcontaine
                                            }
                                          >
                                            {/* {getServiceTotalAmount(index) ==
                                              0 && ( */}
                                            <FormattedMessage id="qty">
                                              {(qty) => (
                                                <MTextField
                                                  name={`serviceForms.${index}.number`}
                                                  variant="outlined"
                                                  type="number"
                                                  value={
                                                    values.serviceForms[
                                                      index
                                                    ].number
                                                  }
                                                  color="secondary"
                                                  size="small"
                                                  className={
                                                    classes.smallInput
                                                  }
                                                  sx={{ height: 35 }}
                                                  label={qty}
                                                  fullWidth
                                                  disabled={values.serviceForms[index].unit
                                                    .id === 4}
                                                />
                                              )}
                                            </FormattedMessage>
                                            {/* )} */}

                                            {values.serviceForms[index] &&
                                              values.serviceForms[index].unit
                                                .name == "Match Rate template"
                                              // &&
                                              //   getServiceTotalAmount(index) != 0
                                              &&
                                              servicesMatchRate.length > 0 && (
                                                <MBox
                                                  display="flex"
                                                  alignItems="center"
                                                >
                                                  <MLink
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                      setSelectedService(
                                                        values.serviceForms[
                                                        index
                                                        ]
                                                      );
                                                      setServiceIndex(index);
                                                      setShowAddMatchRateModal(
                                                        true
                                                      );
                                                      setIsEdit(1);
                                                    }}
                                                    color="secondary"
                                                  >
                                                    {getServiceTotalWords(
                                                      index
                                                    )}
                                                  </MLink>

                                                  <MIconButton
                                                    aria-label="remove"
                                                    onClick={() => {
                                                      setFieldValue(
                                                        `serviceForms.${index}.unit`,
                                                        {
                                                          id: 1,
                                                          name: "Words",
                                                        }
                                                      );
                                                      removeMatchRateService(
                                                        index
                                                      );
                                                    }}
                                                    size="small"
                                                  >
                                                    <HighlightOffIcon fontSize="small" />
                                                  </MIconButton>
                                                </MBox>
                                              )}
                                            <FormErrorMessage
                                              message={
                                                touched.serviceForms &&
                                                  touched.serviceForms[index] &&
                                                  touched.serviceForms[index]
                                                    .number &&
                                                  touched.serviceForms[index]
                                                    .number
                                                  ? errors.serviceForms &&
                                                  errors.serviceForms[
                                                  index
                                                  ] &&
                                                  errors.serviceForms[index]
                                                    .number &&
                                                  errors.serviceForms[index]
                                                    .number
                                                  : ""
                                              }
                                            />
                                          </MBox>

                                          <MBox
                                            flex="1 1 0"
                                            className={
                                              classes.hideerrorcontaine
                                            }
                                          >

                                            <MTextField
                                              disabled={
                                                values.serviceForms[
                                                  index
                                                ] &&
                                                  (values.serviceForms[index].targetLanguages.length == 0 || values.serviceForms[index].unit.id == 4) ? true : false


                                              }
                                              name={`serviceForms.${index}.price`}
                                              variant="outlined"
                                              color="secondary"
                                              size="small"
                                              defaultValue={
                                                values.serviceForms[index]
                                                  .price
                                              }
                                              className={classes.smallInput}
                                              // sx={{ width: 0 }}
                                              type="number"
                                              label={intl.formatMessage({ id: "price" })}
                                              inputProps={{
                                                readOnly:
                                                  // getServiceTotalAmount(
                                                  //   index
                                                  // ) > 0
                                                  //   ? true
                                                  //   : false,
                                                  values.serviceForms[index].unit.id === 4
                                              }}
                                              fullWidth
                                            />

                                            <FormErrorMessage
                                              message={
                                                touched.serviceForms &&
                                                  touched.serviceForms[index] &&
                                                  touched.serviceForms[index]
                                                    .price &&
                                                  touched.serviceForms[index]
                                                    .price
                                                  ? errors.serviceForms &&
                                                  errors.serviceForms[
                                                  index
                                                  ] &&
                                                  errors.serviceForms[index]
                                                    .price &&
                                                  errors.serviceForms[index]
                                                    .price
                                                  : ""
                                              }
                                            />
                                          </MBox>

                                          {getServiceTotalAmount(index) > 0 ? (
                                            <MBox
                                              flex="1 1 0"
                                              pt={1}
                                              textAlign="end"
                                            >
                                              {values.currency?.symbol}{getServiceTotalAmount(index)}
                                            </MBox>
                                          ) : (
                                            <MBox
                                              flex="1 1 0"
                                              pt={1}
                                              textAlign="end"
                                            >
                                              {values.currency?.symbol}
                                              {(
                                                Number(
                                                  values.serviceForms[index]
                                                    .price
                                                ) *
                                                Number(
                                                  values.serviceForms[index]
                                                    .number
                                                )
                                              ).toFixed(2)}
                                            </MBox>
                                          )}
                                          <MBox
                                            flex="1 1 0"
                                            display="flex"
                                            justifyContent="flex-end"
                                          >
                                            <DeleteOutlineIcon
                                              className={classes.deleteIcon}
                                              onClick={() => {
                                                arrayHelper.remove(index);
                                                removeMatchRateService(index);
                                              }}
                                            />
                                          </MBox>
                                        </MBox>
                                      )
                                    )}
                                  <MBox pb={2}>
                                    <MBox
                                      className={classes.addRowButton}
                                      onClick={() =>
                                        arrayHelper.push({
                                          service: "",
                                          targetLanguages:
                                            languages && languages.length === 1
                                              ? [languages[0]]
                                              : [],
                                          number: "",
                                          unit: "",
                                          price: "",
                                          subTotal: "",
                                        })
                                      }
                                    >
                                      <MBox px={1}>
                                        <span style={{ fontSize: 16 }}>+</span>
                                        <FormattedMessage id="add.line" />
                                      </MBox>
                                    </MBox>
                                  </MBox>
                                </>
                              )}
                            />
                            <MBox pt={2} pb={4}>
                              <MBox
                                display="flex"
                                justifyContent="space-between"
                              >
                                <MBox width="50%">
                                  <MBox
                                    mb={1}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    pb={1}
                                    pr={2}
                                  >
                                    <MBox className={classes.formLabel}>
                                      <FormattedMessage id="cover.letter" />
                                    </MBox>
                                  </MBox>
                                  <MBox>
                                    <TextareaAutosize
                                      maxRows={4}
                                      minRows={4}
                                      className={classes.textArea}
                                      aria-label="maximum height"
                                      placeholder={intl.formatMessage({ id: "description" })}
                                      value={values.desc}
                                      onChange={(event: any) => {
                                        setFieldValue(
                                          "desc",
                                          event.target.value
                                        );
                                      }}
                                    />
                                  </MBox>

                                  {/* <MBox
                                    borderRadius={4}
                                    className={classes.editorBox}
                                  >
                                    <MBox className={classes.editorWrap}> */}
                                  {/* <Editor
                                                                                content={((draftDetail && draftDetail.description) ? draftDetail.description : '')}
                                                                                editorStyle={{
                                                                                    minHeight: 200,
                                                                                    maxHeight: 250,
                                                                                    overflowY: 'auto',
                                                                                    outline: 'none',
                                                                                    padding: 10
                                                                                }}
                                                                                onChange={(event) => {
                                                                                    setFieldValue('desc', event);
                                                                                }}
                                                                            /> */}
                                  {/* </MBox>
                                  </MBox> */}
                                </MBox>
                                {/* final calculations */}

                                <MBox width="45%">
                                  <MBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    pb={1}
                                    pl={2}
                                  >
                                    <MBox className={classes.colorLightGrey}>
                                      <FormattedMessage id="total.price" />
                                    </MBox>
                                    <MBox>{values.currency?.symbol}{calculateTotalPrice(values)}</MBox>
                                  </MBox>

                                  <MBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <MBox
                                      width="59%"
                                      className={`${classes.bgLightGrey}`}
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      p={2}
                                    >
                                      <MBox
                                        width="50%"
                                        className={classes.colorLightGrey}
                                      >
                                        <FormattedMessage id="discount" />
                                      </MBox>
                                      <MBox width="50%" textAlign="right">
                                        <MTextField
                                          name="discount"
                                          variant="outlined"
                                          color="secondary"
                                          size="small"
                                          InputProps={{
                                            inputProps: {
                                              min: 0,
                                              max: 99,
                                            },
                                          }}
                                          type="number"
                                          defaultValue={0}
                                          fullWidth
                                          className={`${classes.smallInput} ${classes.inputWhite} ${classes.discountInput}`}
                                          inputProps={{
                                            placeholder: "Discount",
                                          }}
                                        />{" "}
                                        %
                                      </MBox>
                                    </MBox>
                                    <MBox>
                                      {values.currency?.symbol}
                                      {calculateTotalPriceAfterDiscount(values)}
                                    </MBox>
                                  </MBox>

                                  <MBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <MBox
                                      width="59%"
                                      className={`${classes.bgLightGrey}`}
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      p={2}
                                    >
                                      <MBox
                                        width="50%"
                                        className={classes.colorLightGrey}
                                        borderRadius={4}
                                      >
                                        <FormattedMessage id="tax" />
                                      </MBox>
                                      <MBox width="50%" textAlign="right">
                                        <MTextField
                                          name="taxPercent"
                                          variant="outlined"
                                          color="secondary"
                                          size="small"
                                          defaultValue={0}
                                          type="number"
                                          fullWidth
                                          className={`${classes.smallInput} ${classes.inputWhite} ${classes.discountInput}`}
                                          inputProps={{
                                            placeholder: "Tax",
                                          }}
                                        />{" "}
                                        %
                                      </MBox>
                                    </MBox>

                                    <MBox>
                                      {values.currency?.symbol}{calculateTotalPriceAfterTax(values)}
                                    </MBox>
                                  </MBox>

                                  <MBox
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    pl={2}
                                  >
                                    <MBox className={classes.colorLightGrey}>
                                      <FormattedMessage id="balance" />({values.currency?.symbol})
                                    </MBox>
                                    <MBox
                                      fontSize={22}
                                      color="secondary.main"
                                      fontFamily="Poppins Bold"
                                    >
                                      {values.currency?.symbol}{calculateTotalPriceAfterTax(values)}
                                    </MBox>
                                  </MBox>
                                </MBox>
                              </MBox>
                            </MBox>
                          </MBox>
                          <MBox className={classes.footerBottom} py={2}>
                            {(jobQuoteDetails &&
                              jobQuoteDetails.proposalSent == 0) ||
                              jobQuoteDetails.proposalSent == 2 ? (
                              <MBox
                                display="flex"
                                justifyContent="flex-end"
                                width="100%"
                              >
                                {!jobQuoteDetails.isPO && (
                                  <MBox pb={1} pr={1}>
                                    <MButton
                                      disabled={
                                        values.serviceForms.length === 0 ||
                                        createLoading
                                      }
                                      type="submit"
                                      variant="contained"
                                      color="secondary"
                                      className="btnMedium backgroundColorGrey"
                                      onClick={() => {
                                        setIsDraft(1);
                                        if (
                                          typeof props.setViewDraftOpen !==
                                          "undefined"
                                        ) {
                                          props.setViewDraftOpen(false);
                                        }
                                      }}
                                      sx={{ minWidth: "117px !important" }}
                                    >
                                      {createLoading && isDraft === 1 && (
                                        <MCircularProgress
                                          color="secondary"
                                          size={24}
                                        />
                                      )}
                                      <FormattedMessage id="save.draft" />
                                    </MButton>
                                  </MBox>
                                )}

                                <MBox mr={-0.5}>
                                  <MButton
                                    disabled={
                                      values.serviceForms.length === 0 ||
                                      createLoading
                                    }
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="btnMedium"
                                    onClick={() => {
                                      setIsDraft(0);
                                      if (
                                        typeof props.setViewDraftOpen !==
                                        "undefined"
                                      ) {
                                        props.setViewDraftOpen(false);
                                      }
                                    }}
                                    sx={{ minWidth: "117px !important" }}
                                  >
                                    {createLoading && isDraft === 0 && (
                                      <MCircularProgress
                                        color="secondary"
                                        size={24}
                                      />
                                    )}
                                    {jobQuoteDetails.isPO ? (
                                      <FormattedMessage id="send.po" />
                                    ) : (
                                      <FormattedMessage id="send.bid.proposal" />
                                    )}
                                  </MButton>
                                </MBox>
                              </MBox>
                            ) : (
                              ""
                            )}
                          </MBox>
                          <AddMatchRateModal
                            modalOpen={showAddMatchRateModal}
                            setFieldValue={setFieldValue}
                            setModalOpen={setShowAddMatchRateModal}
                            selectedService={selectedService}
                            setServicesMatchRate={setServicesMatchRate}
                            servicesMatchRate={servicesMatchRate}
                            isEdit={isEdit}
                            serviceIndex={serviceIndex}
                            jobAnalysis={quoteAnalysis}
                            setMatchRateId={setMatchRateId}
                            draftDetail={draftDetail && draftDetail}
                            jobQuoteDetails={jobQuoteDetails}
                            serviceFormData={serviceFormData}
                            setServiceFormData={setServiceFormData}
                          />
                        </MForm>
                      );
                    }}
                  </MFormik>
                )}
              </FormattedMessage>
            ) : (
              // it shows skeleton when loading
              (jobDetailLoading || isLoadingDraftData) && <SendQuoteSkeleton />
            )}
          </MGrid>
        </MGrid>
      </MBox>
    </div>
  );
}


