import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //   formBox:{
    //       backgroundColor: 'white',
    //       boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
    //       borderRadius: 5,
    //   },

    formBoxNew: {

      boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
      width: '700px',
      border: '1px solid #E8E8E8',
      margin: '67px auto auto',
      padding: '44px 48px 48px',
      background: '#FDFDFD',
      borderRadius: '5px',
      backgroundColor: '#FDFDFD',
    },
    contentBox: {
      backgroundColor: 'white',
      boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
      borderRadius: 5,
    },
    accordion: {
      '& .MuiAccordionSummary-root': {
        transition: "all .3s",
      },
      '&:not(.Mui-expanded):hover': {
        '& .MuiAccordionSummary-root': {
          backgroundColor: '#f7f8fc',
        }
      },
      "& .MuiAccordionDetails-root": {
        borderTop: '1px solid #eaeaea',
        borderBottom: '1px solid #eaeaea',
        padding: '10px 0'
      },
      "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 42,
      },
      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
      },
      "& .Mui-expanded, & .MuiAccordionDetails-root, & .MuiAccordionActions-root": {
        backgroundColor: '#f7f9fa',
      },

      "& .MuiFormControl-root input": {
        backgroundColor: '#fff',
        padding: "7px 7px"
      },
      "& .MuiFormControl-root fieldset": {
        borderColor: "#e4e4e4!important",
      },

    },
    seachInput: {
      borderRadius: 5,
      '& .Mui-focused': {
        '& legend': {
          width: '105px !important'
        }
      },
      '& fieldset': {
        borderColor: 'transparent !important',
      },
      '& .MuiFormLabel-root': {
        transform: 'translate(15px, 8px) scale(1)',
        fontSize: 14
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(20px, -7px) scale(0.75)'
      },
      '& .MuiOutlinedInput-inputMarginDense': {
        padding: '5px'
      },
      '& .MuiSvgIcon-root': {
        color: '#585858',
        width: 18,
        height: 18,
        marginRight: -3
      },
      '& .MuiInputBase-root': {
        height: 32,
        background: '0px center rgba(9, 30, 66, 0.04)',
        width: '100%',
        '&.Mui-focused': {
          background: '#fff'
        }
      }
    },
    // editForm:{
    //   width: '100%',
    //   '& .inputReadOnly fieldset':{
    //     border: 'none',
    //   },
    //   "& .MuiFormControl-root:not(.inputReadOnly) input":{
    //     backgroundColor: '#fff',
    //   },
    // },
    formLabel: {
      fontSize: 14,
      color: theme.palette.text.primary,
    },
    clearAll: {
      height: 32,
      color: 'rgb(58, 58, 58)'
    },
    fileUploadDrawer: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        minWidth: "85%",
        maxWidth: "85%",

        //   "& .MuiToolbar-root":{
        //       display: 'flex',
        //       justifyContent: 'space-between'
        //   }

      },
      //   '& .MuiToolbar-root':{
      //         display:'flex',
      //         justifyContent: 'space-between'
      //   },
      '&.fullWidth': {
        "& .MuiPaper-root.MuiDrawer-paper": {
          transform: 'translateX(-18%) !important'
        }
      },
    },
    footerBottom: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'fixed',
      bottom: 0,
      left: '15%',
      width: '85%',
      padding: '20px 27px 20px 0px',
      backgroundColor: '#fff',
      borderTop: '1px solid #dbdbdb',

    },
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;